import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
function BackButton({ selectedTab = "" }) {
  const navigate = useNavigate();
  return (
    <div
      className=" mouse-cursor container"
      onClick={() =>
        navigate(
          selectedTab != "" ?
            `/transferDashboard?selectedTab=${selectedTab}`
            : -1
        )
      }
    >
      <FontAwesomeIcon icon={faArrowLeft} size="lg" color="#fff" className='back-button' />
    </div>
  );
}

export default BackButton;
