import { useEffect, useRef, useState } from "react";

// Qr Scanner
import QrScanner from "qr-scanner";
import QrFrame from "../../assets/images/qr-frame.svg";
import { useTranslation } from "react-i18next";

const QrReader = (props: any) => {
    // QR States
    const { t } = useTranslation();
    const scanner = useRef<QrScanner>();
    const videoEl = useRef<HTMLVideoElement>(null);
    const qrBoxEl = useRef<HTMLDivElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [qrOn, setQrOn] = useState<boolean>(true);

    // Result
    const [scannedResult, setScannedResult] = useState<string | undefined>("");
    const [isScanned, setIsScanned] = useState(false);

    // Success
    const onScanSuccess = (result: QrScanner.ScanResult) => {
        console.log(result);
        setScannedResult(result?.data);
        scanner.current?.stop();
        setIsScanned(true);
        props.qrCodeSuccessCallback(result?.data);
    };

    // Fail
    const onScanFail = (err: string | Error) => {
        console.log(err);
    };

    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
            scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
                onDecodeError: onScanFail,
                preferredCamera: "environment",
                highlightScanRegion: true,
                highlightCodeOutline: true,
                overlay: qrBoxEl?.current || undefined,
            });

            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        return () => {
            if (!videoEl?.current) {
                scanner?.current?.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (!qrOn)
            alert(
                "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
            );
    }, [qrOn]);

    // Handle image upload and scan QR code from the image
    const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            try {
                const result = await QrScanner.scanImage(file);
                console.log(result);
                setScannedResult(result);
                setIsScanned(true);
                props.qrCodeSuccessCallback(result);
            } catch (error) {
                console.log("Failed to scan QR code from image", error);
            }
        }
    };
    // Trigger the file input click
    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };
    return (
        <>
            {isScanned ? <button className="fugu--outline-btn" onClick={() => { setIsScanned(false); scanner.current?.start(); videoEl.current?.play() }}
            ><span>Scan Again</span></button> : (
                <div className="qr-reader">
                    {/* QR Scanner */}
                    <video ref={videoEl}></video>
                    <div ref={qrBoxEl} className="qr-box">
                        <img
                            src={QrFrame}
                            alt="Qr Frame"
                            width={256}
                            height={256}
                            className="qr-frame"
                        />
                    </div>

                    {/* Image upload for scanning */}
                    <button onClick={handleButtonClick} className="text-white">{t("dashboard.tabs.nftAccess.uploadImageToScan")}</button>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        ref={fileInputRef}
                        style={{ display: "none" }} // Hide the file input
                    />

                    {/* Show Data Result if scan is successful */}
                </div>
            )}
        </>
    );
};

export default QrReader;
