import { IDL } from "@dfinity/candid";

//@ts-ignore
export const idlFactory = ({ IDL }) => {
  // Define basic types
  const SubAccount = IDL.Vec(IDL.Nat8);
  const AccountIdentifier = IDL.Vec(IDL.Nat8); // Represents a blob
  const TextAccountIdentifier = IDL.Text;
  const Icrc1Tokens = IDL.Nat64; // Represents tokens as Nat64
  const Icrc1Timestamp = IDL.Nat64; // Number of nanoseconds since UNIX epoch
  const BlockIndex = IDL.Nat64;

  // Define Tokens as a record with e8s field
  const Tokens = IDL.Record({ e8s: IDL.Nat64 });
  const Memo = IDL.Nat64;

  // Define Account record
  const Account = IDL.Record({
    owner: IDL.Principal,
    subaccount: IDL.Opt(SubAccount),
  });

  // Define arguments for account balance queries
  const AccountBalanceArgs = IDL.Record({
    account: AccountIdentifier,
  });

  const AccountBalanceArgsDfx = IDL.Record({
    account: TextAccountIdentifier,
  });

  // Corrected TransferArg for icrc1_transfer
  const TransferArg = IDL.Record({
    to: Account,
    fee: IDL.Opt(Tokens),
    memo: IDL.Opt(Memo),
    from_subaccount: IDL.Opt(SubAccount),
    created_at_time: IDL.Opt(Icrc1Timestamp),
    amount: Tokens,
  });

  // Existing TransferArgs for transfer method
  const TransferArgs = IDL.Record({
    to: AccountIdentifier,
    fee: Tokens,
    memo: Memo,
    from_subaccount: IDL.Opt(SubAccount),
    created_at_time: IDL.Opt(Icrc1Timestamp),
    amount: Tokens,
  });

  // Define Icrc1TransferError variant
  const Icrc1TransferError = IDL.Variant({
    GenericError: IDL.Record({ message: IDL.Text, error_code: IDL.Nat }),
    TemporarilyUnavailable: IDL.Null,
    BadBurn: IDL.Record({ min_burn_amount: Icrc1Tokens }),
    Duplicate: IDL.Record({ duplicate_of: BlockIndex }),
    BadFee: IDL.Record({ expected_fee: Icrc1Tokens }),
    CreatedInFuture: IDL.Record({ ledger_time: Icrc1Timestamp }),
    TooOld: IDL.Null,
    InsufficientFunds: IDL.Record({ balance: Icrc1Tokens }),
  });

  // Define Icrc1TransferResult variant
  const Icrc1TransferResult = IDL.Variant({
    Ok: BlockIndex,
    Err: Icrc1TransferError,
  });

  // Define TransferResult variant for transfer method
  const TransferResult = IDL.Variant({
    Ok: BlockIndex,
    Err: IDL.Variant({
      TxTooOld: IDL.Record({ allowed_window_nanos: IDL.Nat64 }),
      BadFee: IDL.Record({ expected_fee: Tokens }),
      TxDuplicate: IDL.Record({ duplicate_of: BlockIndex }),
      TxCreatedInFuture: IDL.Null,
      InsufficientFunds: IDL.Record({ balance: Tokens }),
    }),
  });

  return IDL.Service({
    // Query methods
    account_balance: IDL.Func([AccountBalanceArgs], [Tokens], ["query"]),
    account_balance_dfx: IDL.Func([AccountBalanceArgsDfx], [Tokens], ["query"]),
    account_identifier: IDL.Func([Account], [AccountIdentifier], ["query"]),
    archives: IDL.Func(
      [],
      [
        IDL.Record({
          archives: IDL.Vec(IDL.Record({ canister_id: IDL.Principal })),
        }),
      ],
      ["query"]
    ),
    decimals: IDL.Func([], [IDL.Record({ decimals: IDL.Nat32 })], ["query"]),
    icrc10_supported_standards: IDL.Func(
      [],
      [IDL.Vec(IDL.Record({ url: IDL.Text, name: IDL.Text }))],
      ["query"]
    ),
    icrc1_balance_of: IDL.Func([Account], [Icrc1Tokens], ["query"]),
    icrc1_decimals: IDL.Func([], [IDL.Nat8], ["query"]),
    icrc1_fee: IDL.Func([], [Icrc1Tokens], ["query"]),
    icrc1_metadata: IDL.Func(
      [],
      [
        IDL.Vec(
          IDL.Record({
            text: IDL.Text,
            Value: IDL.Variant({
              Int: IDL.Int,
              Nat: IDL.Nat,
              Blob: IDL.Vec(IDL.Nat8),
              Text: IDL.Text,
            }),
          })
        ),
      ],
      ["query"]
    ),
    icrc1_minting_account: IDL.Func([], [IDL.Opt(Account)], ["query"]),
    icrc1_name: IDL.Func([], [IDL.Text], ["query"]),
    icrc1_supported_standards: IDL.Func(
      [],
      [IDL.Vec(IDL.Record({ url: IDL.Text, name: IDL.Text }))],
      ["query"]
    ),
    icrc1_symbol: IDL.Func([], [IDL.Text], ["query"]),
    icrc1_total_supply: IDL.Func([], [Icrc1Tokens], ["query"]),

    // Transfer methods
    icrc1_transfer: IDL.Func([TransferArg], [Icrc1TransferResult], []), // Uses corrected TransferArg
    transfer: IDL.Func([TransferArgs], [TransferResult], []), // Uses TransferArgs

    // Additional query methods
    name: IDL.Func([], [IDL.Record({ name: IDL.Text })], ["query"]),
    query_blocks: IDL.Func(
      [IDL.Record({ start: BlockIndex, length: IDL.Nat64 })],
      [
        IDL.Record({
          certificate: IDL.Opt(IDL.Vec(IDL.Nat8)),
          blocks: IDL.Vec(
            IDL.Record({
              transaction: IDL.Record({
                memo: Memo,
                icrc1_memo: IDL.Opt(IDL.Vec(IDL.Nat8)),
                operation: IDL.Opt(
                  IDL.Variant({
                    Approve: IDL.Record({
                      fee: Tokens,
                      from: AccountIdentifier,
                      allowance_e8s: IDL.Int,
                      allowance: Tokens,
                      expected_allowance: IDL.Opt(Tokens),
                      expires_at: IDL.Opt(
                        IDL.Record({ timestamp_nanos: Icrc1Timestamp })
                      ),
                      spender: AccountIdentifier,
                    }),
                    Burn: IDL.Record({
                      from: AccountIdentifier,
                      amount: Tokens,
                      spender: IDL.Opt(AccountIdentifier),
                    }),
                    Mint: IDL.Record({ to: AccountIdentifier, amount: Tokens }),
                    Transfer: IDL.Record({
                      to: AccountIdentifier,
                      fee: Tokens,
                      from: AccountIdentifier,
                      amount: Tokens,
                      spender: IDL.Opt(IDL.Vec(IDL.Nat8)),
                    }),
                  })
                ),
                created_at_time: IDL.Record({
                  timestamp_nanos: Icrc1Timestamp,
                }),
              }),
              timestamp: IDL.Record({ timestamp_nanos: Icrc1Timestamp }),
              parent_hash: IDL.Opt(IDL.Vec(IDL.Nat8)),
            })
          ),
          chain_length: IDL.Nat64,
          first_block_index: BlockIndex,
          archived_blocks: IDL.Vec(
            IDL.Record({
              callback: IDL.Func(
                [IDL.Record({ start: BlockIndex, length: IDL.Nat64 })],
                [
                  IDL.Variant({
                    Ok: IDL.Vec(IDL.Vec(IDL.Nat8)),
                    Err: IDL.Variant({
                      Other: IDL.Record({
                        error_message: IDL.Text,
                        error_code: IDL.Nat64,
                      }),
                      BadFirstBlockIndex: IDL.Record({
                        requested_index: BlockIndex,
                        first_valid_index: BlockIndex,
                      }),
                    }),
                  }),
                ],
                ["query"]
              ),
              start: BlockIndex,
              length: IDL.Nat64,
            })
          ),
        }),
      ],
      ["query"]
    ),
    send_dfx: IDL.Func(
      [
        IDL.Record({
          to: TextAccountIdentifier,
          fee: Tokens,
          memo: Memo,
          from_subaccount: IDL.Opt(SubAccount),
          created_at_time: IDL.Opt(
            IDL.Record({ timestamp_nanos: Icrc1Timestamp })
          ),
          amount: Tokens,
        }),
      ],
      [BlockIndex],
      []
    ),
    symbol: IDL.Func([], [IDL.Record({ symbol: IDL.Text })], ["query"]),
    transfer_fee: IDL.Func(
      [IDL.Record({})],
      [IDL.Record({ transfer_fee: Tokens })],
      ["query"]
    ),
  });
};

//@ts-ignore
export const init = ({ IDL }) => {
  return [];
};
