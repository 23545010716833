import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCopy } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import { useAuth } from '../web3/context/AuthContext';
import { useTranslation } from 'react-i18next';

function NFTAccessUshers({ passUshers }) {
  const { t } = useTranslation()
  const { entityInfo, network } = useAuth()
  const [ushers, setUshers] = useState([]);

  const [newUsher, setNewUsher] = useState();
  const handleCopy = async (text) => {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  const addUsher = () => {
    if (newUsher.trim() === '') {
      return;
    }
    const updatedUshers = [...ushers, newUsher];
    if (newUsher.toLowerCase() === entityInfo.activePublicKey.toLowerCase()) {
      toast.error("You can't Add Your Wallet As Usher");
      setNewUsher("")
      return
    }
    setUshers(updatedUshers);
    passUshers(updatedUshers);
    setNewUsher('');
  };
  const removeUsher = (index) => {
    const updatedUshers = [...ushers];
    updatedUshers.splice(index, 1);
    setUshers(updatedUshers);
    passUshers(updatedUshers);
  };

  return (
    <div className="fugu--blog-sidebar mb-4">
      <div className="fugu--blog-sidebar-item px-4">
        <h4>{t("dashboard.tabs.nftAccess.addUshersAddresses")}</h4>
        <div className="row">
          <div className="col">
            <input
              type="text"
              placeholder={t("dashboard.tabs.nftAccess.usherAddress")}
              className={`text-gray-700 ${!network?.network.isValidAddress(newUsher) && "text-red"}`}
              name="newUsher"
              value={newUsher}
              onChange={(e) => { setNewUsher(e.target.value) }}
            />
          </div>
        </div>
        <div className="fugu--card-footer mb-4">
          <button className="fugu--btn btn-sm bg-white" disabled={!network?.network.isValidAddress(newUsher)} onClick={addUsher}>
            {t("dashboard.tabs.nftAccess.add")}
          </button>
        </div>
        <div className="fugu--tags fugu--tags2">
          <ul>
            {ushers.map((usher, index) => (
              <li key={index}>
                <button
                  className="position-absolute"
                  onClick={() => removeUsher(index)}
                  style={{ zIndex: 100 }}
                >
                  <FontAwesomeIcon icon={faTimes} color="#fff" />
                </button>
                <div>
                  <p className="text-uppercase font-weight-bold">
                    {usher?.slice(0, 5)} ...
                    {usher?.slice(
                      usher.length - 5
                    )}
                    <span onClick={() => handleCopy(usher)} className="cursor-pointer ms-2  mb-0">
                      <FontAwesomeIcon icon={faCopy} />
                    </span>
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NFTAccessUshers;
