import { BlockchainNetwork } from "./BlockchainNetwork";
import { CHAIN_SCAN, RPC_NODES } from "./Providers";
import { tokenTransfer } from "../ERC20-utils/proxy-payment/ProxyPayment_Utils";
import { Node, CompilerHttp, AeSdk } from "@aeternity/aepp-sdk";
import { formatUnits } from "viem";

export class AeternityNetwork extends BlockchainNetwork {
  private network: any;
  private networkName: string;
  private rpc: any;
  private complierUrl: string;

  constructor(chain: string) {
    super(chain);
    type NetworkType = "MAINNET" | "TESTNET";
    const networkType = process.env.REACT_APP_NETWORK_TYPE as NetworkType;
    this.networkName = networkType === "TESTNET" ? "testnet" : "mainnet";
    this.rpc =
      RPC_NODES.AETERNITY[process.env.REACT_APP_NETWORK_TYPE as NetworkType];
    this.complierUrl = "https://v7.compiler.aepps.com";
  }

  getAddress(userWallets: any) {
    return userWallets.AETERNITY.wallets[0].publicKey;
  }

  getWallets(userWallets: any, shortenKeyWidth: number) {
    if (!userWallets) return [];
    return userWallets.map((elem: any) => {
      return {
        address: elem.publicKey,
        hasPassword: elem.hasPassword,
        walletIndex: elem.index,
        publicKey: elem.publicKey,
        label:
          elem.publicKey?.slice(0, shortenKeyWidth) +
          "..." +
          elem.publicKey?.slice(-4),
      };
    });
  }

  async getBalance(address: any, network: any): Promise<any> {
    if (!network) return 0;
    try {
      const node = new Node(this.rpc);
      const aeSdk = new AeSdk({
        onCompiler: new CompilerHttp(this.complierUrl),
        nodes: [{ name: this.networkName, instance: node }],
      });

      const balance = await aeSdk.getBalance(address);
      const formattedBalance = formatUnits(BigInt(balance), network.decimals);
      return formattedBalance;
    } catch (error) {
      return 0;
    }
  }

  async getDeposit(email: string, tokenAddress: string): Promise<any> {
    return 0;
  }

  async getAllDepositsBalance(email: string): Promise<any> {
    return [];
  }

  isValidAddress(address: string): any {
    return true;
  }

  validateWalletAddress(addresses: any): any {
    return {
      isValid: true,
      receiver: addresses,
    };
  }

  async depositToken(
    email: string,
    walletAddress: string,
    walletIndex: number,
    receiversIds: [string],
    amount: string,
    network: string,
    password: string
  ): Promise<any> {
    return tokenTransfer(
      email,
      walletIndex,
      password,
      1,
      receiversIds[0],
      amount,
      "AETERNITY"
    );
  }

  async transferToken(
    email: string,
    walletAddress: string,
    walletIndex: number,
    receiversIds: [string],
    amount: string,
    network: string,
    password: string
  ): Promise<any> {
    return tokenTransfer(
      email,
      walletIndex,
      password,
      0,
      receiversIds[0],
      amount,
      "AETERNITY"
    );
  }

  getChainScanUrlAddress() {
    const chain = CHAIN_SCAN[this.chainName as keyof typeof CHAIN_SCAN];
    return (
      chain[process.env.REACT_APP_NETWORK_TYPE as keyof typeof chain] +
      "accounts/"
    );
  }

  getChainScanUrlTx() {
    const chain = CHAIN_SCAN[this.chainName as keyof typeof CHAIN_SCAN];
    return (
      chain[process.env.REACT_APP_NETWORK_TYPE as keyof typeof chain] +
      "transactions/"
    );
  }
}
