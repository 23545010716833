import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import DatePicker from "react-datepicker";
import { useAuth } from '../../components/web3/context/AuthContext';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast';
import { convertDateToUnix } from '../../helper/helperFunc';
import CustomCalenderInput from "../../components/customCalender"
import { scheduleNonCustodial, scheduleCustodial } from '../../components/web3/scheduler-utils/scheduler-utils';
import Preloader from '../../components/common/preloader/preloader';
import { openPasswordModel } from '../../components/social-wallet/PasswordModal';
import { convertToSeconds } from '../../helper/helperFunc';
import { useTranslation } from 'react-i18next';
function DirectScheduleTransfer() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation()
    const initialNumExecutionNumRepeating = {
        numberExecution: location.state?.transactionDetails.numberExecution,
        days: location.state?.transactionDetails.days,
        minutes: location.state?.transactionDetails.minutes
    }
    const [transactionDetails, setTransactionDetails] = useState(location.state?.transactionDetails);
    const [selectedDate, setSelectedDate] = useState(transactionDetails.selectedDate)
    const [days, setDays] = useState(transactionDetails.days ?transactionDetails.days: 0 );
    const [minutes, setMinutes] = useState(transactionDetails.minutes ? transactionDetails.minutes : 0);
    const { isLoggedIn,
        balance,
        loginOption,
        hasPassword,
        network,
        entityInfo,
        email
    } = useAuth()
    const [loading, setLoading] = useState(false)
    const handleDateChange = (date) => {
        setSelectedDate(date)
    };
    const [scheduleExecutionRepeating, setScheduleExecutionRepeating] = useState(initialNumExecutionNumRepeating)
    //remove one element from the receivers arr
    const handleRemoveReceiver = (index) => {
        const updatedReceivers = [...transactionDetails.receivers];
        updatedReceivers.splice(index, 1);
        setTransactionDetails({
            ...transactionDetails,
            receivers: updatedReceivers,
        });
    };
    const handlePassSecureTransfer = async (password, input) => {
        if (password === "") return toast.error("Password is required");
        if (password == null) return;
        await scheduleCustodial({
            walletId: email,
            walletIndex: entityInfo.indexWallet,
            sender: entityInfo.activePublicKey,
            recipients: input.receivers,
            amount: input.amount.toString(),
            executionTime: parseInt(input.date),
            repeatingTime: parseInt(input.timeGap),
            numberOfExecutions: parseInt(scheduleExecutionRepeating.numberExecution == ""  ? 0 : scheduleExecutionRepeating.numberExecution),
            password: password
        }, network, transactionDetails.selectedMethod == "Wallet")

    };
    const SubmitScheduleTransfer = async () => {
        console.log(selectedDate.toLocaleString())
        const time =  validateTimeGapBetweenSchedulesTx()

        if(!time && (scheduleExecutionRepeating.numberExecution > 1 ||scheduleExecutionRepeating.numberExecution == "")) return

        const validReceivers = transactionDetails.receivers.filter(elem => elem.isValid);

        const receiversIds = validReceivers.map((receiver) =>
            receiver?.receiver.replace(/\s/g, "")
        );
        const totalAmount = validReceivers.reduce((sum, receiver) => sum + parseFloat(receiver.amount), 0);

        if (totalAmount <= parseFloat(balance)) {

            const unixDate = convertDateToUnix(selectedDate)
            console.log("Transfer is possible. Proceeding with the transfer.", unixDate);

            setLoading(true)
            try {
                if (loginOption == "custodial") {
                    if (hasPassword) {
                        openPasswordModel(handlePassSecureTransfer, {
                            receivers: receiversIds,
                            amount: totalAmount,
                            date: unixDate,
                            timeGap: time
                        });
                    } else {
                        
                        await scheduleCustodial({
                            walletId: email,
                            walletIndex: entityInfo.indexWallet,
                            sender: entityInfo.activePublicKey,
                            recipients: receiversIds,
                            amount: totalAmount.toString(),
                            executionTime: parseInt(unixDate),
                            repeatingTime: parseInt(time),
                            numberOfExecutions: parseInt(scheduleExecutionRepeating.numberExecution == "" ? 0 : scheduleExecutionRepeating.numberExecution),
                        }, network, transactionDetails.selectedMethod == "Wallet")
                    }
                } else if (loginOption == "non_custodial") {

                   await scheduleNonCustodial({
                        sender: entityInfo.activePublicKey,
                        recipients: receiversIds,
                        amount: totalAmount.toString(),
                        executionTime: parseInt(unixDate),
                        repeatingTime: parseInt(time),
                        numberOfExecutions: parseInt(scheduleExecutionRepeating.numberExecution  == "" ? 0 : scheduleExecutionRepeating.numberExecution)
                    }, network, transactionDetails.selectedMethod == "Wallet")

                }
                setLoading(false)
                navigate(-1)
            } catch (e) {
                console.log(e)
                setLoading(false)
                toast.error("Schedule transfer is failed ..")
            }

        } else {
            // User's balance is not sufficient
            toast.error("Insufficient balance to cover the transaction.")
        }
    }
    const validateTimeGapBetweenSchedulesTx = ()=>{
        const timeGap = convertToSeconds(days, minutes)
        if(timeGap == 0 && scheduleExecutionRepeating.numberExecution > 1 ){
          toast.error("Please add time gap between transaction");
          return 
        }else if (scheduleExecutionRepeating.numberExecution == ""){
            toast.error("Please add Number of transaction repetitions");
            return null
        }else if (scheduleExecutionRepeating.numberExecution == "" || scheduleExecutionRepeating.numberExecution  == 1){
            const updateTimeGap  =  {
                days: 0,
                minutes: 0
            }
            setScheduleExecutionRepeating(updateTimeGap)
            return 0
        }
        else return timeGap
        
      }
    useEffect(() => {
        if (transactionDetails) {
            const updatedReceivers = transactionDetails.receivers.map(receiver => ({
                ...receiver,
                amount: transactionDetails.amount,
            }));

            setTransactionDetails({
                ...transactionDetails,
                receivers: updatedReceivers,
            });
        }
    }, []);

    const handleReceiverAmountChange = (e, index) => {
        const updatedReceivers = [...transactionDetails.receivers];
        updatedReceivers[index] = {
            ...updatedReceivers[index],
            amount: e.target.value,
        };
        setTransactionDetails({
            ...transactionDetails,
            receivers: updatedReceivers,
        });
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        setScheduleExecutionRepeating((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className="fugu--dashboard-section ">
            {isLoggedIn && (
                <div>
                    <Preloader show={loading} />
                    <div className='d-flex justify-content-center align-items-center'>
                        <DatePicker
                            selected={selectedDate}
                            onChange={date => handleDateChange(date)}
                            showTimeSelect
                            dateFormat="Pp"
                            minDate={new Date()}
                            minTime={new Date()}
                            maxTime={new Date(0, 0, 0, 23, 59, 59)}
                            className="mx-5 fugu-date-picker text-gray-10"
                            customInput={<CustomCalenderInput onClick={handleDateChange} value={selectedDate} />}
                        />
                        <div className='text-gray-10'>
                            {selectedDate.toLocaleString()}
                        </div>
                    </div>
                    <div className="row p-5">
                        <div className='col-lg-6 col-12 '>
                            <label className='text-white pb-1 '>
                                {t("dashboard.tabs.tokens.numberRepetitions")}
                                <input
                                    className="m-2 text-gray-10 "
                                    placeholder={t("dashboard.tabs.tokens.numberRepetitions")}
                                    type="number"
                                    name="numberExecution"
                                    onChange={(e) => handleChange(e.target.value < 0 ? 0 : e)}
                                    value={scheduleExecutionRepeating.numberExecution}
                                    onWheel={(e) => e.target.blur()}
                                />
                            </label>
                        </div>
                        <div className='col-lg-6 col-12'>
                            {scheduleExecutionRepeating.numberExecution > 1 && (
                                <div className=' d-flex justify-content-around'>
                                    <div>
                                        <label className='text-white pb-1 '>
                                            Days:
                                            <input type="number"
                                                className="text-gray-10 m-2"
                                                value={days}
                                                onChange={(e) => setDays(e.target.value < 0 ? 0 : e.target.value)}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                        </label>
                                    </div>

                                    <div>
                                        <label className='text-white'>
                                            Minutes:
                                            <input
                                                className='text-gray-10 m-2'
                                                type="number"
                                                value={minutes}
                                                onChange={(e) => setMinutes(e.target.value < 0 ? 0 : e.target.value)}
                                                onWheel={(e) => e.target.blur()}
                                            />
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>


                    </div>

                    {transactionDetails && transactionDetails.receivers.length > 0 && (
                        <table className='fugu-schedule-Table'>
                            <tbody>
                                {transactionDetails.receivers.map((elem, index) =>
                                    <tr key={index}>
                                        <td className={`p-1 fugu-table-border ${elem.isValid ? "text-gray-10" : "text-danger"}`}>
                                            {elem.receiver}
                                        </td>
                                        <td className='p-1 fugu-table-border '>

                                            <div className='d-flex justify-content-center align-items-center'>
                                                <div className='fugu-schedule-currency-symbol'>
                                                    {transactionDetails.network?.symbol}
                                                </div>
                                                <div className="fugu-schedule-amount-input">
                                                    <input
                                                        name='amount'
                                                        onChange={e => handleReceiverAmountChange(e, index)}
                                                        className='text-gray-10  text-center'
                                                        value={elem.amount}
                                                        type='number'
                                                    />
                                                </div>
                                            </div>

                                        </td>
                                        <td className='fugu-table-border'>
                                            <div className='d-flex  justify-content-center'>
                                                <button className='text-danger-500' onClick={() => handleRemoveReceiver(index)}>
                                                    REMOVE
                                                </button>

                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}

                    <div className='mt-5'>
                        <button
                            className="fugu--btn btn-sm bg-white col m-2"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </button>
                        {transactionDetails.receivers.length > 0 && (
                            <button
                                className="fugu--outline-btn ms-5"
                                onClick={() => SubmitScheduleTransfer()}
                            >
                                <span>Submit Schedule Transaction</span>
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default DirectScheduleTransfer