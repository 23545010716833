import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function CollectionTraits({ passTraits, traitsOfSelectedLayer, isProbsValid }) {
  const { t } = useTranslation()
  const [collectionTraits, setCollectionTraits] = useState(
    traitsOfSelectedLayer
  );

  const [newTrait, setNewTrait] = useState({
    traitOption: '',
    traitProb: '',
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setNewTrait((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addTrait = () => {
    if (
      newTrait.traitOption.trim() === '' ||
      newTrait.traitProb.trim() === ''
    ) {
      return;
    }
    const updatedTraits = [...collectionTraits, newTrait];

    setCollectionTraits(updatedTraits);
    passTraits(updatedTraits);
    setNewTrait({
      traitOption: '',
      traitProb: '',
    });
  };
  const removeTrait = (index) => {
    const updatedTraits = [...collectionTraits];
    updatedTraits.splice(index, 1);
    setCollectionTraits(updatedTraits);
    passTraits(updatedTraits);
  };

  useEffect(() => {
    setCollectionTraits(traitsOfSelectedLayer);
  }, [traitsOfSelectedLayer]);
  return (
    <div>
      <div className="row">
        <div className="col">
          <input
            type="text"
            placeholder={t("dashboard.tabs.createCollection.option")}
            className="text-gray-700"
            name="traitOption"
            value={newTrait.traitOption}
            onChange={handleChange}
          />
        </div>
        <div className="col">
          <input
            type="number"
            placeholder={t("dashboard.tabs.createCollection.probability")}
            className="text-gray-700"
            name="traitProb"
            value={newTrait.traitProb}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="fugu--card-footer mb-4">
        <button
          className="fugu--btn btn-sm bg-white"
          onClick={addTrait}
          disabled={newTrait.traitOption === '' || newTrait.traitProb === ''}
        >
          {t("dashboard.tabs.createCollection.add")}
        </button>
      </div>
      <div className="fugu--tags fugu--tags2">
        <ul>
          {collectionTraits.map((trait, index) => (
            <li key={index}>
              <button
                className="position-absolute"
                onClick={() => removeTrait(index)}
                style={{ zIndex: 100 }}
              >
                <FontAwesomeIcon icon={faTimes} color="#fff" />
              </button>
              <div>
                <p className="text-uppercase font-weight-bold">
                  {trait.traitOption}
                </p>
                <p>{trait.traitProb}</p>
              </div>
            </li>
          ))}
          {!isProbsValid && collectionTraits.length > 0 && (
            <span className="text-danger">{t("dashboard.tabs.createCollection.sumOfProbsNote")}</span>
          )}
        </ul>
      </div>
    </div>
  );
}

export default CollectionTraits;
