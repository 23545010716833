
import { getDepositedNfts } from "../components/web3/nfts-utils/nfts-proxy/NftsProxy_Utils"
import { ChainsObjectsArr } from "../chainsStaticObject/chainsStaticData";
//const { ethers } = require('ethers');

export const FetchUsersNewBenefits = async (walletId, loginOption) => {
    let nftsArr = []
    let tokensFundArr = []

    //fetch tokens and nft in all chains that supported those services
    for (const chain of ChainsObjectsArr) {
        if (chain.isSupportClaimNFT && chain.supportedOptions[loginOption]?.Claim_NFTs) {
            const nftsPerChain = await getDepositedNfts(walletId, chain)
            if (nftsPerChain.length > 0) nftsArr.push({ nftsPerChain, chain })
        }
        // if (chain.supportedOptions[loginOption]?.Claim_Tokens) {
        //     const FundArr = await chain.network.getAllDepositsBalance(walletId)
        //     if (tokensFundArr.length > 0) tokensFundArr.push({ FundArr, chain })
        // }
    }
    // check if user have a benefits tokens or nfts
    if (nftsArr.length > 0 || tokensFundArr.length > 0) {
        let userDepositedNfts = localStorage.getItem(
            `userDepositedNfts-address-${walletId}`
        );
        let userBenefits = {
            nftsArr,
            tokensFundArr,
            showMeAgain: true
        };
        if (!userDepositedNfts) {
            // user got a new benefits in the first time             
            const data = JSON.stringify(userBenefits);
            //sort benefits in the localStorage
            localStorage.setItem(`userDepositedNfts-address-${walletId}`, data)
            return userBenefits
        } else {
            //user received benefits before and has a data in the localStorage
            let oldData = JSON.parse(userDepositedNfts); //the retrieved data from the localStorage
            // implement the fetched data from the contracts            
            if (!oldData.showMeAgain) {
                //debugger
                let newNftObjectArr = []
                let fundTokens = [];
                for (const newNftsObjectPerChain of userBenefits.nftsArr || []) {
                    let newNfts = [];
                    //loop in the deprecated object per the chains
                    for (const oldNftObject of oldData.deprecated.nftsArr || []) {
                        if (newNftsObjectPerChain.chain.chain === oldNftObject.chain.chain) {
                            // Loop through the new NFTs per chain
                            newNftsObjectPerChain.nftsPerChain.forEach(newNftObject => {
                                const isNewNft = oldNftObject.nftsPerChain.every(oldNft => {
                                    // const oldTokenId = ethers.BigNumber.from(oldNft.tokenId);
                                    // const newTokenId = ethers.BigNumber.from(newNftObject.tokenId);
                                    //return (parseInt(oldTokenId) !== parseInt(oldTokenId) || oldNft.contractAddress !== newNftObject.contractAddress);
                                    return(oldNft.tokenUri != newNftObject.tokenUri)
                                });
                                if (isNewNft) {
                                    newNfts.push(newNftObject);
                                }
                            });
                        }
                    }
                    if (newNfts.length > 0) {
                        const newNftObj = {
                            nftsPerChain: newNfts,
                            chain: newNftsObjectPerChain.chain,
                        }
                        newNftObjectArr.push(newNftObj)
                    }
                }
                if (newNftObjectArr.length > 0){
                    //create new local object with retrieved data
                    const newLocalData = {
                        nftsArr: newNftObjectArr,
                        showMeAgain: true,
                        tokensFundArr: [],
                        deprecated: oldData.deprecated ? oldData.deprecated : []
                    }
                    const data = JSON.stringify(newLocalData);
                    localStorage.setItem(`userDepositedNfts-address-${walletId}`, data)
                    return newLocalData
                }else{
                    //return the old object
                    const data = JSON.stringify(oldData);
                    localStorage.setItem(`userDepositedNfts-address-${walletId}`, data)
                    return oldData
                }
            } else {
                // replace the whole object in the local storage as it is with the retrieved data from the contracts
                const data = JSON.stringify(userBenefits);
                //sort benefits in the localStorage
                localStorage.setItem(`userDepositedNfts-address-${walletId}`, data)
                return userBenefits
            }
        }
    }
}