import emailIcon from "../../../components/social-wallet/icon/email-icon.png"
import cryptoWallet from "../../../assets/images/social/cryptoWallet.png";
import phoneIcon from "../../../components/social-wallet/icon/phone-icon.png"
import facebookIcon from "../../../components/social-wallet/icon/facebook-icon.png";
import twitterIcon from "../../../components/social-wallet/icon/twitter-icon.png"
import i18n from "../../../i18n";

export const DemoDropdownMenus = [
  {
    title: "Home 01",
    path: "/",
  },
  {
    title: "Home 02",
    path: "index-two",
  },
  {
    title: "Home 03",
    path: "index-three",
  },
  {
    title: "Home 04",
    path: "index-four",
  },
  {
    title: "Home 05",
    path: "index-five",
  },
];
export const PagesDropdownMenus = [
  {
    title: "About",
    path: "about",
    submenu: [
      {
        title: "About Dark",
        path: "about-dark",
      },
      {
        title: "About Light 01",
        path: "about-us",
      },
      {
        title: "Blog Light 02",
        path: "about-light",
      },
    ],
  },
  {
    title: "Blog Light",
    path: "blog",
    submenu: [
      {
        title: "Blog Light",
        path: "blog-light",
      },
      {
        title: "Blog Light Sidebar 01",
        path: "blog",
      },
      {
        title: "Blog Light Sidebar 02",
        path: "blog-light-sidebar",
      },
      {
        title: "Single Blog Light",
        path: "single-blog-light",
      },
    ],
  },
  {
    title: "Blog Dark",
    path: "blog-dark",
    submenu: [
      {
        title: "Blog Dark",
        path: "blog-dark",
      },
      {
        title: "Blog Dark Sidebar",
        path: "blog-dark-sidebar",
      },
      {
        title: "Blog Dark Details",
        path: "single-blog-dark",
      },
    ],
  },
  {
    title: "Service",
    path: "#",
    submenu: [
      {
        title: "Services",
        path: "service",
      },
      {
        title: "Single Service",
        path: "single-service",
      },
    ],
  },
  {
    title: "Team",
    path: "team",
    submenu: [
      {
        title: "Team Dark",
        path: "team-dark",
      },
      {
        title: "Team Light 01",
        path: "team",
      },
      {
        title: "Team Light 02",
        path: "team-light",
      },
      {
        title: "Single Team",
        path: "single-team",
      },
    ],
  },
  {
    title: "Pricing",
    path: "pricing",
    submenu: [
      {
        title: "Pricing 01",
        path: "pricing-one",
      },
      {
        title: "Pricing 02",
        path: "pricing-two",
      },
    ],
  },
  {
    title: "Carrer",
    path: "carrer",
    submenu: [
      {
        title: "Career",
        path: "carrer",
      },
      {
        title: "Single-Career",
        path: "sinlge-career",
      },
    ],
  },
  {
    title: "Portfolio",
    path: "portfolio",
    submenu: [
      {
        title: "Portfolio Classic",
        path: "portfolio-one",
      },
      {
        title: "Portfolio-Minimal",
        path: "portfolio-two",
      },
      {
        title: "Portfolio-Modern",
        path: "portfolio-three",
      },
      {
        title: "Single Portfolio",
        path: "single-portfolio",
      },
    ],
  },
  {
    title: "Utility",
    path: "utility",
    submenu: [
      {
        title: "Faq",
        path: "faq",
      },
      {
        title: "Error-404",
        path: "404",
      },
      {
        title: "Testimonial",
        path: "testimonial",
      },
      {
        title: "coming-soon",
        path: "coming-soon",
      },
    ],
  },
];

export const ElementsMegaMenu = [
  {
    title: "About Style",
    path: "#",
    submenu: [
      {
        title: "About Dark",
        path: "about-dark",
      },
      {
        title: "About Light 01",
        path: "about-us",
      },
      {
        title: "Blog Light 02",
        path: "about-light",
      },
    ],
  },

  {
    title: "Blog Style",
    path: "#",
    submenu: [
      {
        title: "Blog Dark",
        path: "blog-dark",
      },
      {
        title: "Blog Dark Sidebar",
        path: "blog-dark-sidebar",
      },
      {
        title: "Blog Dark Details",
        path: "single-blog-dark",
      },
      {
        title: "Blog Light",
        path: "blog-light",
      },
      {
        title: "Blog Light Sidebar 01",
        path: "blog",
      },
      {
        title: "Blog Light Sidebar 02",
        path: "blog-light-sidebar",
      },
      {
        title: "Single Blog light",
        path: "single-blog",
      },
    ],
  },

  {
    title: "Masonry Style",
    path: "#",
    submenu: [
      {
        title: "Portfolio Classic",
        path: "portfolio-one",
      },
      {
        title: "Portfolio-Minimal",
        path: "portfolio-two",
      },
      {
        title: "Portfolio-Modern",
        path: "portfolio-three",
      },
      {
        title: "Single Portfolio",
        path: "single-portfolio",
      },
    ],
  },

  {
    title: "Contact Style",
    path: "#",
    submenu: [
      {
        title: "Contact Dark",
        path: "contact-dark",
      },
      {
        title: "Contact Light 01",
        path: "contact",
      },
      {
        title: "Contact Light 02",
        path: "contact-light",
      },
    ],
  },
];

export const BlogDropdownMenus = [
  {
    title: "Blog Dark",
    path: "blog-dark",
  },
  {
    title: "Blog Dark Sidebar",
    path: "blog-dark-sidebar",
  },
  {
    title: "Blog Dark Details",
    path: "single-blog-dark",
  },
  {
    title: "Blog Light",
    path: "blog-light",
  },
  {
    title: "Blog Light Sidebar 01",
    path: "blog",
  },
  {
    title: "Blog Light Sidebar 02",
    path: "blog-light-sidebar",
  },
  {
    title: "Single Blog Light",
    path: "single-blog-light",
  },
];

export const ContactDropdownMenus = [
  {
    title: "Contact Dark",
    path: "contact-dark",
  },
  {
    title: "Contact Light 01",
    path: "contact",
  },
  {
    title: "Contact Light 02",
    path: "contact-light",
  },
];

export const transferSelectedMethod = [
  {
    title: "general.email",
    name: "Email",
    icon: emailIcon,
    disabled: false,
    toolTipText: "Deposit to Email",
    csvSample: "xx@xx.xxx, xx@xx.xxx, xx@xx.xxx"
  },
  {
    title: "general.wallet",
    name: "Wallet",
    icon: cryptoWallet,
    disabled: false,
    toolTipText: "Transfer to Wallet",
    csvSample: "0xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx, 0xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx, 0xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"

  },
  {
    title: "general.phone",
    name: "Phone",
    icon: phoneIcon,
    disabled: false,
    toolTipText: "Deposit to Phone",
    csvSample: "+0111111111111, +0111111111111, +0111111111111"


  },
  {
    title: "general.twitter",
    name: "Twitter",
    icon: twitterIcon,
    disabled: false,
    toolTipText: "Deposit to Twitter",
    csvSample: "xxxx_xxx, xxxxxx, xx_xxxxx"
  },
  {
    title: "general.facebook",
    name: "Facebook",
    icon: facebookIcon,
    disabled: true,
    toolTipText: "we are still working on it"

  },
];
