import Isotope from "isotope-layout";
import React, { useEffect, useRef, useState, useCallback } from "react";
import Dropdown from "react-dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../components/web3/context/AuthContext";
import { getNfts } from "../../components/web3/utils/utils";
import nftPlaceHolder from "../../assets/images/all-img/v3/card9.jpg";
import { isValidEmail, isValidTwitterAccount, isValidWalletAddress } from "../../helper/helperFunc";
import { toast } from "react-hot-toast";
import twitterLogo from "../../assets/images/social2/twitterx.svg";
import openseaLogo from "../../assets/images/social2/OpenSea.png";
import { TwitterShareButton } from "react-share";
import {
  depositNft_custodial,
  depositNft_nonCustodial,
  transferNft_custodial,
  transferNft_nonCustodial,
} from "../../components/web3/nfts-utils/nfts-proxy/NftsProxy_Utils";
import { openPasswordModel } from "../../components/social-wallet/PasswordModal";
import { transferSelectedMethod } from "../../components/layout/navbar/menu-data";
import phone from "phone";
import Preloader from "../../components/common/preloader/preloader";
import ReactGA from "react-ga4";
import axios from "axios";
import { useTranslation } from "react-i18next";

function MyNfts() {
  const { t } = useTranslation();
  const isotope = useRef();
  const [walletNfts, setWalletNfts] = useState([]);
  const [isBrokenImage, setIsBrokenImage] = useState([]);
  const {
    balance,
    network,
    entityInfo,
    hasPassword,
    loginOption,
    email,
    isLoading,
    isLoggedIn,
    setIsLoading,
  } = useAuth();
  const [showTransferInput, setShowTransferInput] = useState([]);
  const [emailInputs, setEmailInputs] = useState([]);
  const [userSelection, setUserSelection] = useState(transferSelectedMethod[0]);
  const [inputPlaceHolder, setInputPlaceHolder] = useState(t("general.email"));
  const [inputElement, setInputElement] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [shortUrls, setShortUrls] = useState([]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/MyNfts",
      title: "My NFTS Page",
    });
    setShowTransferInput(Array(walletNfts.length).fill(false));
    setEmailInputs(Array(walletNfts.length).fill(""));
  }, [walletNfts]);

  useEffect(() => {
    fetchNfts();
  }, [isLoggedIn, entityInfo, network?.chain]);

  useEffect(() => {
    if (transferSelectedMethod.length > 0) {
      setSelectedOption({
        value: transferSelectedMethod[0].icon,
        label: (
          <div className="dropdown-item">
            <img
              src={transferSelectedMethod[0].icon}
              alt={transferSelectedMethod[0].name}
              className="fugu-network-icons mx-2"
            />
          </div>
        ),
      });
    }
  }, []);

  const shortenUrl = async (originalLink) => {
    try {
      const response = await axios.post(
        "https://api.short.io/links",
        {
          domain: "g1g7.short.gy",
          originalURL: originalLink,
        },
        {
          headers: {
            accept: "application/json",
            authorization: "sk_ozgzfGhBIpMreHkW",
            "content-type": "application/json",
          },
        }
      );
      return response.data.shortURL;
    } catch (e) {
      return originalLink;
    }
  };

  useEffect(() => {
    const generateShortUrls = async () => {
      const urls = await Promise.all(
        walletNfts.map(async (elem) => {
          const longUrl = `${process.env.REACT_APP_TEST_OPENSEA_URL}/${network?.chainName}/${elem.contractAddress}/${elem.tokenId}`;
          return await shortenUrl(longUrl);
        })
      );
      setShortUrls(urls);
    };

    if (walletNfts.length > 0) {
      generateShortUrls();
    }
  }, [walletNfts, network]);

  const fetchNfts = useCallback(async () => {
    try {
      setIsLoading(true);
      const nfts = await getNfts(entityInfo?.activePublicKey, network.chain);
      if (nfts) setWalletNfts(nfts);
    } catch (err) {
      console.log(t("dashboard.tabs.nfts.errorFetchNfts"));
    } finally {
      setIsLoading(false);
    }
  }, [entityInfo, network, setIsLoading, t]);

  useEffect(() => {
    if (walletNfts) {
      initializeIsotope();
    }
  }, [walletNfts]);

  const initializeIsotope = () => {
    isotope.current = new Isotope("#fugu--nft-four-column", {
      itemSelector: ".collection-nft-grid-item",
      resizable: false,
      masonry: {
        columnWidth: ".collection-nft-grid-item",
        gutterWidth: 0,
        isFitWidth: true,
      },
    });
  };

  const handleImageError = (index) => {
    setIsBrokenImage((prevBrokenImages) => {
      const updatedBrokenImages = [...prevBrokenImages];
      updatedBrokenImages[index] = true;
      return updatedBrokenImages;
    });
  };

  const handleTransferClick = (index) => {
    toggleElementIndex(true, index);
    setShowTransferInput((prev) =>
      prev.map((value, idx) => (idx === index ? true : false))
    );
  };

  const isValid = (input, dropdownSelection) => {
    if (!dropdownSelection) return false;
    switch (dropdownSelection.name) {
      case "Email":
        return isValidEmail(input);
      case "Phone":
        return phone(input).isValid;
      case "Wallet":
        return isValidWalletAddress(input);
      case "Twitter":
        return isValidTwitterAccount(input);
      default:
        return false;
    }
  };

  const handleInputChange = (index, e) => {
    const value = e.target.value;
    const newInputElement = e.target;
    setInputElement(newInputElement);
    newInputElement.style.color = isValid(value, userSelection) ? "#fff" : "red";
    setEmailInputs((prev) => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  };

  const handleTransferConfirmation = async (index) => {
    if (balance < 0.001) {
      return toast.error(t("dashboard.tabs.nfts.notEnoughBalance"));
    }
    let to = emailInputs[index];
    if (userSelection.name === "Twitter") {
      to += "@x.com";
      emailInputs[index] = to;
    }
    if (isValid(to, userSelection)) {
      setIsLoading(true);
      try {
        if (loginOption === "custodial") {
          const action = userSelection.name === "Wallet" ? handleSocialTransfer : handleSocialDeposit;
          if (hasPassword) {
            openPasswordModel(action, index, setIsLoading);
          } else {
            action(null, index);
          }
        } else if (loginOption === "non_custodial") {
          userSelection.name === "Wallet" ? await transferNft_nonCustodial(entityInfo.activePublicKey, to, walletNfts[index], network) :
            await depositNft_nonCustodial(to, walletNfts[index], network);
          fetchNfts();
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      const errMes = !userSelection && email === "" ? t("dashboard.tabs.nfts.selectReceiver") :
        !userSelection ? t("dashboard.tabs.nfts.selectTransferType") :
          `Invalid ${userSelection.name}`;
      toast.error(errMes);
    }
  };

  const handleSocialTransfer = async (password, index) => {
    if (hasPassword && !password) return toast.error("Password is required");
    await transferNft_custodial(
      email,
      entityInfo.indexWallet,
      entityInfo.activePublicKey,
      emailInputs[index],
      walletNfts[index],
      network,
      password
    );
    fetchNfts();
  };

  const handleSocialDeposit = async (password, index) => {
    if (hasPassword && !password) return toast.error("Password is required");
    await depositNft_custodial(
      email,
      entityInfo.indexWallet,
      emailInputs[index],
      walletNfts[index],
      network,
      password
    );
    fetchNfts();
  };

  const toggleElementIndex = (display, index) => {
    if (isotope.current) {
      const gridItems = isotope.current.element.querySelectorAll(".collection-nft-grid-item");
      gridItems.forEach((item, idx) => {
        item.style.zIndex = idx === index && display ? "99" : "initial";
      });
    }
    if (!display) {
      setShowTransferInput((prev) =>
        prev.map((value, idx) => (idx === index ? false : value))
      );
    }
    setInputPlaceHolder(t("general.email"));
    setUserSelection(transferSelectedMethod[0]);
    setEmailInputs((prev) => {
      const updated = [...prev];
      updated[index] = "";
      return updated;
    });
  };

  const handleDropDownSelection = (e, index) => {
    setUserSelection(e);
    if (inputElement) {
      inputElement.style.color = isValid(emailInputs[index], e) ? "#fff" : "red";
    }
    setInputPlaceHolder(t(e.title));
  };

  return (
    <div className="mt-5">
      <Preloader show={isLoading} />
      <div className="fugu--section-title">
        <div className="fugu--default-content content-sm">
          <h2>{t("dashboard.tabs.nfts.exploreNft")}</h2>
          <p>{t("dashboard.tabs.nfts.nftString")}</p>
        </div>
      </div>
      <div className="container">
        <div id="fugu--nft-four-column" className="row position-relative h-auto">
          {walletNfts.map((elem, index) => (
            <div
              className={`mb-4 col-lg-3 col-md-6 col-12 ${showTransferInput[index] ? "transfer-active" : ""}`}
              key={index}
            >
              <div className="fugu--card-wrap">
                <div className="fugu--card-thumb">
                  <img
                    src={elem.metadata ? elem.metadata.image : nftPlaceHolder}
                    alt=""
                    onError={() => handleImageError(index)}
                  />
                </div>
                <div className="fugu--card-data">
                  <h3>{`${elem.name} - #${elem.tokenId}`}</h3>
                  <div className="fugu--social-icon">
                    <ul>
                      <li>
                        {shortUrls[index] ? (
                          <TwitterShareButton
                            url={shortUrls[index]}
                            title="🔥 Discover the gateway to a new digital era with my exclusive NFT on HorusWallet! 🌟 Immerse yourself in the captivating world of art and blockchain. 🎨✨ Join me on this journey today. 💫 #NFT #HorusWallet #DigitalArt"
                            via="HorusWallet"
                          >
                            <img src={twitterLogo} alt="" />
                          </TwitterShareButton>
                        ) : (
                          <div>Loading...</div>
                        )}
                      </li>
                      <li>
                        <a
                          href={`${process.env.REACT_APP_TEST_OPENSEA_URL}/${network.chainName}/${elem.contractAddress}/${elem.tokenId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={openseaLogo} alt="" />
                        </a>
                      </li>
                      <li>
                        {showTransferInput[index] && (
                          <div className="col">
                            <Dropdown
                              options={transferSelectedMethod
                                .filter((item) => !item.disabled)
                                .map((item, optionIndex) => ({
                                  value: item,
                                  label: (
                                    <div key={optionIndex} className="dropdown-item">
                                      <img
                                        src={item.icon}
                                        alt={item.name}
                                        className="fugu-network-icons mx-2"
                                      />
                                    </div>
                                  ),
                                }))}
                              onChange={(e) => handleDropDownSelection(e.value, index)}
                              placeholder="select"
                              arrowClosed={<FontAwesomeIcon icon={faAngleDown} className="ms-2" />}
                              arrowOpen={<FontAwesomeIcon icon={faAngleUp} className="ms-2" />}
                              className="fugu-dropdown dropdown-control"
                              controlClassName="fugu-dropdown dropdown-control"
                              arrowClassName="dropdown-arrow"
                              placeholderClassName="dropdown-placeholder"
                              value={selectedOption}
                            />
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="fugu--card-footer">
                    {showTransferInput[index] ? (
                      <div className="d-block">
                        <div className="input-wrapper d-flex align-items-center">
                          <input
                            type="default"
                            placeholder={inputPlaceHolder}
                            value={emailInputs[index]}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                          {userSelection.name === "Twitter" && <span className="ms-1 text-white">@x.com</span>}
                        </div>
                        <div className="button-wrapper row">
                          <button
                            className="fugu--btn btn-sm bg-white col m-2"
                            onClick={() => handleTransferConfirmation(index)}
                          >
                            {t("general.ok")}
                          </button>
                          <button
                            className="fugu--btn btn-sm bg-white col m-2"
                            onClick={() => toggleElementIndex(false, index)}
                          >
                            {t("general.cancel")}
                          </button>
                        </div>
                      </div>
                    ) : (
                      elem.contractAddress !== process.env.REACT_APP_QRNFT_CONTRACT_ADDRESS && (
                        <div className="row">
                          <div className="col">
                            <button
                              className="fugu--btn btn-sm bg-white"
                              onClick={() => handleTransferClick(index)}
                            >
                              {showTransferInput[index] ? t("general.ok") : t("general.transfer")}
                            </button>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MyNfts;
