import { BlockchainNetwork } from "./BlockchainNetwork";
import { getCasperAccountBalance } from "../CASPER-utils/casperUtils";
import { casperBatchTransfer, casperTokenDeposit, getDepositsBalance } from "../CASPER-utils/casper-proxy-payment";
import { isValidCasperWallet, validateCasperWallet } from "../../../helper/helperFunc";
import { CHAIN_SCAN } from "./Providers";
export class CasperNetwork extends BlockchainNetwork {
    constructor(chain: string) {
        super(chain);
    }

    getAddress(userWallets: any) {
        return userWallets.CASPER.wallets[0].publicKey;
    }

    getWallets(userWallets: any, shortenKeyWidth: number) {
        if (!userWallets) return [];
        return userWallets.map((elem: any) => {
            return {
                address: elem.publicKey,
                hasPassword: elem.hasPassword,
                walletIndex: elem.index,
                publicKey: elem.publicKey,
                label:
                    elem.publicKey?.slice(0, shortenKeyWidth) +
                    "..." +
                    elem.publicKey?.slice(-4),
            };
        });
    }

    async getBalance(address: string, network: any): Promise<any> {
        return await getCasperAccountBalance(address);
    }

    async getDeposit(email: string, tokenAddress: string): Promise<any> {
        return await getDepositsBalance(email);
    }

    async getAllDepositsBalance(email: string): Promise<any> {
        return [await getDepositsBalance(email)];
    }

    isValidAddress(address: string, status = true): any {
        return isValidCasperWallet(address);
    }

    validateWalletAddress(addresses: []): any {
        return validateCasperWallet(addresses)
    }

    async depositToken(email: string, walletAddress: string, walletIndex: number, receiversIds: [string], amount: string, network: string, password: string): Promise<any> {
        return casperTokenDeposit(email, parseInt(amount), receiversIds, undefined, walletAddress, network, password)
    }

    async transferToken(email: string, walletAddress: string, walletIndex: number, receiversIds: [string], amount: string, network: string, password: string): Promise<any> {
        return casperBatchTransfer(email, receiversIds, parseInt(amount), network, walletIndex, password, "CASPER Transfer");
    }

    getChainScanUrlAddress() {
        const chain = CHAIN_SCAN[this.chainName as keyof typeof CHAIN_SCAN]
        return chain[process.env.REACT_APP_NETWORK_TYPE as keyof typeof chain] + 'account/';
    }

}