
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import heroImage from "../../assets/images/all-img/v3/hero-thumb3.png"
import heroBg from "../../assets/images/all-img/v3/hero-bg.png"
import { useTranslation } from "react-i18next";
export default function HeroSection() {
	const { t } = useTranslation();
	return (
		<div className="fugu--hero-section" style={{ backgroundImage: `url(${heroBg})` }}>
			<div id="fugu--counter"></div>
			<div className="container">
				<div className="row">
					<div className="col-lg-7">
						<div className="fugu--hero-content">
							<h1 className="wow fadeInUpX" data-wow-delay="0s">
								{t('dashboard.banner.title')}
							</h1>
							<p className="wow fadeInUpX" data-wow-delay="0.10s">
								{t('dashboard.banner.subTitle')}
							</p>
							{/* <div className="fugu--btn-wrap fugu--hero-btn wow fadeInUpX" data-wow-delay="0.20s">
								<Link href={"#"} legacyBehavior>
									<a className="fugu--btn bg-gray active">Get Started</a>
								</Link>
								<Link href={"#"} legacyBehavior>
									<a className="fugu--btn bg-gray">View Artwork</a>
								</Link>
							</div> */}
							<div className="fugu--counter-wrap wow fadeInUpX" data-wow-delay="0.30s">
								<div className="fugu--counter-data">
									<h2>
										<span data-percentage="45" className="fugu--counter">
											<CountUp end={45} />
										</span>
										<strong>{t('general.k')}</strong>
									</h2>
									<p>{t('general.wallets')}</p>
								</div>
								<div className="fugu--counter-data">
									<h2>
										<span data-percentage="86" className="fugu--counter">
											<CountUp end={86} />
										</span>
										<strong>{t('general.k')}</strong>
									</h2>
									<p>{t('general.transactions')}</p>
								</div>
								{/* <div className="fugu--counter-data">
									<h2>
										<span data-percentage="32" className="fugu--counter">
											<CountUp end={32} />
										</span>
										<strong>K</strong>
									</h2>
									<p>Artist</p>
								</div> */}
							</div>
						</div>
					</div>
					<div className="col-lg-5">
						<div className="fugu--hero-right" id="rotateOne">
							<div className="fugu--card-wrap">
								<div className="fugu--card-thumb">
									<img src={heroImage} alt="" />
								</div>
								<div className="fugu--card-data">
									<h3>{t('dashboard.banner.horusNFTCollection')}</h3>
									<p>{t('general.preSale')} : 18 May 2024</p>
									<div className="fugu--card-footer">
										<div className="fugu--card-footer-data">
											<span>{t('general.mintPrice')}</span>
											<h4>0.194 {t('general.eth')}</h4>
										</div>
										{/* <Link href={"#"} legacyBehavior>
											<a className="fugu--btn btn-sm bg-white">Place a Bid</a>
										</Link> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="fugu--hero-shape1">
					{/* <img src="/images/all-img/v3/shape-hero1.png" alt="" /> */}
				</div>
				<div className="fugu--hero-shape2"></div>
			</div>
		</div>
	);
}
