import { BlockchainAPI } from "./BlockchainAPI";
import { NATIVE_TOKENS } from "../utils/Providers";
import { ALCHEMY_APIS_DICT } from "./Service";
import { getNativeTokens } from "../utils/utils";
import { ethers } from "ethers";

export class AlchemyAPI extends BlockchainAPI {
  constructor(network) {
    super(network);
    if (
      !network ||
      typeof network !== "string" ||
      !ALCHEMY_APIS_DICT[network]
    ) {
      return;
    }
    this.api = ALCHEMY_APIS_DICT[this.network][this.networkType];
  }

  async getDepositBalances(tokens) {
    if (!tokens || !Array.isArray(tokens)) {
      throw new Error("Invalid tokens array");
    }
    // Create an array to store token dictionaries
    let depositedTokens = [];
    // Loop through all tokens with non-zero balance
    for (let token of tokens) {
      if (!token || typeof token !== "object") {
        console.error("Invalid token object: ", token);
        continue;
      }
      if (token.depositAmount === 0) continue;
      let metadata = null;
      let tokenDict = null;
      // Fetch token metadata
      try {
        if (token.contractAddress == ethers.constants.AddressZero)
          metadata = NATIVE_TOKENS[this.network][this.networkType];
        else if (this.api)
          metadata = await this.api.core.getTokenMetadata(
            token.contractAddress
          );
      } catch (err) {
        console.error(
          `Error fetching token metadata for ${token.contractAddress}: `,
          err
        );
        continue;
      }
      // Compute token balance in human-readable format
      let balance = token.depositAmount / Math.pow(10, metadata.decimals);

      balance = Math.floor(balance * 1000000) / 1000000;
      // Create a dictionary for this token
      tokenDict = {
        name: metadata.name,
        symbol: metadata.symbol,
        contractAddress: token.contractAddress,
        logo: metadata.logo,
        balance: balance.toString(),
      };
      // Add the dictionary to the array
      depositedTokens.push(tokenDict);
    }
    // Return the array of token dictionaries
    return depositedTokens;
  }

  async getAllBalances(walletAddress) {
    if (!this.api) return [];

    if (!walletAddress || typeof walletAddress !== "string") {
      return [];
    }

    try {
      // Get token balances
      const etherBalance = await getNativeTokens(this.network, walletAddress);

      const nativeToken = NATIVE_TOKENS[this.network][this.networkType];
      nativeToken.balance = parseFloat(etherBalance).toFixed(4);
      // Create an array to store token dictionaries
      let tokens = [];

      // Add native token to the array
      tokens.push(nativeToken);

      if (!this.api) return tokens;
      // Remove tokens with zero balance
      const balances = await this.api.core.getTokenBalances(walletAddress);
      const nonZeroBalances = balances.tokenBalances.filter((token) => {
        return token.tokenBalance !== "0";
      });
      // Loop through all tokens with non-zero balance
      for (let token of nonZeroBalances) {
        // Get balance of token
        let balance = token.tokenBalance;

        // Get metadata of token
        const metadata = await this.api.core.getTokenMetadata(
          token.contractAddress
        );

        // Compute token balance in human-readable format
        balance = balance / Math.pow(10, metadata.decimals);
        balance = balance.toFixed(4);

        // Create a dictionary for this token
        let tokenDict = {
          name: metadata.name,
          symbol: metadata.symbol,
          contractAddress: token.contractAddress,
          logo: metadata.logo,
          balance: balance,
          decimals: metadata.decimals,
        };

        // Add the dictionary to the array
        tokens.push(tokenDict);
      }

      console.log(tokens);
      // Return the array of token dictionaries
      return tokens;
    } catch (error) {
      console.error("Error getting balances with Alchemy: ", error);
      // throw new Error("Error getting balances with Alchemy");
    }
  }

  async getNFTs(walletAddress) {
    if (!this.api) return [];

    if (!walletAddress || typeof walletAddress !== "string") {
      return [];
    }

    try {
      // Get user NFTs
      const req = await this.api.nft.getNftsForOwner(walletAddress);
      const nfts = req.ownedNfts;
      const resNfts = [];
      for (let nft of nfts) {
        const metadata = {
          contractAddress: nft.contract.address,
          metadata: {
            image: nft.image.cachedUrl || nft.raw.metadata.image,
            name: nft.raw.metadata.name || nft.name || nft.contract.name,
            attributes: nft.raw.metadata.attributes,
          },
          tokenUri: this.getTokenUri(
            nft.tokenType,
            nft.tokenId,
            nft.raw.tokenUri
          ),
          tokenType: nft.tokenType,
          name: nft.name || nft.contract.name,
          tokenId: nft.tokenId,
          balance: nft.balance,
        };
        resNfts.push(metadata);
      }
      console.log(resNfts);
      return resNfts;
    } catch (error) {
      console.error("Error getting NFTs with Alchemy: ", error);
      // throw new Error("Error getting NFTs with Alchemy");
    }
  }

  getTokenUri(tokenType, tokenId, tokenUri) {
    if (tokenType === "ERC1155") {
      return tokenUri.replace("{id}", tokenId);
    } else {
      return tokenUri;
    }
  }
}
