import React, { useEffect, useState } from "react";
import { useAuth } from "./web3/context/AuthContext";
import CongratsModal from "./custom-modal/congratsBenefitsModal";
import { FetchUsersNewBenefits } from "../helper/fetchNewBenfitsHandler";

function CallCongrates() {
    const { email, isLoggedIn, loginOption, entityInfo, network, supportedOptions } = useAuth()
    const [showBenefitsModal, setShowBenefitsModal] = useState(false);
    const [userBenefits, setUserBenefits] = useState(null)


    const [canFetchBenefits, setCanFetchBenefits] = useState(false);
    const isLoggedInRef = React.useRef(isLoggedIn); // Ref to store the latest `isLoggedIn` value

    // Update the ref whenever `isLoggedIn` changes
    useEffect(() => {
        isLoggedInRef.current = isLoggedIn;
        if (isLoggedIn) {
            setCanFetchBenefits(true);
        } else {
            setCanFetchBenefits(false);
            setShowBenefitsModal(false);  // Immediately hide modal on logout
            setUserBenefits(null);        // Clear benefits on logout
        }
    }, [isLoggedIn]);

    useEffect(() => {
        // AbortController for manual cancellation of async calls
        const controller = new AbortController();

        const fetchBenefits = async () => {
            if (!canFetchBenefits || !isLoggedInRef.current) return; // Check the latest `isLoggedIn` ref value

            if (
                loginOption &&
                entityInfo &&
                loginOption === "custodial" &&
                supportedOptions?.Claim_NFTs
            ) {
                try {
                    const benefits = await FetchUsersNewBenefits(email, loginOption, { signal: controller.signal });
                    if (!benefits || !isLoggedInRef.current) return; // Check ref again after async call

                    setUserBenefits(benefits);

                    if (benefits.showMeAgain && isLoggedInRef.current) {
                        setShowBenefitsModal(true);
                    }
                } catch (error) {
                    if (error.name === 'AbortError') {
                        console.log("Fetch canceled due to logout or dependency change");
                    } else {
                        console.error("Failed to fetch benefits", error);
                    }
                }
            }
        };

        fetchBenefits();

        return () => {
            controller.abort(); // Cancel any ongoing fetch if dependencies change
        };
    }, [canFetchBenefits, entityInfo, network, loginOption, email, supportedOptions]);

    return (
        <CongratsModal
            show={showBenefitsModal}
            handleCloseParent={() => setShowBenefitsModal(!showBenefitsModal)}
            benefits={userBenefits}
        />
    );
}

export default CallCongrates;
