// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './localization/en.json';
import translationAR from './localization/ar.json';
import translationSp from './localization/sp.json';
import translationGR from './localization/gr.json';
import translationRU from './localization/ru.json';

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
  sp: {
    translation: translationSp,
  },
  gr: {
    translation: translationGR,
  },
  ru: {
    translation: translationRU,
  },
};
const lang = localStorage.getItem("selectedLanguage")
i18n.use(initReactI18next).init({
  resources,
  lng: lang ? lang : 'en', // Set default language here
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
