import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import WalletMenuOption from './walletMenuOption';
import { useAuth } from '../web3/context/AuthContext';
import toast from 'react-hot-toast';
import { switchDefaultNetworkAddress } from '../../api/defaultNetworkWallet';

const CustomSelect = ({ options, disabled }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const { setIsLoggedIn, email, network, entityInfo, loginProvider, setHasPassword, setIsLoading, manualRefreshBalance } = useAuth()
    const [selectedValue, setSelectedValue] = useState()

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth < 922) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const setRecommendedWalletWithNetwork = async (selection) => {
        setIsLoading(true)
        setLoading(true)
        try {
            let change = await switchDefaultNetworkAddress(
                email,
                selection.address,
                false,
                network.chain
            );
            let chain = network.chain;
            const ChainHolderName = network.networkType            
            if (change.data) {
                const updatedUserWalletsData = {
                    ...entityInfo.userWalletsData,
                    [ChainHolderName]: {
                        ...entityInfo.userWalletsData[ChainHolderName],
                        ...entityInfo.defaultReceivingAddress,
                        [chain]: { receivingAddress: selection.address },
                    },
                };
                setIsLoggedIn(
                    true,
                    entityInfo.activePublicKey,
                    "custodial",
                    loginProvider,
                    entityInfo.indexWallet,
                    updatedUserWalletsData
                );
                toast.success("Recommended Wallet is set successfully");
                setLoading(false)
                setIsLoading(false)
            } else {
                toast.error("Change Recommended Wallet is failed");
                setLoading(false)
                setIsLoading(false)
            }
        } catch (e) {
            toast.error(e.message);
            setLoading(false)
            setIsLoading(false)
        }
    };
    const switchDefaultWallet = async (selection) => {
        setLoading(true)
        setIsLoading(true)
        try {
            let change = await switchDefaultNetworkAddress(
                email,
                selection.address,
                true,
                network.chain
            );
            console.log(change, "change");
            const ChainName = network.networkType            
            if (change.data) {
                const updatedUserWalletsData = {
                    ...entityInfo.userWalletsData,
                    [ChainName]: {
                        ...entityInfo.userWalletsData[ChainName],
                        defaultReceivingAddress: selection.address,
                    },
                };
                setIsLoggedIn(
                    true,
                    entityInfo.activePublicKey,
                    "custodial",
                    loginProvider,
                    entityInfo.indexWallet,
                    updatedUserWalletsData
                );
                toast.success("Default Wallet changed successfully");
                setLoading(false)
                setIsLoading(false)
            } else {
                toast.error("Change default wallet is failed");
                setLoading(false)
                setIsLoading(false)
            }
        } catch (e) {
            toast.error(e.message);
            setLoading(false)
            setIsLoading(false)
        }
    };
    const switchWallet = (selection) => {
        setIsLoading(true)
        setHasPassword(selection.hasPassword);
        setIsLoggedIn(
            true,
            selection.address,
            "custodial",
            loginProvider,
            selection.walletIndex,
            entityInfo.userWalletsData
        );
        setSelectedValue(selection)
        manualRefreshBalance(selection.address, network)
    };
    const customOptions = options.map(option => ({
        value: option.publicKey,
        label: option.label,
        WalletMenuOption: (
            <WalletMenuOption
                selection={option}
                setRecommendedWalletWithNetwork={setRecommendedWalletWithNetwork}
                switchDefaultWallet={switchDefaultWallet}
                switchWallet={switchWallet}
                loading={loading} />
        )
    }));

    useEffect(() => {
        options &&
            setSelectedValue(options.find((item) => item.address == entityInfo.activePublicKey))
    }, [options, entityInfo])

    const OptionComponent = ({ children, ...props }) => {
        return (
            <components.Option {...props}>
                {props.data.WalletMenuOption}
            </components.Option>
        );
    };

    const customStyles = {
        container: (base) => ({
            ...base,
            display: 'flex',
            alignItems: "center",
            width: "100%"
        }),
        control: (base, state) => ({
            ...base,
            height: '34px',
            background: "transparent",
            border: "none",
            color: 'black',
            boxShadow: state.isFocused ? null : null,
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: 'auto',
            display: "block",
            background: "transparent",
            border: "none",
            padding: "0",
            input: { height: 0 },
            width: "100%",
            marginTop: 7
        }),
        input: (provided, state) => ({
            ...provided,
        }),
        indicatorSeparator: state => ({
            display: 'none',
            border: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            border: "none",
        }),
        placeholder: (provided) => ({
            ...provided,
            whiteSpace: 'nowrap',
        }),
        menu: (provided, state) => ({
            ...provided,
            color: 'black',
            width: isSmallScreen ? '320px' : 'auto',
            padding: 10,
            backgroundColor: isSmallScreen ? "rgba(225, 225, 225, 1)" : "rgba(225, 225, 225, .5)",

        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: 'transparent',
            '&:active': {
                backgroundColor: 'transparent',
            },
            color: 'black',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            color: "#fff",
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 })
    };
    const customComponents = {
        Option: OptionComponent,
        ...(disabled && { DropdownIndicator: () => null })
    };

    return (
        <div className='d-flex align-items-center'>
            <Select
                value={selectedValue}
                isSearchable={false}
                options={customOptions}
                styles={customStyles}
                components={customComponents}
                isDisabled={disabled}
            />
        </div>
    );
};


export default CustomSelect;
