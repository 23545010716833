import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Preloader from "../common/preloader/preloader";
import placeHolder from "../../assets/images/collections/aiIssuePlaceholder.png";
import {
  addUsherToCollectionCustodial,
  createCollectionCustodial,
  getCollectionsByUser,
  removeUsherFromCollectionCustodial,
  scanTokenCustodial,
} from "../web3/nfts-utils/nfts-access/NFTsAccess_Utils";
import { TOKEN_TYPE } from "../web3/nfts-utils/nfts-access/NFTsAccess_Constants";
import { useAuth } from "../web3/context/AuthContext";

function NFTAccessManager() {
  const { t } = useTranslation()
  const [nftsAccess, setNFTsAccess] = useState();
  const [loading, setLoading] = useState(false);
  const { entityInfo, network } = useAuth();
  const getCollections = async () => {
    const collections = await getCollectionsByUser(
      network.chain,
      entityInfo.activePublicKey
    );
    console.log(collections);
    setNFTsAccess(collections)
  }
  useEffect(() => {
    getCollections()
  },
    [entityInfo])

  return (
    <div className={` `}>
      <Preloader show={loading} />
      <div className="fugu--breadcrumbs-section  justify-content-center text-center opacity-bg ">
        <div className="col-lg-12">
          <div className="mb-5">
            {/* <h1>Select Collection</h1> */}
            <h3 className="mb-3">{t("dashboard.tabs.nftAccess.nftAccessManager")}</h3>
          </div>{" "}
          <Link
            className="fugu--outline-btn my-5"
            to={"/CreateNFTAccess"}
          // onClick={test}
          >
            <span>{t("dashboard.tabs.nftAccess.createNFTAccess")}</span>
          </Link>
          {nftsAccess && nftsAccess.length > 0 ? (
            <div className="row w-100 mx-0">
              {nftsAccess &&
                nftsAccess.length > 0 &&
                nftsAccess.map((elem, index) => (
                  <div className="col-md-4" key={index}>
                    <div
                      className="fugu--blog-wrap  fugu--blog-wrap2 wow fadeInUpX px-1"
                      data-wow-delay="0s"
                    >
                      <div className="fugu--blog-content">
                        <div className="fugu--blog-title text-uppercase ">
                          <div className="fugu--card-wrap min-height-auto">
                            <h3>{elem.name}</h3>
                            <div className="d-flex justify-content-center mb-4">
                              <Link
                                className="fugu--outline-btn"
                                to="/singleNFTAccess"
                                state={elem}
                              >
                                <span>{t("dashboard.tabs.nftAccess.manageNFTAccess")}</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

          ) : (
            <h1 className="text-warning-500 text-center mt-5 pt-5 text-uppercase">
              {t("dashboard.tabs.nftAccess.noNFTAccess")}
            </h1>
          )}   </div>
      </div>
    </div>
  );
}

export default NFTAccessManager;
