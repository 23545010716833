import { ethers } from "ethers";
import polygonIcon from "../../../assets/images/networks-icons/polygon.png";
import binanceIcon from "../../../assets/images/networks-icons/binance.png";
import ethIcon from "../../../assets/images/networks-icons/eth.png";
import flareIcon from "../../../assets/images/networks-icons/flare.png";
import telosIcon from "../../../assets/images/networks-icons/telos.png";
import baseIcon from "../../../assets/images/networks-icons/base.png";
import bitlayerIcon from "../../../assets/images/networks-icons/bitlayer.png";
import bitfinityIcon from "../../../assets/images/networks-icons/bitfinity.png";
import btc from "../../../assets/images/networks-icons/btc.png";
import pwrIcon from "../../../assets/images/networks-icons/pwr.png";
import chilizIcon from "../../../assets/images/networks-icons/chiliz.png";
import icpIcon from "../../../assets/images/networks-icons/icp.png";
import arbitrumIcon from "../../../assets/images/networks-icons/arbitrum.png";
import aeternityIcon from "../../../assets/images/networks-icons/aeternity.png";
import hemiIcon from "../../../assets/images/networks-icons/hemi.png";
import avalancheIcon from "../../../assets/images/networks-icons/avalanche.png";
import soneiumIcon from "../../../assets/images/networks-icons/soneium.png";

import {
  baseSepolia,
  base,
  mainnet,
  sepolia,
  polygon,
  bsc,
  bscTestnet,
  telos,
  telosTestnet,
  flare,
  flareTestnet,
  arbitrum,
  arbitrumSepolia,
  chiliz,
  spicy,
  avalanche,
  avalancheFuji,
} from "viem/chains";
import { CUSTOM_NETWORKS } from "./networks";

const networkType = process.env.REACT_APP_NETWORK_TYPE;

export const RPC_NODES = {
  ETHEREUM: {
    TESTNET: "https://eth-sepolia.api.onfinality.io/public",
    MAINNET: "https://mainnet.infura.io/v3/853c7c382bc74e69ac0540c9792ceec4",
  },
  BSC: {
    TESTNET: "https://data-seed-prebsc-1-s3.binance.org:8545",
    MAINNET: "https://bsc-dataseed.binance.org/",
  },
  POLYGON: {
    TESTNET:
      "https://rpc.ankr.com/polygon_amoy/b41db2216dd6dbbe201da8a228de7a50d33be808ac02c48a77b8ea34d8005399",
    // TESTNET:  "https://rpc-mumbai.maticvigil.com",
    MAINNET: "https://polygon-rpc.com",
  },
  TELOS: {
    TESTNET: "https://testnet.telos.net/evm",
    MAINNET: "https://mainnet.telos.net/evm",
  },
  FLARE: {
    TESTNET: "https://coston-api.flare.network/ext/bc/C/rpc",
    MAINNET: "https://flare-api.flare.network/ext/C/rpc",
  },
  BASE: {
    TESTNET:
      "https://rpc.ankr.com/base_sepolia/b41db2216dd6dbbe201da8a228de7a50d33be808ac02c48a77b8ea34d8005399",
    MAINNET:
      "https://rpc.ankr.com/base/b41db2216dd6dbbe201da8a228de7a50d33be808ac02c48a77b8ea34d8005399",
  },
  BITLAYER: {
    TESTNET: "https://testnet-rpc.bitlayer.org",
    MAINNET: "https://rpc.bitlayer.org",
  },
  BITCOIN: {
    TESTNET: "https://api.blockcypher.com/v1/btc/test3",
    MAINNET: "https://api.blockcypher.com/v1/btc/main",
  },
  PWR_ETH_PLUS: {
    TESTNET: "https://ethereumplus.pwrlabs.io/",
    MAINNET: "https://ethereumplus.pwrlabs.io/", // Not available
  },
  PWR_BTC_PLUS: {
    TESTNET: "https://bitcoinplus.pwrlabs.io/",
    MAINNET: "https://bitcoinplus.pwrlabs.io/", // Not available
  },
  CHILIZ: {
    TESTNET: "https://spicy-rpc.chiliz.com",
    MAINNET: "https://rpc.chiliz.com",
  },
  BITFINITY: {
    TESTNET: "https://testnet.bitfinity.network",
    MAINNET: "https://mainnet.bitfinity.network",
  },
  ICP: {
    TESTNET: "https://ic0.app",
    MAINNET: "https://ic0.app",
  },
  ARBITRUM: {
    TESTNET: "https://sepolia-rollup.arbitrum.io/rpc",
    MAINNET: "https://arbitrum.llamarpc.com",
  },
  AETERNITY: {
    TESTNET: "https://testnet.aeternity.io",
    MAINNET: "https://mainnet.aeternity.io",
  },
  HEMI: {
    TESTNET: "https://testnet.rpc.hemi.network/rpc",
    MAINNET: "https://mainnet.rpc.hemi.network/rpc",
  },
  AVALANCHE: {
    TESTNET: "https://rpc.ankr.com/avalanche_fuji",
    MAINNET: "https://avalanche.drpc.org",
  },
  SONEIUM:{
    TESTNET: "https://rpc.minato.soneium.org/",
    MAINNET: "https://rpc.minato.soneium.org/",
  }
};

export const CHAIN_ID_NETWORK = {
  8453: "BASE",
  84532: "BASE",
};

export const CHAIN_ID_NETWORK_INSTACNE = {
  84532: baseSepolia,
  8453: base,
  1: mainnet,
  11155111: sepolia,
  137: polygon,
  // 80002: amoy
};

export const NETWORK_TO_NETWORK_INSTACNE = {
  BASE: {
    TESTNET: baseSepolia,
    MAINNET: base,
  },
  ETHEREUM: {
    TESTNET: sepolia,
    MAINNET: mainnet,
  },
  POLYGON: {
    TESTNET: CUSTOM_NETWORKS.POLYGON_TESTNET,
    MAINNET: polygon,
  },
  BSC: {
    TESTNET: bscTestnet,
    MAINNET: bsc,
  },
  TELOS: {
    TESTNET: telosTestnet,
    MAINNET: telos,
  },
  FLARE: {
    TESTNET: flareTestnet,
    MAINNET: flare,
  },
  BITLAYER: {
    TESTNET: CUSTOM_NETWORKS.BITLAYER_TESTNET,
    MAINNET: CUSTOM_NETWORKS.BITLAYER_MAINNET,
  },
  PWR_ETH_PLUS: {
    TESTNET: CUSTOM_NETWORKS.PWR_ETH_PLUS_TESTNET,
    MAINNET: "",
  },
  PWR_BTC_PLUS: {
    TESTNET: CUSTOM_NETWORKS.PWR_BTC_PLUS_TESTNET,
    MAINNET: "",
  },
  CHILIZ: {
    TESTNET: spicy,
    MAINNET: chiliz,
  },
  BITFINITY: {
    TESTNET: CUSTOM_NETWORKS.BITFINITY_TESTNET,
    MAINNET: CUSTOM_NETWORKS.BITFINITY_MAINNET,
  },
  ARBITRUM: {
    TESTNET: arbitrumSepolia,
    MAINNET: arbitrum,
  },
  HEMI: {
    TESTNET: CUSTOM_NETWORKS.HEMI_SEPOLIA,
    MAINNET: CUSTOM_NETWORKS.HEMI,
  },
  AVALANCHE: {
    TESTNET: avalancheFuji,
    MAINNET: avalanche,
  },
  SONEIUM:{
    TESTNET: CUSTOM_NETWORKS.SONEIUM_TESTNET,
    MAINNET: CUSTOM_NETWORKS.SONEIUM_TESTNET,
  }
};

export const CHAIN_SCAN = {
  ETHEREUM: {
    TESTNET: "https://sepolia.etherscan.io/",
    MAINNET: "https://etherscan.io/",
  },
  BSC: {
    TESTNET: "https://testnet.bscscan.com/",
    MAINNET: "https://bscscan.com/",
  },
  POLYGON: {
    TESTNET: "https://amoy.polygonscan.com/",
    MAINNET: "https://polygonscan.com/",
  },
  TELOS: {
    TESTNET: "https://testnet.teloscan.io/",
    MAINNET: "https://www.teloscan.io/",
  },
  FLARE: {
    TESTNET: "https://coston-explorer.flare.network/",
    MAINNET: "https://flarescan.com/",
  },
  CASPER: {
    TESTNET: "https://testnet.cspr.live/",
    MAINNET: "https://cspr.live/",
  },
  BASE: {
    TESTNET: "https://sepolia.basescan.org/",
    MAINNET: "https://basescan.org/",
  },
  BITLAYER: {
    TESTNET: "https://testnet-scan.bitlayer.org/",
    MAINNET: "https://www.btrscan.com/",
  },
  BITCOIN: {
    TESTNET: "https://mempool.space/testnet/",
    MAINNET: "https://mempool.space/",
  },
  PWR_ETH_PLUS: {
    TESTNET: "https://ethplusexplorer.pwrlabs.io/",
    MAINNET: "https://ethplusexplorer.pwrlabs.io/", // Not available
  },
  PWR_BTC_PLUS: {
    TESTNET: "https://btcplusexplorer.pwrlabs.io/",
    MAINNET: "https://btcplusexplorer.pwrlabs.io/", // Not available
  },
  CHILIZ: {
    TESTNET: "https://testnet.chiliscan.com/",
    MAINNET: "https://chiliscan.com/",
  },
  BITFINITY: {
    TESTNET: "https://explorer.testnet.bitfinity.network/",
    MAINNET: "https://explorer.mainnet.bitfinity.network/",
  },
  ICP: {
    TESTNET: "https://dashboard.internetcomputer.org/",
    MAINNET: "https://dashboard.internetcomputer.org/",
  },
  ARBITRUM: {
    TESTNET: "https://sepolia.arbiscan.io/",
    MAINNET: "https://arbiscan.io/",
  },
  AETERNITY: {
    TESTNET: "https://testnet.aescan.io/",
    MAINNET: "https://aescan.io/",
  },
  HEMI: {
    MAINNET: "https://explorer.hemi.xyz/",
    TESTNET: "https://testnet.explorer.hemi.xyz/",
  },
  AVALANCHE: {
    MAINNET: "https://subnets.avax.network/c-chain/",
    TESTNET: "https://subnets-test.avax.network/c-chain/",
  },
  SONEIUM:{
    TESTNET: "https://soneium-minato.blockscout.com/",
    MAINNET: "https://soneium-minato.blockscout.com/",
  }
};

export const getEthersProvider = (network) => {
  if (!network) {
    throw new Error("Invalid network");
  }

  if (network === "NON_CUSTODIAL") {
    return new ethers.providers.Web3Provider(window.ethereum);
  }

  const options = RPC_NODES[network];
  const networkType = process.env.REACT_APP_NETWORK_TYPE;

  if (!options || !options[networkType]) {
    throw new Error("Invalid network");
  }

  return new ethers.providers.JsonRpcProvider(options[networkType]);
};

export const getSupportedOption = (network, loginOption) => {
  if (!network || !loginOption) {
    return null;
  }
  const options = SUPPORTED_OPTIONS[network];
  if (!options) {
    return null;
  }
  return options[loginOption];
};

export const SUPPORTED_OPTIONS = {
  ETHEREUM: {
    custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: true,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: true,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  BSC: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  POLYGON: {
    custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: networkType === "TESTNET" ? false : false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: true,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: true,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  TELOS: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  FLARE: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  CASPER: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: true,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: false,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: false,
    },
  },
  BASE: {
    custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: true,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: true,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  ARBITRUM: {
    custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: true,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: true,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: true,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  PWR_ETH_PLUS: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  PWR_BTC_PLUS: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  BITLAYER: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  CHILIZ: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  BITFINITY: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  HEMI: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  BITCOIN: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: false,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: false,
    },
  },
  ICP: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: false,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: false,
    },
  },
  AETERNITY: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: false,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: false,
      Claim_Tokens: false,
      Claim_NFTs: false,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: false,
    },
  },
  AVALANCHE: {
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  },
  SONEIUM:{
    custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: true,
      Create_Wallet: true,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
    non_custodial: {
      Tokens: true,
      NFTs: false,
      Collections: true,
      Claim_Tokens: true,
      Claim_NFTs: true,
      Schedules: false,
      Import_Wallet: false,
      Create_Wallet: false,
      Multi_Tokens: false,
      Multi_Receivers: true,
      NFT_Access: true,
    },
  }
};

export const NATIVE_TOKENS = {
  POLYGON: {
    TESTNET: {
      name: "Amoy",
      symbol: "MATIC",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: polygonIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "Polygon",
      symbol: "MATIC",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: polygonIcon,
      balance: 0,
      decimals: 18,
    },
  },
  ETHEREUM: {
    TESTNET: {
      name: "Etherium SEPOLIA",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: ethIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "Etherium",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: ethIcon,
      balance: 0,
      decimals: 18,
    },
  },
  BSC: {
    TESTNET: {
      name: "BSC TESTNET",
      symbol: "BNB",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: binanceIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "BSC",
      symbol: "BNB",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: binanceIcon,
      balance: 0,
      decimals: 18,
    },
  },
  TELOS: {
    TESTNET: {
      name: "Telos Testnet",
      symbol: "TLS",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: telosIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "TELOS",
      symbol: "TLS",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: telosIcon,
      balance: 0,
      decimals: 18,
    },
  },
  FLARE: {
    TESTNET: {
      name: "FLARE TESTNET",
      symbol: "FLR",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: flareIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "FLARE",
      symbol: "FLR",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: flareIcon,
      balance: 0,
      decimals: 18,
    },
  },
  BASE: {
    TESTNET: {
      name: "Base Sepolia",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: baseIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "Base",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: baseIcon,
      balance: 0,
      decimals: 18,
    },
  },
  ARBITRUM: {
    TESTNET: {
      name: "Arbitrum Sepolia",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: arbitrumIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "Arbitrum",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: arbitrumIcon,
      balance: 0,
      decimals: 18,
    },
  },
  PWR_ETH_PLUS: {
    TESTNET: {
      name: "PWR ETH PLUS TESTNET",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: pwrIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "PWR ETH PLUS",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: pwrIcon,
      balance: 0,
      decimals: 18,
    },
  },
  PWR_BTC_PLUS: {
    TESTNET: {
      name: "PWR BTC PLUS TESTNET",
      symbol: "BTC",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: pwrIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "PWR BTC PLUS",
      symbol: "BTC",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: pwrIcon,
      balance: 0,
      decimals: 18,
    },
  },
  BITLAYER: {
    TESTNET: {
      name: "Bitlayer",
      symbol: "BTC",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: bitlayerIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "Bitlayer",
      symbol: "BTC",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: bitlayerIcon,
      balance: 0,
      decimals: 18,
    },
  },
  CHILIZ: {
    TESTNET: {
      name: "Chiliz Spicy",
      symbol: "CHZ",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: chilizIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "Chiliz",
      symbol: "CHZ",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: chilizIcon,
      balance: 0,
      decimals: 18,
    },
  },
  BITFINITY: {
    TESTNET: {
      name: "Bitfinity Testnet",
      symbol: "BFT",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: bitfinityIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "Bitfinity",
      symbol: "BFT",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: bitfinityIcon,
      balance: 0,
      decimals: 18,
    },
  },
  HEMI: {
    TESTNET: {
      name: "Hemi Sepolia",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: hemiIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "Hemi",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: hemiIcon,
      balance: 0,
      decimals: 18,
    },
  },
  BITCOIN: {
    TESTNET: {
      name: "Bitcoin Testnet",
      symbol: "BTC",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: btc,
      balance: 0,
      decimals: 8,
    },
    MAINNET: {
      name: "Bitcoin",
      symbol: "BTC",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: btc,
      balance: 0,
      decimals: 8,
    },
  },
  ICP: {
    TESTNET: {
      name: "ICP",
      symbol: "ICP",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: icpIcon,
      balance: 0,
      decimals: 8,
    },
    MAINNET: {
      name: "ICP",
      symbol: "ICP",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: icpIcon,
      balance: 0,
      decimals: 8,
    },
  },
  ICP: {
    TESTNET: {
      name: "Aeternity Testnet",
      symbol: "AE",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: aeternityIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "Aeternity",
      symbol: "AE",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: aeternityIcon,
      balance: 0,
      decimals: 8,
    },
  },
  AVALANCHE: {
    TESTNET: {
      name: "Avalanche Fuji",
      symbol: "AVAX",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: avalancheIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "Avalanche",
      symbol: "AVAX",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: avalancheIcon,
      balance: 0,
      decimals: 18,
    },
  },
  SONEIUM: {
    TESTNET: {
      name: "Soneium Minato",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: soneiumIcon,
      balance: 0,
      decimals: 18,
    },
    MAINNET: {
      name: "Soneium",
      symbol: "ETH",
      contractAddress: "0x0000000000000000000000000000000000000000",
      logo: soneiumIcon,
      balance: 0,
      decimals: 18,
    },
  }
};
