import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import '../../../../assets/styles/css/_drag-drop-list.scss';
import { useTranslation } from 'react-i18next';

const LayersNamesController = ({ setLayerProps, layersIndexChanged }) => {
  const [items, setItems] = useState([{ name: 'Background', id: 0, index: 0 }]);
  const { t } = useTranslation();
  const [draggingItem, setDraggingItem] = useState(null);
  const [newItemName, setNewItemName] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [isNameExist, setIsNameExist] = useState(false);
  useEffect(() => {
    setLayerProps && setLayerProps(items[0]);
    setSelectedItem(items[0]);
  }, [setLayerProps]);

  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', '');
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e, targetItem) => {
    const currentIndex = items.indexOf(draggingItem);
    const targetIndex = items.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      const newItems = [...items];
      newItems.splice(currentIndex, 1);
      newItems.splice(targetIndex, 0, draggingItem);
      setItems(newItems);
      layersIndexChanged(newItems.map((item, index) => ({ ...item, index })));
    }
  };

  const handleNameChange = (e) => {
    setNewItemName(e.target.value);
  };

  const addNewItem = () => {
    const isNameExist = items?.findIndex(({ name }) => name === newItemName);
    if (isNameExist != -1) {
      setIsNameExist(true);
      return;
    }
    setIsNameExist(false);
    const newItemId =
      items.length == 0 ? 1 : Math.max(...items.map((item) => item.id)) + 1;
    const newItem = {
      id: newItemId,
      name: newItemName,
      index: items.length,
    };

    setItems([...items, newItem]);
    setNewItemName('');
  };

  const handleItemClick = (item) => {
    const selectedIndex = items.findIndex((i) => i.name === item.name);
    setSelectedItem(item);
    // Perform the select action here
    setLayerProps({ ...item, index: selectedIndex });
  };

  return (
    <div className="sortable-list col-4">
      {items.map((item, index) => (
        <div
          key={item.id}
          className={`item ${item === draggingItem ? 'dragging' : ''} ${item === selectedItem ? 'selected' : ''
            }`}
          draggable="true"
          onDragStart={(e) => handleDragStart(e, item)}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, item)}
          onClick={() => handleItemClick(item)}
        >
          <div className="details">
            <FontAwesomeIcon
              icon={faGripVertical}
              className={`${item === selectedItem ? 'text-black' : 'text-white'
                }`}
            />
            <span className="ms-2">{item.name}</span>
          </div>
        </div>
      ))}
      <div className="new-item">
        <input
          type="text"
          placeholder={t("general.name")}
          value={newItemName}
          onChange={handleNameChange}
          className="input-field mt-4 mb-2 text-white"
        />
        {isNameExist && (
          <span className="text-danger">{t("dashboard.tabs.createCollection.nameIsAlreadyExist")}</span>
        )}
        <button
          onClick={addNewItem}
          disabled={newItemName === ''}
          className="fugu--outline-btn w-100 mt-2"
        >
          <span>{t("dashboard.tabs.createCollection.addNewItem")}</span>
        </button>
      </div>
    </div>
  );
};

export default LayersNamesController;
