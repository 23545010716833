import { Chain } from "viem";

export const CUSTOM_NETWORKS: Record<string, Chain> = {
  POLYGON_TESTNET: {
    id: 80002,
    name: "Amoy",
    network: "Polygon",
    nativeCurrency: {
      decimals: 18,
      name: "MATIC",
      symbol: "MATIC",
    },
    rpcUrls: {
      default: {
        http: ["https://polygon-amoy.drpc.org"],
        webSocket: ["wss://polygon-amoy-bor-rpc.publicnode.com"],
      },
      public: {
        http: ["https://polygon-amoy.drpc.org"],
      },
    },
    blockExplorers: {
      default: {
        name: "AmoyPolygonscan",
        url: "https://amoy.polygonscan.com/",
      },
    },
    testnet: true,
  },
  BITLAYER_MAINNET: {
    id: 200901,
    name: "Bitlayer Mainnet",
    network: "Bitlayer",
    nativeCurrency: {
      decimals: 18,
      name: "BTC",
      symbol: "BTC",
    },
    rpcUrls: {
      default: {
        http: ["https://rpc.bitlayer.org"],
        webSocket: ["wss://ws.bitlayer.org"],
      },
      public: {
        http: ["https://rpc.bitlayer.org"],
      },
    },
    blockExplorers: {
      default: {
        name: "Bitlayer Explorer",
        url: "https://www.btrscan.com/",
      },
    },
    testnet: false,
  },
  BITLAYER_TESTNET: {
    id: 200810,
    name: "Bitlayer Testnet",
    network: "Bitlayer",
    nativeCurrency: {
      decimals: 18,
      name: "BTC",
      symbol: "BTC",
    },
    rpcUrls: {
      default: {
        http: ["https://testnet-rpc.bitlayer.org"],
        webSocket: ["wss://testnet-ws.bitlayer.org"],
      },
      public: {
        http: ["https://testnet-rpc.bitlayer.org"],
      },
    },
    blockExplorers: {
      default: {
        name: "Bitlayer Testnet Explorer",
        url: "https://testnet-scan.bitlayer.org/",
      },
    },
    testnet: true,
  },
  BITFINITY_MAINNET: {
    id: 355110,
    name: "Bitfinity Network Mainnet",
    network: "Bitfinity",
    nativeCurrency: {
      decimals: 18,
      name: "BFT",
      symbol: "BFT",
    },
    rpcUrls: {
      default: {
        http: ["https://mainnet.bitfinity.network"],
      },
      public: {
        http: ["https://mainnet.bitfinity.network"],
      },
    },
    blockExplorers: {
      default: {
        name: "Bitfinity Explorer",
        url: "https://explorer.mainnet.bitfinity.network/",
      },
    },
    testnet: false,
  },
  BITFINITY_TESTNET: {
    id: 355113,
    name: "Bitfinity Network Testnet",
    network: "Bitfinity",
    nativeCurrency: {
      decimals: 18,
      name: "BFT",
      symbol: "BFT",
    },
    rpcUrls: {
      default: {
        http: ["https://testnet.bitfinity.network"],
      },
      public: {
        http: ["https://testnet.bitfinity.network"],
      },
    },
    blockExplorers: {
      default: {
        name: "Bitfinity Testnet Explorer",
        url: "https://explorer.testnet.bitfinity.network/",
      },
    },
    testnet: true,
  },
  PWR_ETH_PLUS_TESTNET: {
    id: 10023,
    name: "PWR ETH Plus Testnet",
    network: "PWR ETH Plus",
    nativeCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH",
    },
    rpcUrls: {
      default: {
        http: ["https://ethereumplus.pwrlabs.io/"],
      },
      public: {
        http: ["https://ethereumplus.pwrlabs.io/"],
      },
    },
    blockExplorers: {
      default: {
        name: "PWR Eth plus Testnet Explorer",
        url: "https://ethplusexplorer.pwrlabs.io/",
      },
    },
    testnet: true,
  },
  PWR_BTC_PLUS_TESTNET: {
    id: 21000001,
    name: "PWR BTC Plus Testnet",
    network: "PWR BTC Plus",
    nativeCurrency: {
      decimals: 18,
      name: "BTC",
      symbol: "BTC",
    },
    rpcUrls: {
      default: {
        http: ["https://bitcoinplus.pwrlabs.io/"],
      },
      public: {
        http: ["https://bitcoinplus.pwrlabs.io/"],
      },
    },
    blockExplorers: {
      default: {
        name: "PWR BTC plus Testnet Explorer",
        url: "https://btcplusexplorer.pwrlabs.io/",
      },
    },
    testnet: true,
  },
  HEMI_SEPOLIA: {
    id: 743111,
    name: "Hemi Sepolia",
    network: "Hemi",
    nativeCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH",
    },
    rpcUrls: {
      default: {
        http: ["https://testnet.rpc.hemi.network/rpc"],
      },
      public: {
        http: ["https://testnet.rpc.hemi.network/rpc"],
      },
    },
    blockExplorers: {
      default: {
        name: "Hemi Sepolia Explorer",
        url: "https://testnet.explorer.hemi.xyz/",
      },
    },
    testnet: true,
  },
  HEMI: {
    id: 43111,
    name: "Hemi",
    network: "Hemi",
    nativeCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH",
    },
    rpcUrls: {
      default: {
        http: ["https://mainnet.rpc.hemi.network/rpc"],
      },
      public: {
        http: ["https://mainnet.rpc.hemi.network/rpc"],
      },
    },
    blockExplorers: {
      default: {
        name: "Hemi Explorer",
        url: "https://explorer.hemi.xyz/",
      },
    },
    testnet: false,
  },
  SONEIUM_TESTNET:{
    id: 1946,
    name: "SONEIUM Minato",
    network: "SONEIUM",
    nativeCurrency: {
      decimals: 18,
      name: "ETH",
      symbol: "ETH",
    },
    rpcUrls: {
      default: {
        http: ["https://rpc.minato.soneium.org/"],
      },
      public: {
        http: ["https://rpc.minato.soneium.org/"],
      },
    },
    blockExplorers: {
      default: {
        name: "SONEIUM Minato Explorer",
        url: "https://soneium-minato.blockscout.com/",
      },
    },
    testnet: false,
  }
};
