import { BlockchainNetwork } from "./BlockchainNetwork";
import { CHAIN_SCAN, RPC_NODES } from "./Providers";
import { tokenTransfer } from "../ERC20-utils/proxy-payment/ProxyPayment_Utils";
import { HttpAgent, Actor } from "@dfinity/agent";
import { idlFactory as ledgerIDL } from "./ledger_idl";
import { formatUnits } from "viem";

export class ICPNetwork extends BlockchainNetwork {
  private network: any;
  private rpc: any;

  constructor(chain: string) {
    super(chain);
    type NetworkType = "MAINNET" | "TESTNET";
    this.rpc =
      RPC_NODES.ICP[process.env.REACT_APP_NETWORK_TYPE as NetworkType];
  }

  getAddress(userWallets: any) {
    return userWallets.ICP.wallets[0].accountId;
  }

  getWallets(userWallets: any, shortenKeyWidth: number) {
    if (!userWallets) return [];
    return userWallets.map((elem: any) => {
      return {
        address: elem.accountId,
        hasPassword: elem.hasPassword,
        walletIndex: elem.index,
        publicKey: elem.principalId,
        label:
          elem.accountId?.slice(0, shortenKeyWidth) +
          "..." +
          elem.accountId?.slice(-4),
      };
    });
  }

  async getBalance(accountId: string, network: any): Promise<any> {
    if (!network) return 0;
    try {
      const agent = new HttpAgent({
        host: this.rpc,
      });

      const ledgerActor = Actor.createActor(ledgerIDL, {
        agent,
        canisterId: "ryjl3-tyaaa-aaaaa-aaaba-cai",
      });

      const balance: any = await ledgerActor.account_balance_dfx({
        account: accountId,
      });

      console.log(
        `Account ID ${accountId} has a balance of ${balance.e8s} e8s`
      );

      const formatedBalance = formatUnits(balance.e8s, 8);
      return formatedBalance;
    } catch (error) {
      return 0;
    }
  }

  async getDeposit(email: string, tokenAddress: string): Promise<any> {
    return 0;
  }

  async getAllDepositsBalance(email: string): Promise<any> {
    return [];
  }

  isValidAddress(address: string): any {
    return true;
  }

  validateWalletAddress(addresses: any): any {
    return {
      isValid: true,
      receiver: addresses
    }
  }

  async depositToken(
    email: string,
    walletAddress: string,
    walletIndex: number,
    receiversIds: [string],
    amount: string,
    network: string,
    password: string
  ): Promise<any> {
    return tokenTransfer(
      email,
      walletIndex,
      password,
      1,
      receiversIds[0],
      (Number(amount) * 1e8).toString(),
      "ICP"
    );
  }

  async transferToken(
    email: string,
    walletAddress: string,
    walletIndex: number,
    receiversIds: [string],
    amount: string,
    network: string,
    password: string
  ): Promise<any> {
    return tokenTransfer(
      email,
      walletIndex,
      password,
      0,
      receiversIds[0],
      (Number(amount) * 1e8).toString(),
      "ICP"
    );
  }

  getChainScanUrlAddress() {
    const chain = CHAIN_SCAN[this.chainName as keyof typeof CHAIN_SCAN];
    return (
      chain[process.env.REACT_APP_NETWORK_TYPE as keyof typeof chain] +
      "account/"
    );
  }

  getChainScanUrlTx() {
    const chain = CHAIN_SCAN[this.chainName as keyof typeof CHAIN_SCAN];
    return (
      chain[process.env.REACT_APP_NETWORK_TYPE as keyof typeof chain] +
      "transaction/"
    );
  }
}
