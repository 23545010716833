import React, { useEffect, useState } from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../web3/context/AuthContext";
import WalletTooltip from "../../social-wallet/tooltip";
import { ChainsObjectsArr } from "../../../chainsStaticObject/chainsStaticData";

export default function DropdownItem({ navItemText, submenu, selection, id }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {
    setNetwork,
    setPaymentMethod,
    loginProvider,
    entityInfo,
    setIsLoggedIn,
    setHasPassword,
    email,
    network,
    setSupportedOptions,
    loginOption,
    manualRefreshBalance
  } = useAuth();

  const handleClick = (e) => {
    setOpen(!open);
  };


  const switchNetwork = (selection) => {
    setSupportedOptions(selection.supportedOptions[loginOption])
    const ChainName = selection?.networkType;
    if (!entityInfo.userWalletsData[ChainName]) {
      setNetwork(selection);
      return
    } //validate wallets exist in selected chain
    setHasPassword(entityInfo.userWalletsData[ChainName]?.wallets[0].hasPassword);
    const wallet = selection.network.getAddress(entityInfo.userWalletsData)
    setIsLoggedIn(
      true,
      wallet,
      "custodial",
      loginProvider,
      0,
      entityInfo.userWalletsData,
    );
    setNetwork(selection);
    manualRefreshBalance(wallet, selection)
    navigate("/transferDashboard?selectedTab=Tokens")
  }
  const handleDropDownSelection = (selection) => {
    if (id === "selectedChain") {
      switchNetwork(selection)
    } else if (id === "paymentMethod") {
      setPaymentMethod(selection);
    }
  };



  React.useEffect(() => {
    if (!network) {
      setNetwork(ChainsObjectsArr[0])
      setSupportedOptions(ChainsObjectsArr[0].supportedOptions[loginOption])
    } else {
      setSupportedOptions(network.supportedOptions[loginOption])
    }
  }, [network]);

  return (
    <li
      className={`sub-menu--item ${submenu ? "nav-item-has-children" : ""
        } d-flex  align-items-center justify-content-center
      
      ${id == "selectedChain" ? "mb-1 ml-2" : ""}
      `}
      onClick={handleClick}
    >
      {id == "depositsTokens" && (
        <div className="d-flex row">
          <div className="text-warning-500 col">{selection.balance}</div>
          <div className="col">{selection.symbol}</div>
          <button className="col">Claim</button>
        </div>
      )}
      {id == "selectedChain" && (
        <div onClick={() => handleDropDownSelection(selection)} className="justify-content-between d-flex w-100 mx-2" >
          <span>{selection.title}</span>
          <img src={selection.logo} className="fugu-network-icons mx-2 " />
        </div>
      )}
      {!submenu && id != "depositsTokens" ? (
        <Link
          legacyBehavior
          onClick={() =>
            !selection.disabled && handleDropDownSelection(selection)
          }
        >
          <WalletTooltip title={selection.toolTipText}>
            {id == "paymentMethod" ? (
              <div>
                <img src={selection.icon} className="fugu-network-icons mx-2" />
              </div>
            ) : (
              <>
                <div className="drop-trigger d-flex">
                  {navItemText}{" "}
                  {submenu && <FontAwesomeIcon icon={faAngleDown} />}
                </div>
              </>
            )}
          </WalletTooltip>
        </Link>
      ) : (
        <div className="drop-trigger">
          {/* {submenu && <FontAwesomeIcon icon={faAngleDown} />} */}
        </div>
      )}

      {/* {submenu && (
        <SecondLevelDropdownMenu submenu={submenu} parent={selection} isClicked={open} id={id} />
      )} */}
    </li>
  );
}
