import NFTS_ACCESS from "./NFTsAccess.json";

export const NFTS_ACCESS_CONTRACT_ADDRESS = {
  POLYGON: {
    TESTNET: "0x768377B6645D2024B62d4f873803F65A509Befc4",
    MAINNET: "",
  },
  ETHEREUM: {
    TESTNET: "0x8B3CE5bbEc58Ef38179106C46a915E0d5EEde97b",
    MAINNET: "",
  },
  BSC: {
    TESTNET: "0x7BDa2F05793313BAFE39C4df68A1Db541C5E38fD",
    MAINNET: "",
  },
  TELOS: {
    TESTNET: "0x882A12aa0e3082Ba5868Ffd7B7C46C0c542D4BCa",
    MAINNET: "",
  },
  FLARE: {
    TESTNET: "0x768377B6645D2024B62d4f873803F65A509Befc4",
    MAINNET: "",
  },
  BASE: {
    TESTNET: "0x9B4982E3f690e789D071c6fa76f131Bd6d86c960",
    MAINNET: "",
  },
  PWR_ETH_PLUS: {
    TESTNET: "0x14e63e8a56D5F310F9e4DAd430A1434678500df1",
    MAINNET: "",
  },
  PWR_BTC_PLUS: {
    TESTNET: "0xC1B796D4Ffe88bfBa4F118B77a769adc670B167A",
    MAINNET: "",
  },
  BITLAYER: {
    TESTNET: "0xEd0Be114c211021412939eBE69145B6582F92dF1",
    MAINNET: "",
  },
  CHILIZ: {
    TESTNET: "0x28D21B2efCA409ad0a3d3B5BC24D0A6Cc9a8Ab7e",
    MAINNET: "",
  },
  BITFINITY: {
    TESTNET: "0xEA54426bbB4bd8C7dD55e186d197A883C46597f2",
    MAINNET: "",
  },
  ARBITRUM: {
    TESTNET: "0x8d53c088f7dFf1e7377267fEecC75cAed3dC2901",
    MAINNET: "",
  },
  AVALANCHE: {
    TESTNET: "0xF704F975a729D98aDF6c29cCAa56B8ACecA4663E",
    MAINNET: "",
  },
  SONEIUM:{
    TESTNET: "0x13A840015EB059f0F81DE9aEb05c2BF9F7EA76C5",
    MAINNET: "",
  }
};

export const TOKEN_TYPE = {
  NATIVE_TOKEN: "0",
  ERC20: "1",
  ERC721: "2",
  ERC1155: "3",
};

export const NFTS_ACCESS_ABI = NFTS_ACCESS.abi;

export const ERC20_ABI = [
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

export const ERC721_ABI = [
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "balance", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
];

export const ERC1155_ABI = [
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
