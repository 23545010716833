import { useState, useEffect } from "react";
import { handleCSVUpload } from "../../../helper/helperFunc";
import { toast } from "react-hot-toast";
import Preloader from "../../../components/common/preloader/preloader";
import { useAuth } from "../../../components/web3/context/AuthContext";
import {
  airdrop_custodial,
  airdrop_nonCustodial,
  depositNfts_custodial,
  depositNfts_nonCustodial,
} from "../../../components/web3/nfts-utils/nfts-factory/HorusFactory_Utils";
import { openPasswordModel } from "../../../components/social-wallet/PasswordModal";
import { useTranslation } from "react-i18next";
function UploadMintCsv({ receiversArr, selectedCollection }) {
  let initialReceivers = {
    Email: [],
    Phone: [],
    Wallet: [],
  };
  const { t } = useTranslation()
  const [receiversAddress, setReceiversAddress] = useState(initialReceivers);
  const [selectedCollectionFromParent, setSelectedCollection] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    isLoggedIn,
    loginOption,
    hasPassword,
    email,
    network,
    balance,
    entityInfo,
  } = useAuth();
  const detectCsvFileData = async (event, type) => {
    const data = await handleCSVUpload(event, type, network.chain);
    const updatedReceiversAddress = {
      ...receiversAddress,
      [type]: [...receiversAddress[type], ...data],
    };
    setReceiversAddress(updatedReceiversAddress);
    receiversArr(updatedReceiversAddress);
  };
  useEffect(() => {
    setSelectedCollection(selectedCollection);
  }, [selectedCollection]);
  const validateNftData = () => {
    return (
      receiversAddress.Email.length > 0 ||
      receiversAddress.Phone.length > 0 ||
      receiversAddress.Wallet.length > 0
    );
  };
  const mint = async () => {
    if (balance < 0.001)
      return toast.error("You don't have enough balance to mint");
    console.log(receiversAddress);
    const valid = validateNftData();
    if (isLoggedIn) {
      if (!valid) {
        toast.error("Upload receivers addresses to be able to mint.");
      } else if (
        selectedCollectionFromParent.maxNfts ==
        selectedCollectionFromParent.currNFts
      ) {
        toast.error(
          "You minted the maximum number of NFTs under this collection."
        );
      } else {
        setLoading(true);
        const recepientsSocial = extractSocialReciepents(receiversAddress);
        const recepientsWallets = extractWalletAddresses(receiversAddress);
        if (loginOption === "custodial") {
          if (hasPassword) {
            if (recepientsSocial.length > 0) {
              const handlePassSecureTransfer = async (password) => {
                if (password === "") return toast.error("Password is required");
                if (password == null) return;
                await depositNfts_custodial(
                  email,
                  entityInfo.indexWallet,
                  network,
                  selectedCollectionFromParent.contractAddress,
                  recepientsSocial,
                  password
                );
              };
              openPasswordModel(handlePassSecureTransfer);
            }
            if (recepientsWallets.length > 0) {
              const handlePassSecureTransfer = async (password) => {
                if (password === "") return toast.error("Password is required");
                if (password == null) return;
                await airdrop_custodial(
                  email,
                  entityInfo.indexWallet,
                  network,
                  selectedCollectionFromParent.contractAddress,
                  recepientsWallets,
                  password
                );
              };
              openPasswordModel(handlePassSecureTransfer);
            }
          } else {
            if (recepientsSocial.length > 0) {
              await depositNfts_custodial(
                email,
                entityInfo.indexWallet,
                network,
                selectedCollectionFromParent.contractAddress,
                recepientsSocial
              );
            }

            if (recepientsWallets.length > 0) {
              await airdrop_custodial(
                email,
                entityInfo.indexWallet,
                network,
                selectedCollectionFromParent.contractAddress,
                recepientsWallets
              );
            }
          }
        } else {
          if (recepientsSocial.length > 0) {
            await depositNfts_nonCustodial(
              network,
              selectedCollectionFromParent.contractAddress,
              recepientsSocial
            );
          }
          if (recepientsWallets.length > 0) {
            await airdrop_nonCustodial(
              selectedCollectionFromParent.contractAddress,
              recepientsWallets,
              network
            );
          }
        }
        setLoading(false);
      }
    }
  };

  const extractSocialReciepents = (socials) => {
    let receipents = [];
    for (let i = 0; i < socials.Email.length; i++) {
      receipents.push(socials.Email[i].mail);
    }
    for (let i = 0; i < socials.Phone.length; i++) {
      receipents.push(socials.Phone[i].phone);
    }
    return receipents;
  };

  const extractWalletAddresses = (wallets) => {
    let receipents = [];
    for (let i = 0; i < wallets.Wallet.length; i++) {
      receipents.push(wallets.Wallet[i].wallet);
    }
    return receipents;
  };

  return (
    <>
      <Preloader show={loading} />
      {selectedCollectionFromParent && (
        <>
          <div
            className="fugu--blog-wrap fugu--blog-wrap2 wow fadeInUpX"
            data-wow-delay="0s"
          >
            <div className="fugu--blog-thumb">
              <img src={selectedCollectionFromParent.image} alt="" />
            </div>
            <div className="fugu--blog-content">
              <div className="fugu--blog-date">
                <ul>
                  <li>
                    <p className="text-warning-600">{`${t("dashboard.tabs.myCollections.maxNfts")} ${selectedCollectionFromParent.maxNfts}`}</p>
                  </li>
                  <li>
                    <p className="text-warning-600">{`${t("dashboard.tabs.myCollections.mintedNfts")} ${selectedCollectionFromParent.currNFts}`}</p>
                  </li>
                </ul>
              </div>
              <div className="fugu--blog-title text-uppercase">
                <div className="d-flex align-items-center">
                  <h3>
                    {selectedCollectionFromParent.name} {"_"}{" "}
                    {selectedCollectionFromParent.symbol}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <button className="fugu--outline-btn" onClick={() => mint()}>
              <span>Mint NFTs</span>
            </button>
          </div>
        </>
      )}
      <div className="fugu--blog-sidebar-item">
        <label htmlFor="address-input">
          <h4>Click to upload CSV with emails</h4>
          <p className="text-gray-700">
            Please make your file formate with email header
          </p>
          <input
            type="file"
            accept=".csv"
            onChange={(event) => detectCsvFileData(event, "Email")}
            style={{ display: "none" }}
            id="address-input"
          />
        </label>

        <div className="fugu--tags">
          <ul>
            {receiversAddress.Email.length > 0 &&
              receiversAddress.Email.map((e, index) => (
                <li key={index}>
                  <p className="text-warning-600">{e.mail}</p>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div className="fugu--blog-sidebar-item">
        <label htmlFor="phone-input">
          <h4>Click to upload CSV with phones</h4>
          <p className="text-gray-700">
            Please make your file formate with phone header
          </p>
          <input
            type="file"
            accept=".csv"
            onChange={(event) => detectCsvFileData(event, "Phone")}
            style={{ display: "none" }}
            id="phone-input"
          />
        </label>

        <div className="fugu--tags">
          <ul>
            {receiversAddress.Phone.length > 0 &&
              receiversAddress.Phone.map((e, index) => (
                <li key={index}>
                  <p className="text-warning-600">{e.phone}</p>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default UploadMintCsv;
