import Papa from "papaparse";
import phone from "phone";
import { ethers } from "ethers";
import {
  CLPublicKey,
} from 'casper-js-sdk';
import { CHAIN_SCAN } from "../components/web3/utils/Providers";
import { ChainsObjectsArr } from "../chainsStaticObject/chainsStaticData";

export const isValidWalletAddress = (address) => {
  try {
    ethers.utils.getAddress(address);
    return true;
  } catch (error) {
    return false;
  }
};

export function validateWallet(walletsArr) {
  const wallets = walletsArr.split(",").map((wallet) => wallet.trim());
  const validatedPhones = wallets.map((w) => {
    return {
      receiver: w,
      isValid: isValidWalletAddress(w),
    };
  });
  return validatedPhones;
}

export function validateCasperWallet(walletsArr) {
  const wallets = walletsArr.split(",").map((wallet) => wallet.trim());
  const validatedPhones = wallets.map((w) => {
    return {
      receiver: w,
      isValid: isValidCasperWallet(w),
    };
  });
  return validatedPhones;
}

export const handleCSVUpload = (event, selectedPayment, network) => {
  return new Promise((resolve, reject) => {
    if (!event.target.files || event.target.files.length === 0) {
      console.log("File selection canceled");
      reject(new Error("No file selected"));
      return;
    }

    // First, parsing the CSV with the header option
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // If the first row is a valid email, phone number, or wallet address,
        // then parse again with the header option set to false
        const firstRowValues = Object.values(results.data[0]);
        if (
          firstRowValues.some(
            (value) =>
              isValidEmail(value) ||
              phone(value).isValid ||
              isValidTwitterAccount(value) ||
              isValidWalletAddress(value)
          )
        ) {
          Papa.parse(event.target.files[0], {
            header: false,
            skipEmptyLines: true,
            complete: function (resultsWithoutHeader) {
              console.log(resultsWithoutHeader.data);
              let data = extractReceiversFromCSV(
                resultsWithoutHeader.data,
                selectedPayment,
                network
              );
              resolve(data);
            },
            error: function (error) {
              reject(error);
            },
          });
        } else {
          console.log(results.data);
          let data = extractReceiversFromCSV(results.data, selectedPayment, network);
          resolve(data);
        }
      },
      error: function (error) {
        reject(error);
      },
    });
  });
};

export const extractSchedulesList = (event, selectedPayment) => {
  return new Promise((resolve, reject) => {
    if (!event.target.files || event.target.files.length === 0) {
      console.log("File selection canceled");
      reject(new Error("No file selected"));
      return;
    }

    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        console.log(results.data);
        let data = extractScheduledListCSV(results.data);
        resolve(data);
      },
      error: function (error) {
        reject(error);
      },
    });
  }).then((data) => {
    return data;
  });
};

// Helper function to validate email addresses
export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

// Helper function to validate email addresses
export function isValidTwitterAccount(account) {
  const TwitterRegex = /^(?=.{4,15}$)(?!^\d+$)[A-Za-z0-9_]+$/;
  return TwitterRegex.test(account);
}

function extractReceiversFromCSV(csvData, selectedPayment, network) {
  const receivers = new Set();

  const processValue = (value) => {
    let validInput;
    switch (selectedPayment) {
      case "Email":
        if (isValidEmail(value)) {
          validInput = { isValid: true, mail: value.trim() };
        }
        break;
      case "Phone":
        if (phone(value).isValid) {
          validInput = { isValid: true, phone: value.trim() };
        }
        break;
      case "Twitter":
        if (isValidTwitterAccount(value)) {
          validInput = { isValid: true, twitter: value.trim() };
        }
        break;
      case "Wallet":
        if (network !== "CASPER" && isValidWalletAddress(value)) {
          validInput = { isValid: true, wallet: value.trim() };
        } else if (network === "CASPER" && isValidCasperWallet(value)) {
          validInput = { isValid: true, wallet: value.trim() };
        }
        break;
    }

    if (validInput) {
      receivers.add(validInput);
    }
  };

  // Check if the csvData is an array of objects
  if (
    Array.isArray(csvData) &&
    csvData.length > 0 &&
    typeof csvData[0] === "object"
  ) {
    csvData.forEach((obj) => {
      Object.values(obj).forEach(processValue);
    });
  } else if (
    Array.isArray(csvData) &&
    csvData.length > 0 &&
    typeof csvData[0] === "string"
  ) {
    // Check if the csvData is an array of key-value pairs
    csvData.forEach((pair) => {
      Object.values(pair).forEach(processValue);
    });
  } else if (typeof csvData === "string") {
    // Treat the csvData as a single string
    const lines = csvData.split("\n");
    lines.forEach(processValue);
  }

  return Array.from(receivers);
}

function extractScheduledListCSV(csvData) {
  const row = [];
  if (
    Array.isArray(csvData) &&
    csvData.length > 0 &&
    typeof csvData[0] === "object"
  ) {
    csvData.forEach((obj) => {
      row.push(obj);
    });
  }
  return row;
}


export function convertDateToUnix(date) {
  return Math.floor(date.getTime() / 1000)
}

export const convertToSeconds = (days, minutes) => {
  const secondsPerMinute = 60;
  const secondsPerDay = 24 * 60 * 60;
  const totalSeconds = days * secondsPerDay + minutes * secondsPerMinute;
  return totalSeconds
};

export function convertSecondsToDaysAndMinutes(seconds) {
  const totalMinutes = Math.floor(seconds / 60);
  const days = Math.floor(totalMinutes / (60 * 24));
  const remainingMinutes = totalMinutes % (60 * 24);
  return {
    days: days,
    minutes: remainingMinutes
  };
}

export function isValidCasperWallet(publicKey) {
  try {
    return CLPublicKey.fromHex(publicKey) instanceof CLPublicKey;
  } catch (error) {
    return false;
  }
}

export const displayMessageWithLink = (hash, toastMsg, network) => {
  const chainScanUrl = ChainsObjectsArr.find(chain => chain.chain === network)?.network.getChainScanUrlTx();
  return (
    <div>
      {toastMsg} Success! Transaction Hash:{" "}
      <a
        href={`${chainScanUrl}${hash}`}
        target="_blank"
        rel="noreferrer"
        style={{ color: "#fff" }}
      >
        Click Here
      </a>
    </div>
  );
};