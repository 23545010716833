import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  TwitterAuthProvider,
  isSignInWithEmailLink,
  signInWithEmailLink,
  onAuthStateChanged,
} from "firebase/auth";
import { getAuthConfig } from "./firebase";
import WalletTooltip from "./tooltip";
import google_icon from "./icon/google-icon.png";
import facebook_icon from "./icon/facebook-icon.png";
import twitter_icon from "./icon/twitter-icon.png";
import email_icon from "./icon/email-icon.png";
import phone_icon from "./icon/phone-icon.png";
import toast from "react-hot-toast";
import { openNewPasswordModel } from "./NewPasswordModal";
import axiosToken, { setAxiosToken } from "./AxiosToken";
import { useAuth } from "../web3/context/AuthContext";
import { openPhoneModal } from "./PhoneModal";
import { openEmailModal } from "./EmailModal";
import { useTranslation } from "react-i18next";
import { ChainsObjectsArr } from "../../chainsStaticObject/chainsStaticData";

const LoginButtons = ({ freeNft = false, QRNFTURL = undefined }) => {
  const { t } = useTranslation();
  const [walletAddress, setWalletAddress] = useState("");
  const [providerName, setProviderName] = useState("");
  const [userWalletsData, setUserWalletsData] = useState();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const { search } = location;
  const {
    REACT_APP_FACEBOOK_AVAILABLE,
    REACT_APP_TWITTER_AVAILABLE,
    REACT_APP_GOOGLE_AVAILABLE,
  } = process.env;

  const {
    setEmail,
    setToken,
    setIsLoggedIn,
    setHasPassword,
    network,
    entityInfo,
    isLoggedIn,
    setIsLoading,
    manualRefreshBalance,
  } = useAuth();
  const [auth, setAuth] = useState(null);

  const handleSignIn = useCallback(async (loginOption, user = null) => {
    try {
      const providerMap = {
        google: () => new GoogleAuthProvider(),
        facebook: () => new FacebookAuthProvider(),
        twitter: () => new TwitterAuthProvider(),
      };

      const provider = providerMap[loginOption]?.();
      const authFlag = !!provider;

      if (!authFlag && (loginOption === "phone" || loginOption === "email")) {
        setProviderName(loginOption);
        const accessToken = user.stsTokenManager.accessToken;
        const walletId = user.phoneNumber || user.email;
        setEmail(walletId);
        setNewUser(true);
        setToken(accessToken);
        setAxiosToken(accessToken);
        getWallet(walletId, true);
      }

      if (authFlag) {
        const auth = await getAuthConfig();
        const result = await signInWithPopup(auth, provider);
        console.log("result", result);
        setProviderName(loginOption);
        const email = loginOption === "twitter" ? result._tokenResponse.screenName + "@x.com" : result.user.email;
        setEmail(email);
        setToken(result.user.accessToken);
        setAxiosToken(result.user.accessToken);
        getWallet(email, true);
      }
    } catch (error) {
      console.log(error);
    }
  }, [setEmail, setToken, setAxiosToken]);

  const initAuth = useCallback(async () => {
    const authConfig = await getAuthConfig();
    setAuth(authConfig);
  }, []);

  useEffect(() => {
    if (!isLoggedIn && !auth) {
      initAuth();
    } else if (!isLoggedIn && !newUser) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log("User:", user?.email);
          const walletId = user?.phoneNumber || user?.email;
          if (user?.providerData.length > 0) {
            const providerId = user.providerData[0].providerId;
            const providerName = providerId.split(".")[0];
            setProviderName(providerName);
          }
          setEmail(walletId);
          setToken(user?.stsTokenManager?.accessToken);
          setAxiosToken(user?.stsTokenManager?.accessToken);
          getWallet(walletId, false);
        }
      });
      return () => {
        unsubscribe();
      };
    }
  }, [auth, isLoggedIn, newUser, setEmail, setToken, setAxiosToken]);

  const getWallet = useCallback(async (email, isFreshLogin) => {
    setIsLoading(true);
    const apiName = "getWallet";
    try {
      const response = await axiosToken.get(apiName, {
        params: {
          walletId: email,
          network: "",
        },
      });
      console.log("wallet data", response.data);
      if (!response.data.wallets["EVM"]) {
        openNewPasswordModel(createNewAVMWallet, {
          email: email,
          wallets: response.data.wallets,
        });
      } else {
        const chainHolder = "EVM";
        const wallets = response.data.wallets;
        setHasPassword(wallets[chainHolder].wallets[0].hasPassword);
        setWalletAddress(wallets[chainHolder].wallets[0].publicKey);
        setUserWalletsData(wallets);
        setIsLoading(false);

        if (location.pathname !== "/QrNfts") navigate("/transferDashboard");
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error.hasOwnProperty("response") &&
        error.response &&
        error.response.status === 500 &&
        isFreshLogin
      )
        openNewPasswordModel(createWallet, email);
      else {
        isFreshLogin && toast.error("Error getting wallet");
      }
    }
  }, [setIsLoading, setHasPassword, setWalletAddress, setUserWalletsData, location.pathname, navigate]);

  const createNewAVMWallet = useCallback(async (password, input) => {
    if (password === null) return;
    try {
      const apiName = "getWallet";
      const response = await axiosToken.get(apiName, {
        params: {
          walletId: input.email,
          network: "POLYGON",
          password: password,
        },
      });
      response.data.wallets["CASPER"] = input.wallets["CASPER"];
      if (response.status === 200) {
        toast.success("New EVM Wallet is created successfully");
        const wallets = response.data.wallets;
        setHasPassword(wallets["EVM"].wallets[0].hasPassword);
        setWalletAddress(wallets["EVM"].wallets[0].publicKey);
        setUserWalletsData(wallets);
        setIsLoading(false);
        if (location.pathname !== "/QrNfts") navigate("/transferDashboard");
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Error creating wallet");
    }
  }, [setHasPassword, setWalletAddress, setUserWalletsData, setIsLoading, location.pathname, navigate]);

  const createWallet = useCallback(async (password, email) => {
    const apiName = "createWallet";
    const network = "BASE";
    if (password === null) return;

    setIsLoading(true);

    try {
      const response = await axiosToken.post(apiName, {
        walletId: email,
        network: network,
        password: password,
      });
      toast.success("Wallet created successfully");
      const wallets = response.data.wallets;
      setHasPassword(wallets["EVM"].wallets[0].hasPassword);
      setWalletAddress(wallets["EVM"].wallets[0].publicKey);
      setUserWalletsData(wallets);

      setIsLoading(false);
      if (location.pathname !== "/QrNfts") navigate("/transferDashboard");
    } catch (error) {
      console.log("error", error);
      toast.error("Error creating wallet");
      setIsLoading(false);
    }
  }, [setIsLoading, setHasPassword, setWalletAddress, setUserWalletsData, location.pathname, navigate]);

  useEffect(() => {
    if (walletAddress !== "") {
      const modifiedWalletsWithIndex = Object.keys(userWalletsData).reduce((acc, key) => {
        const value = userWalletsData[key];
        acc[key] = {
          ...value,
          wallets: value.wallets.map((w, index) => ({ ...w, index })),
        };
        return acc;
      }, {});

      setIsLoggedIn(
        true,
        walletAddress,
        "custodial",
        providerName,
        0,
        modifiedWalletsWithIndex
      );

      const baseNetwork = network || ChainsObjectsArr.find((item) => item.chain === "BASE");
      manualRefreshBalance(walletAddress, baseNetwork);
      setIsLoading(false);
    }
  }, [walletAddress, userWalletsData, entityInfo, setIsLoggedIn, network, manualRefreshBalance, setIsLoading]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 922);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const checkUserAuthState = async () => {
      if (!user) {
        const auth = await getAuthConfig();
        if (isSignInWithEmailLink(auth, window.location.href)) {
          const email = localStorage.getItem("email");
          if (email) {
            signInWithEmailLink(auth, email, window.location.href)
              .then((result) => {
                console.log(JSON.stringify(result.user, null, 4));
                setUser(result.user);
                localStorage.removeItem("email");
                handleSignIn("email", result.user);
              })
              .catch((err) => {
                console.log(`Error: ${err}`);
              });
          } else {
            console.log(`No email found, try again`);
          }
        }
      }
    };
    checkUserAuthState();
  }, [user, search, navigate, handleSignIn]);

  const renderButton = (icon, altText, onClick, tooltipText, isAvailable) => (
    <div
      className={`${!freeNft ? "col-12" : "mx-2"} col-md-auto d-flex my-2 align-items-center mouse-cursor`}
      onClick={onClick}
    >
      <WalletTooltip title={tooltipText} className="mx-4">
        <div className={`image-overlay ${isAvailable ? "" : "disabled"}`}>
          <img alt={altText} src={icon} width={45} className={`${isSmallScreen && !freeNft && "me-2"}`} />
        </div>
      </WalletTooltip>
      {isSmallScreen && !freeNft && (
        <p className="mx-1 text-warning-600">{tooltipText}</p>
      )}
    </div>
  );

  return (
    <div className={`mx-4 ${isSmallScreen && !freeNft ? "mt-5" : freeNft ? "d-flex" : "row"}`}>
      {renderButton(
        google_icon,
        "google",
        () => REACT_APP_GOOGLE_AVAILABLE === "true" && handleSignIn("google"),
        t("headerLoggedout.connectGoogle"),
        REACT_APP_GOOGLE_AVAILABLE === "true"
      )}
      {renderButton(
        facebook_icon,
        "facebook",
        () => REACT_APP_FACEBOOK_AVAILABLE === "true" && handleSignIn("facebook"),
        REACT_APP_FACEBOOK_AVAILABLE === "true" ? "Connect with Facebook" : t("headerLoggedout.commingSoon"),
        REACT_APP_FACEBOOK_AVAILABLE === "true"
      )}
      {renderButton(
        twitter_icon,
        "twitter",
        () => REACT_APP_TWITTER_AVAILABLE === "true" && handleSignIn("twitter"),
        REACT_APP_TWITTER_AVAILABLE === "true" ? "Connect with X account" : t("headerLoggedout.commingSoon"),
        REACT_APP_TWITTER_AVAILABLE === "true"
      )}
      {renderButton(
        email_icon,
        "email",
        () => openEmailModal(freeNft, QRNFTURL),
        t("headerLoggedout.connectEmail"),
        true
      )}
      {renderButton(
        phone_icon,
        "phone",
        () => openPhoneModal(handleSignIn),
        t("headerLoggedout.connectPhone"),
        true
      )}
    </div>
  );
};

export default LoginButtons;
