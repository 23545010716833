import React, { createContext, useEffect, useState } from 'react';
//interface of the context object
interface IMultiLangContextValue {
  language: string | null;
  setLanguage: (language: string) => void;
}

//inital values for the context object
const MultiLangContext = createContext<IMultiLangContextValue>({
  language: null,
  setLanguage: () => { },
});

const MultiLangProvider = (props: any) => {
  const [language, setlanguage] = useState<string | null>(null);

  const setLanguage = async (language: any) => {
    try {
      setlanguage(language);
      localStorage.setItem("selectedLanguage", language)
      let link: HTMLLinkElement | any;
      let linkCustom: HTMLLinkElement | any;
      if (language === 'ar') {
        document.documentElement.dir = 'rtl';
        document.documentElement.lang = 'ar';
        document.body.classList.add('rtl-mode');
        link = document.createElement('link');
        link.href = 'bootstrap/dist/css/bootstrap.rtl.min.css';
        link.rel = 'stylesheet';
        document.head.appendChild(link);
        linkCustom = document.createElement('link');
        linkCustom.href = './assets/css/rtl.css';
        linkCustom.rel = 'stylesheet';
        document.head.appendChild(linkCustom);
      } else {
        document.documentElement.dir = 'ltr';
        document.documentElement.lang = 'en';
        document.body.classList.remove('rtl-mode');
        document.head.removeChild(link);
        document.head.removeChild(linkCustom);
      }
      return { language };
    } catch (e: any) {
      return { e };
    }
  };
  useEffect(() => {
    const lang = localStorage.getItem("selectedLanguage")
    setLanguage(lang ? lang : 'en');
  }, []);
  const contextValue: IMultiLangContextValue = {
    language,
    setLanguage,
  };

  return <MultiLangContext.Provider value={contextValue} {...props} />;
};

const useMultiLanguage = () => React.useContext(MultiLangContext);

export { MultiLangProvider, useMultiLanguage };
