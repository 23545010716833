import HORUS_FACTORY from "./HorusFactory.json";
import HORUS_NFT from "./HorusNFT.json";

export const HORUS_FACTORY_CONTRACT_ADDRESS = {
  POLYGON: {
    TESTNET: "0x32087e722229BE9134da4524D74c00492a77aaC7",
    MAINNET: "0xB8c0b04509e9476a951053CCD8Dc5892A8d7d4c2",
  },
  ETHEREUM: {
    TESTNET: "0xaA1a3b324B6079D71083Bb34F981634c6fb44579",
    MAINNET: "",
  },
  BSC: {
    TESTNET: "0xbe3582ab46DDEFAD8b942e0F9B9F19C749D50796",
    MAINNET: "",
  },
  TELOS: {
    TESTNET: "0xbe3582ab46DDEFAD8b942e0F9B9F19C749D50796",
    MAINNET: "",
  },
  FLARE: {
    TESTNET: "0xd04359099D1C009eCe9aB4DaDb02b23d819CDE6F",
    MAINNET: "",
  },
  BASE: {
    TESTNET: "0xC61272D208c106b291d41dE7781aB4EeB88967B0",
    MAINNET: "0xe1696f8B54B28f8bAff68b538bE73BaEadec6758",
  },
  PWR_ETH_PLUS: {
    TESTNET: "0x1E39dB59426ffCb5c0b79E33b466dc41660bb801",
    MAINNET: "",
  },
  PWR_BTC_PLUS: {
    TESTNET: "0xbe3582ab46DDEFAD8b942e0F9B9F19C749D50796",
    MAINNET: "",
  },
  BITLAYER: {
    TESTNET: "0x32087e722229BE9134da4524D74c00492a77aaC7",
    MAINNET: "",
  },
  CHILIZ: {
    TESTNET: "0x7BDa2F05793313BAFE39C4df68A1Db541C5E38fD",
    MAINNET: "",
  },
  BITFINITY: {
    TESTNET: "0x882A12aa0e3082Ba5868Ffd7B7C46C0c542D4BCa",
    MAINNET: "",
  },
  ARBITRUM: {
    TESTNET: "0x6d8A48addDC7Ace3cFFE64C72574C218Fc5B944b",
    MAINNET: "",
  },
  AVALANCHE: {
    TESTNET: "0x1E39dB59426ffCb5c0b79E33b466dc41660bb801",
    MAINNET: "",
  },
  SONEIUM:{
    TESTNET: "0x14e63e8a56D5F310F9e4DAd430A1434678500df1",
    MAINNET: "",
  }
};

export const HORUS_FACTORY_ABI = HORUS_FACTORY.abi;

export const HORUS_NFT_ABI = HORUS_NFT.abi;
