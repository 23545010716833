import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';

import { useAuth } from '../web3/context/AuthContext';
import { createCollection } from '../collections-manager/create-collection/collection-ui-utils';
import mintHolder from '../../assets/images/all-img/blog2/dark/blog14.png';

import BackButton from '../common/backButton';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Preloader from '../common/preloader/preloader';
import {
  createCollectionCustodial,
  createCollectionNonCustodial
} from "../web3/nfts-utils/nfts-access/NFTsAccess_Utils";
import { openPasswordModel } from "../../components/social-wallet/PasswordModal";
import { TOKEN_TYPE } from "../web3/nfts-utils/nfts-access/NFTsAccess_Constants";
import NFTAccessUshers from './nft-access-ushers';
import { useTranslation } from 'react-i18next';

function CreateNFTAccess() {
  let initialNFTAccessProps = {
    name: '',
    maxScanPerUser: '',
    image: null,
    balance: ''
  };
  const tokenArray = Object.entries(TOKEN_TYPE).map(([key, value]) => ({
    label: key,
    value,
  }));
  let navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [nftAccessProps, setNFTAccessProps] = useState(
    initialNFTAccessProps
  );
  const [tokenType, setTokenType] = useState(tokenArray[0]);
  const [tokenId, setTokenID] = useState("0");
  const [contractAddress, setContractAddress] = useState("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE");
  const [ushersList, setUshersList] = useState([]);
  const {
    balance, loginOption, email, entityInfo, hasPassword, network
  } = useAuth();
  const customStyles = {
    container: (base) => ({
      ...base,
      display: 'flex',
      alignItems: "center",
      width: "100%"
    }),
    control: (base, state) => ({
      ...base,
      height: '60px',
      background: "#1e2930",
      border: '1px solid #e1e1e1',
      color: '#e1e1e1',
      boxShadow: state.isFocused ? null : null,
      borderRadius: '10px',
      width: "100%",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 'auto',
      display: "block",
      background: "transparent",
      border: "none",
      padding: "0",
      input: { height: 0 },
      width: "100%"
    }),
    input: (provided, state) => ({
      ...provided,
      height: '60px'
    }),
    indicatorSeparator: state => ({
      display: 'none',
      border: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '60px',
      border: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap',
    }),
    menu: (provided, state) => ({
      ...provided,
      color: 'black',
      padding: 10,

    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent',
      '&:active': {
        backgroundColor: 'transparent',
      },
      color: 'black',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      color: "#fff",
      height: '60px',
      padding: '15px 30px !important'
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    setNFTAccessProps((prevNftProps) => ({
      ...prevNftProps,
      [name]: value,
    }));
  };

  const handleChangeToken = (e) => {
    setTokenType(e);
    if (e.value == TOKEN_TYPE.NATIVE_TOKEN) {
      setContractAddress("0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE");
      setTokenID("0")
    } else {
      setContractAddress("");
      if (e.value == TOKEN_TYPE.ERC20 || e.value == TOKEN_TYPE.ERC721) {
        setTokenID("0")
      }

    }
  }

  const createNFTAccess = async () => {
    try {
      setLoading(true)
      if (loginOption == "custodial") {
        if (hasPassword) {
          openPasswordModel(handlePassSecureTransfer, {
            name: nftAccessProps.name,
            contractAddress: contractAddress,
            maxScanPerUser: nftAccessProps.maxScanPerUser,
            tokenType: tokenType.value,
            tokenId: tokenId,
            ushers: ushersList,
            setLoading
          }, setLoading);
        } else {
          await createCollectionCustodial({
            name: nftAccessProps.name,
            tokenType: tokenType.value,
            tokenId: tokenId,
            maxScanPerUser: nftAccessProps.maxScanPerUser,
            contractAddress: contractAddress,
            ushers: ushersList
          },
            network.chain,
            email,
            entityInfo.indexWallet, ""
          )
          navigate('/transferDashboard?selectedTab=NFT_Access');

        }
      } else if (loginOption == "non_custodial") {
        await createCollectionNonCustodial({
          name: nftAccessProps.name,
          tokenType: tokenType.value,
          tokenId: tokenId,
          maxScanPerUser: nftAccessProps.maxScanPerUser,
          contractAddress: contractAddress,
          ushers: ushersList
        },
          network.chain)

        navigate('/transferDashboard?selectedTab=NFT_Access');
      }
      setLoading(false)

    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };
  const handlePassSecureTransfer = async (password, input) => {
    if (password === '') return toast.error('Password is required');
    if (password == null) return;
    try {
      await createCollectionCustodial({
        name: input.name,
        tokenType: input.tokenType,
        tokenId: input.tokenId,
        maxScanPerUser: input.maxScanPerUser,
        contractAddress: input.contractAddress,
        ushers: input.ushers
      },
        network.chain,
        email,
        entityInfo.indexWallet, password);
      input.setLoading(false);
      navigate('/transferDashboard?selectedTab=NFT_Access');

    } catch (e) {
      console.log(e);
      input.setLoading(false);
    }
  };
  return (
    <div className="fugu--hero-section container">
      <BackButton selectedTab={'NFT_Access'} />
      <h1 className="text-center text-white mx-2">
        {t("dashboard.tabs.nftAccess.createNFTAccess")}
      </h1>
      <Preloader show={loading} />
      <div className=" container d-flex justify-content-center">
        <div className="fugu--blog-sidebar-section mt-5 pb-5 w-100">
          <div className="row">

            <div className="col-lg-6">
              <div className="fugu--blog-title">
                <div className="row mt-3 ">
                  <div className="col">
                    <input
                      type="default"
                      placeholder={t("dashboard.tabs.createCollection.collectionName")}
                      className="text-gray-700"
                      name="name"
                      value={nftAccessProps.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-3 ">
                  <div className="col">
                    <input
                      type="number"
                      placeholder={t("dashboard.tabs.nftAccess.maxScanPerUser")}
                      className="text-gray-700"
                      name="maxScanPerUser"
                      value={nftAccessProps.maxScanPerUser}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mt-3 ">
                  <div className="col">
                    <Select
                      value={tokenType}
                      isSearchable={false}
                      options={tokenArray}
                      styles={customStyles}
                      onChange={(e) => handleChangeToken(e)}
                    />
                  </div>
                  {TOKEN_TYPE.ERC1155 === tokenType.value &&
                    <div className="col">
                      <input
                        type="text"
                        placeholder={t("dashboard.tabs.nftAccess.tokenId")}
                        className="text-gray-700 text-uppercase"
                        name="tokenId"
                        value={tokenId}
                        onChange={(e) => setTokenID(e.target.value)}
                      />
                    </div>}
                </div>
                {TOKEN_TYPE.NATIVE_TOKEN != tokenType.value &&
                  <div className='row mt-3'>
                    <div className='col'>
                      <input
                        type="text"
                        placeholder={t("dashboard.tabs.nftAccess.contractAddress")}
                        className="text-gray-700 text-uppercase"
                        name="contractAddress"
                        value={contractAddress}
                        onChange={(e) => setContractAddress(e.target.value)}
                      />
                    </div>
                  </div>}
              </div>

            </div>
            <div className='col-lg-6'>
              <NFTAccessUshers passUshers={(result) => setUshersList(result)} />
            </div>
            <div className="row mb-5">
              <div className="col-auto justify-content-end">
                <button
                  className="fugu--outline-btn"
                  onClick={createNFTAccess}
                  disabled={loading || nftAccessProps.maxScanPerUser == "" ||
                    nftAccessProps.name == "" || contractAddress == "" ||
                    tokenType.value == "" || tokenId == ""}
                >
                  <span>{t("dashboard.tabs.nftAccess.createNFTAccess")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateNFTAccess;
