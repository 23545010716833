import NFTS_PROXY from "./NftsProxy.json";
import ERC721 from "./ERC721.json";
import ERC1155 from "./ERC1155.json";

export const NFTS_PROXY_CONTRACT_ADDRESS = {
  POLYGON: {
    TESTNET: "0x1E39dB59426ffCb5c0b79E33b466dc41660bb801",
    MAINNET: "0x83a0FFfEb0bcD9CfBba0e7E927DBBF719e97b6b3",
  },
  ETHEREUM: {
    TESTNET: "0xC61272D208c106b291d41dE7781aB4EeB88967B0",
    MAINNET: "",
  },
  BSC: {
    TESTNET: "0x00ED4cEeAC49938b5DA96684eF10B48f12B41876",
    MAINNET: "",
  },
  TELOS: {
    TESTNET: "0x00ED4cEeAC49938b5DA96684eF10B48f12B41876",
    MAINNET: "",
  },
  FLARE: {
    TESTNET: "0x9dcc27A8f564EDF63851Aba4796F77866D79435F",
    MAINNET: "",
  },
  BASE: {
    TESTNET: "0xAb8D4213115Dd543bAC7d6591E6057C6ABEE4909",
    MAINNET: "0x046235053Fa4131A9B628210e2A3182D13e7c04c",
  },
  PWR_ETH_PLUS: {
    TESTNET: "0x00ED4cEeAC49938b5DA96684eF10B48f12B41876",
    MAINNET: "",
  },
  PWR_BTC_PLUS: {
    TESTNET: "0x00ED4cEeAC49938b5DA96684eF10B48f12B41876",
    MAINNET: "",
  },
  BITLAYER: {
    TESTNET: "0x4bDF39d5420173CC23100c1744C1a50165428140",
    MAINNET: "",
  },
  CHILIZ: {
    TESTNET: "0x72087723fA4Edc1B9929c55B8f05057aE36658b9",
    MAINNET: "",
  },
  BITFINITY: {
    TESTNET: "0x6d8A48addDC7Ace3cFFE64C72574C218Fc5B944b",
    MAINNET: "",
  },
  ARBITRUM: {
    TESTNET: "0x13A840015EB059f0F81DE9aEb05c2BF9F7EA76C5",
    MAINNET: "",
  },
  AVALANCHE: {
    TESTNET: "0x00ED4cEeAC49938b5DA96684eF10B48f12B41876",
    MAINNET: "",
  },
  SONEIUM:{
    TESTNET: "0x00ED4cEeAC49938b5DA96684eF10B48f12B41876",
    MAINNET: "",
  }
};

export const NFTS_PROXY_ABI = NFTS_PROXY.abi;

export const ERC721_ABI = ERC721.abi;
export const ERC1155_ABI = ERC1155.abi;
