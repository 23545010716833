import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { getCollections } from '../../../components/web3/nfts-utils/nfts-factory/HorusFactory_Utils';
import { useAuth } from '../../../components/web3/context/AuthContext';
import Checkbox from '@mui/material/Checkbox';
import Preloader from '../../../components/common/preloader/preloader';
import { useTranslation } from 'react-i18next';
function NftsLibrary({ onCollectionSelect }) {
    const { t } = useTranslation();
    const { loginOption, network, entityInfo } = useAuth()
    const [collections, setCollections] = useState()
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [loading, setLoading] = useState(false)
    const fetchCurrentCollections = async () => {
        setLoading(true)
        try {
            console.log(network, entityInfo.activePublicKey);

            const fetchedCollections = await getCollections(network, entityInfo.activePublicKey);
            setCollections(fetchedCollections)
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };
    const handleCollectionSelect = (collection) => {
        setSelectedCollection(collection);
        onCollectionSelect(collection);
    };
    useEffect(() => {
        fetchCurrentCollections()
    }, [])
    return (
        <>
            <Preloader show={loading} />
            <div className="col-lg-12">
                <div className="fugu--breadcrumbs-data">
                    {/* <h1>Select Collection</h1> */}
                    <p className='mb-3'>
                        You can choose your Collection from your Amazing and Unique library
                    </p>
                </div>
                <div className="row">
                    {collections && collections.length > 0 && collections.map((elem) =>
                        <div className="col-md-6">
                            <div className="fugu--blog-wrap fugu--blog-wrap2 wow fadeInUpX" data-wow-delay="0s">
                                <div className="fugu--blog-thumb">
                                    <img src={elem.image} alt="" />
                                </div>
                                <div className="fugu--blog-content">
                                    <div className="fugu--blog-date">
                                        <ul>
                                            <li>
                                                <Link href={"#"}>{`${t("dashboard.tabs.myCollections.maxNfts")} ${elem.maxNfts}`}</Link>
                                            </li>
                                            <li>
                                                <Link href={"#"}>{`${t("dashboard.tabs.myCollections.mintedNfts")} ${elem.currNFts}`}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="fugu--blog-title text-uppercase">
                                        <div className='d-flex align-items-center'>
                                            <h3>{elem.name} {"_"} {elem.symbol}</h3>
                                            <Checkbox
                                                className='mb-4  ms-3 text-warning-600'
                                                checked={selectedCollection === elem}
                                                onChange={() => handleCollectionSelect(elem)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}
                </div>
            </div>
        </>
    )
}

export default NftsLibrary