import binanceIcon from "../assets/images/networks-icons/binance.png";
import casperIcon from "../assets/images/networks-icons/casper.png";
import etherIcon from "../assets/images/networks-icons/eth.png";
import polygonIcon from "../assets/images/networks-icons/polygon.png";
import telosIcon from "../assets/images/networks-icons/telos.png";
import flareIcon from "../assets/images/networks-icons/flare.png";
import chilizIcon from "../assets/images/networks-icons/chiliz.png";
import baseIcon from "../assets/images/networks-icons/base.png";
import bitlayerIcon from "../assets/images/networks-icons/bitlayer.png";
import bitfinity from "../assets/images/networks-icons/bitfinity.png";
import btcIcon from "../assets/images/networks-icons/btc.png";
import pwrIcon from "../assets/images/networks-icons/pwr.png";
import icpIcon from "../assets/images/networks-icons/icp.png";
import arbitrumIcon from "../assets/images/networks-icons/arbitrum.png";
import aeternityIcon from "../assets/images/networks-icons/aeternity.png";
import avalancheIcon from "../assets/images/networks-icons/avalanche.png";
import soneiumIcon from "../assets/images/networks-icons/soneium.png";

import { SUPPORTED_OPTIONS } from "../components/web3/utils/Providers";
import { Network } from "casper-storage";
import { EvmNetwork } from "../components/web3/utils/EvmNetwork";
import { CasperNetwork } from "../components/web3/utils/CasperNetwork";
import { ICPNetwork } from "../components/web3/utils/ICPNetwork";
import { BitcoinNetwork } from "../components/web3/utils/BitcoinNetwork";
import { AeternityNetwork } from "../components/web3/utils/AeternityNetwork";

const chainType = process.env.REACT_APP_NETWORK_TYPE;

const MAINNET_SUPPORTED_CHAINS = ["BASE", "POLYGON"];
const TESTNET_SUPPORTED_CHAINS = [
  "BASE",
  "POLYGON",
  "BSC",
  "ETHEREUM",
  "TELOS",
  "FLARE",
  "BITLAYER",
  "BITCOIN",
  // "PWR_ETH_PLUS",
  "PWR_BTC_PLUS",
  "CHILIZ",
  "BITFINITY",
  "ICP",
  "ARBITRUM",
  "AETERNITY",
  "AVALANCHE",
  "SONEIUM",
];

const ChainsObjects = [
  {
    title: "Base",
    chain: "BASE",
    symbol: "BASE",
    nativeToken: "ETH",
    decimals: 18,
    network: new EvmNetwork("BASE"),
    networkId: chainType == "TESTNET" ? "84532" : "8453",
    chainName: chainType == "TESTNET" ? "base-sepolia" : "base",
    minMumTrAmount: 0.001,
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.BASE,
    logo: baseIcon,
    image: baseIcon,
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Arbitrum",
    chain: "ARBITRUM",
    symbol: "ARB",
    nativeToken: "ETH",
    decimals: 18,
    network: new EvmNetwork("ARBITRUM"),
    minMumTrAmount: 0.001,
    networkId: chainType == "TESTNET" ? "421614" : "42161",
    chainName: chainType == "TESTNET" ? "arbitrum-sepolia" : "arbitrum",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.ARBITRUM,
    logo: arbitrumIcon,
    image: arbitrumIcon,
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Polygon",
    chain: "POLYGON",
    symbol: "MATIC",
    nativeToken: "MATIC",
    decimals: 18,
    network: new EvmNetwork("POLYGON"),
    minMumTrAmount: 0.001,
    networkId: chainType == "TESTNET" ? "80002" : "137",
    chainName: chainType == "TESTNET" ? "amoy" : "polygon",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.POLYGON,
    logo: polygonIcon,
    image: polygonIcon, // set this var to use it in header networks original images
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Avalanche",
    chain: "AVALANCHE",
    symbol: "AVAX",
    nativeToken: "AVAX",
    decimals: 18,
    network: new EvmNetwork("AVALANCHE"),
    minMumTrAmount: 0.001,
    networkId: chainType == "TESTNET" ? "43113" : "43114",
    chainName: chainType == "TESTNET" ? "avalancheFuji" : "avalanche",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.AVALANCHE,
    logo: avalancheIcon,
    image: avalancheIcon,
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Soneium",
    chain: "SONEIUM",
    symbol: "SONY",
    nativeToken: "ETH",
    decimals: 18,
    network: new EvmNetwork("SONEIUM"),
    minMumTrAmount: 0.001,
    networkId: chainType == "TESTNET" ? "1946" : "1946",
    chainName: chainType == "TESTNET" ? "soneiumMinato" : "soneium",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.SONEIUM,
    logo: soneiumIcon,
    image: soneiumIcon,
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Binance",
    chain: "BSC",
    symbol: "BNB",
    nativeToken: "BNB",
    decimals: 18,
    network: new EvmNetwork("BSC"),
    minMumTrAmount: 0.001,
    networkId: chainType == "TESTNET" ? "97" : "56",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.BSC,
    logo: binanceIcon,
    image: binanceIcon, // set this var to use it in header networks original images
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Ethereum",
    chain: "ETHEREUM",
    symbol: "ETH",
    nativeToken: "ETH",
    decimals: 18,
    network: new EvmNetwork("ETHEREUM"),
    minMumTrAmount: 0.001,
    networkId: chainType == "TESTNET" ? "11155111" : "1",
    chainName: chainType == "TESTNET" ? "sepolia" : "ethereum",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.ETHEREUM,
    logo: etherIcon,
    image: etherIcon, // set this var to use it in header networks original images
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "ICP",
    chain: "ICP",
    symbol: "ICP",
    nativeToken: "ICP",
    decimals: 8,
    network: new ICPNetwork("ICP"),
    minMumTrAmount: 0.0001,
    networkId: chainType == "TESTNET" ? "0" : "0",
    networkType: "ICP",
    supportedOptions: SUPPORTED_OPTIONS.ICP,
    logo: icpIcon,
    image: icpIcon,
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: false
  },
  {
    title: "Aeternity",
    chain: "AETERNITY",
    symbol: "AE",
    nativeToken: "AE",
    decimals: 18,
    network: new AeternityNetwork("AETERNITY"),
    minMumTrAmount: 0.1,
    networkId: chainType == "TESTNET" ? "0" : "0",
    networkType: "AETERNITY",
    supportedOptions: SUPPORTED_OPTIONS.AETERNITY,
    logo: aeternityIcon,
    image: aeternityIcon,
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "CASPER",
    chain: "CASPER",
    symbol: "CSPR",
    nativeToken: "CSPR",
    network: new CasperNetwork("CASPER"),
    minMumTrAmount: 10,
    networkId: "0",
    networkType: "CASPER",
    supportedOptions: SUPPORTED_OPTIONS.CASPER,
    logo: casperIcon,
    image: casperIcon, // set this var to use it in header networks original images
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Telos",
    chain: "TELOS",
    symbol: "TLOS",
    nativeToken: "TLOS",
    decimals: 18,
    network: new EvmNetwork("TELOS"),
    minMumTrAmount: 10,
    networkId: chainType == "TESTNET" ? "41" : "40",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.TELOS,
    logo: telosIcon,
    image: telosIcon,
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Flare",
    chain: "FLARE",
    symbol: "FLR",
    nativeToken: "FLR",
    decimals: 18,
    network: new EvmNetwork("FLARE"),
    minMumTrAmount: 10,
    networkId: chainType == "TESTNET" ? "114" : "14",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.FLARE,
    logo: flareIcon,
    image: flareIcon,
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Chiliz",
    chain: "CHILIZ",
    symbol: "CHZ",
    nativeToken: "CHZ",
    decimals: 18,
    network: new EvmNetwork("CHILIZ"),
    minMumTrAmount: 0.1,
    networkId: chainType == "TESTNET" ? "88882" : "88888",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.CHILIZ,
    logo: chilizIcon,
    image: chilizIcon,
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Bitfinity",
    chain: "BITFINITY",
    symbol: "BFT",
    nativeToken: "BFT",
    decimals: 18,
    network: new EvmNetwork("BITFINITY"),
    minMumTrAmount: 0.1,
    networkId: chainType == "TESTNET" ? "355113" : "355110",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.BITFINITY,
    logo: bitfinity,
    image: bitfinity,
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "PWR ETH+",
    chain: "PWR_ETH_PLUS",
    symbol: "ETH",
    nativeToken: "ETH",
    decimals: 18,
    network: new EvmNetwork("PWR_ETH_PLUS"),
    minMumTrAmount: 0.1,
    networkId: chainType == "TESTNET" ? "10023" : "10023",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.PWR_ETH_PLUS,
    logo: pwrIcon,
    image: pwrIcon,
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: false
  },
  {
    title: "PWR BTC+",
    chain: "PWR_BTC_PLUS",
    symbol: "BTC",
    nativeToken: "BTC",
    decimals: 18,
    network: new EvmNetwork("PWR_BTC_PLUS"),
    minMumTrAmount: 0.1,
    networkId: chainType == "TESTNET" ? "21000001" : "21000001",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.PWR_BTC_PLUS,
    logo: pwrIcon,
    image: pwrIcon,
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Bitlayer",
    chain: "BITLAYER",
    symbol: "BTC",
    nativeToken: "BTC",
    decimals: 18,
    network: new EvmNetwork("BITLAYER"),
    minMumTrAmount: 0.0001,
    networkId: chainType == "TESTNET" ? "200810" : "200901",
    networkType: "EVM",
    supportedOptions: SUPPORTED_OPTIONS.BITLAYER,
    logo: bitlayerIcon,
    image: bitlayerIcon,
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: true
  },
  {
    title: "Bitcoin",
    chain: "BITCOIN",
    symbol: "BTC",
    nativeToken: "BTC",
    decimals: 8,
    network: new BitcoinNetwork("BITCOIN"),
    minMumTrAmount: 0.000001,
    networkId: chainType == "TESTNET" ? "0" : "0",
    networkType: "BITCOIN",
    supportedOptions: SUPPORTED_OPTIONS.BITCOIN,
    logo: btcIcon,
    image: btcIcon,
    apiKey: "",
    tokenAddress: "0x0000000000000000000000000000000000000000",
    submenu: [],
    isSupportClaimNFT: false
  },
];

export const ChainsObjectsArr =
  chainType == "TESTNET"
    ? ChainsObjects.filter((chain) =>
      TESTNET_SUPPORTED_CHAINS.includes(chain.chain)
    )
    : ChainsObjects.filter((chain) =>
      MAINNET_SUPPORTED_CHAINS.includes(chain.chain)
    );
