import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import phone from "phone";
import { transferSelectedMethod } from "../../../../components/layout/navbar/menu-data";
import Dropdown from "react-dropdown";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { handleCSVUpload, isValidTwitterAccount } from "../../../../helper/helperFunc";
import {
  isValidEmail,
  isValidWalletAddress,
} from "../../../../helper/helperFunc";
import { useAuth } from "../../../../components/web3/context/AuthContext";
import Preloader from "../../../../components/common/preloader/preloader";
import {
  airdrop_custodial,
  airdrop_nonCustodial,
  depositNfts_custodial,
  depositNfts_nonCustodial,
} from "../../../../components/web3/nfts-utils/nfts-factory/HorusFactory_Utils";
import { openPasswordModel } from "../../../../components/social-wallet/PasswordModal";
import BackButton from "../../../../components/common/backButton";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

function MintManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const passedCollectionData = location.state;
  const [collectionData, setCollectionData] = useState(passedCollectionData);
  const [receiversTypeSelection, setReceiversTypeSelection] = useState(
    transferSelectedMethod[0]
  );
  const [inputPlaceHolder, setInputPlaceHolder] = useState(t("general.email"));
  const [receiversAddressArr, setReceiversAddressArr] = useState([]);
  const [receiverAddress, setReceiverAddress] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [sendCustomEmails, setSendCustomEmails] = useState(false);
  const [sendEmails, setSendEmails] = useState(true);
  const {
    isLoggedIn,
    loginOption,
    email,
    balance,
    network,
    hasPassword,
    entityInfo,
  } = useAuth();

  useEffect(() => {
    if (transferSelectedMethod.length > 0) {
      setSelectedOption({
        value: transferSelectedMethod[0].icon,
        label: (
          <div className="dropdown-item">
            <img
              src={transferSelectedMethod[0].icon}
              alt={transferSelectedMethod[0].name}
              className="fugu-network-icons mx-2"
            />
          </div>
        ),
      });
    }
  }, []);
  const recommendedMailFormats = {
    subject: "NEW NFT MINTED FOR YOU ..",
    body: "Set your recommended message ..",
  };
  useEffect(() => {
    if (!isLoggedIn) navigate("/");
  }, [isLoggedIn, receiversTypeSelection]);

  useEffect(() => {
    if (!collectionData) navigate("/transferDashboard");
  }, [collectionData]);
  // receivers type dropdown
  const handleDropDownSelection = (e) => {
    setReceiversAddressArr([]);
    setReceiversTypeSelection(e);
    setInputPlaceHolder(t(e.title));
  };

  const detectCsvFileData = async (event, type) => {
    if (type) {
      const data = await handleCSVUpload(event, type.name, network.chain);
      let filteredData = filterAndReturnStrings(data, type.name);
      data && setReceiversAddressArr(filteredData);
    } else {
      toast.error("Select receivers type before upload");
    }
  };

  const filterAndReturnStrings = (data, type) => {
    const validData = data.filter((e) => e.isValid);
    const result = [];

    validData.forEach((item) => {
      if (item.mail && type == "Email") result.push(item.mail);
      if (item.phone && type == "Phone") result.push(item.phone);
      if (item.wallet && type == "Wallet") result.push(item.wallet);
      if (item.twitter && type == "Twitter") result.push(item.twitter + "@x.com");
    });

    return result;
  };

  // add manual receivers input handling
  const handleInputChange = (e) => {
    const value = e.target.value;
    const newInputElement = e.target;
    if (isValid(value, receiversTypeSelection)) {
      newInputElement.style.color = "#fff";
    } else {
      newInputElement.style.color = "red";
    }
    setReceiverAddress(value);
  };

  const isValid = (input, dropdownSelection) => {
    if (!dropdownSelection) {
      return false;
    } else if (dropdownSelection.name == "Email") {
      return isValidEmail(input);
    } else if (dropdownSelection.name == "Phone") {
      return phone(input).isValid;
    } else if (dropdownSelection.name == "Wallet") {
      return isValidWalletAddress(input);
    } else if (dropdownSelection.name == "Twitter") {
      return isValidTwitterAccount(input);
    }
    return false;
  };

  const addManualReceiver = () => {
    if (
      isValid(receiverAddress, receiversTypeSelection) &&
      receiversTypeSelection
    ) {
      switch (receiversTypeSelection.name) {
        case "Twitter":
          setReceiversAddressArr((prevReceiversAddressArr) => [
            ...prevReceiversAddressArr,
            receiverAddress + "@x.com",
          ]);
          break;
        default:
          setReceiversAddressArr((prevReceiversAddressArr) => [
            ...prevReceiversAddressArr,
            receiverAddress,
          ]);
          break
      }
      setReceiverAddress("");
    }
  };
  const removeEmail = (index) => {
    const newMailsArr = receiversAddressArr.filter((item, i) => i !== index);
    setReceiversAddressArr(newMailsArr);
  };
  const mint = async () => {
    if (receiversAddressArr.length == 0) return;

    if (balance < 0.001)
      return toast.error("You don't have enough balance to mint");
    const validMintCount =
      parseInt(collectionData.maxNfts) - parseInt(collectionData.currNFts);
    if (receiversAddressArr.length > validMintCount)
      return toast.error(
        `It seems you're trying to mint more NFTs than the allowed limit for this collection.`
      );
    try {
      if (isLoggedIn) {
        // setLoading(true);
        if (loginOption == "custodial") {
          if (hasPassword) {
            if (
              receiversTypeSelection.name == "Email" ||
              receiversTypeSelection.name == "Phone" ||
              receiversTypeSelection.name == "Twitter"
            ) {
              openPasswordModel(depositNftCustodial, setLoading);
            } else if (receiversTypeSelection.name == "Wallet") {
              openPasswordModel(airDropCustodial, setLoading);
            }
          } else {
            if (
              receiversTypeSelection.name == "Email" ||
              receiversTypeSelection.name == "Phone" ||
              receiversTypeSelection.name == "Twitter"
            ) {
              depositNftCustodial();
            } else if (receiversTypeSelection.name == "Wallet") {
              airDropCustodial();
            }
          }
        } else if (loginOption == "non_custodial") {
          setLoading(true);
          if (
            receiversTypeSelection.name == "Email" ||
            receiversTypeSelection.name == "Phone" ||
            receiversTypeSelection.name == "Twitter"
          ) {
            await depositNfts_nonCustodial(
              network,
              collectionData.contractAddress,
              receiversAddressArr,
              receiversTypeSelection.name == "Email" ? sendEmails : false
            );
            setLoading(false);
            refreshNftsCount();
            //send your custom email
            if (!sendEmails && receiversTypeSelection.name == "Email") {
              setSendCustomEmails(true);
            }
          } else if (receiversTypeSelection.name == "Wallet") {
            setLoading(true);
            await airdrop_nonCustodial(
              collectionData.contractAddress,
              receiversAddressArr,
              network
            );
            setLoading(false);
            refreshNftsCount();
          }
        }
        // setReceiversAddressArr([]);
      } else {
        toast.error("Please login to mint NFTs");
      }
    } catch (error) {
      toast.error("Error in minting NFTs");
      setLoading(false);
    }
  };
  const refreshNftsCount = () => {
    const currentNfts =
      parseInt(collectionData.currNFts) + receiversAddressArr.length;
    setCollectionData((prev) => ({
      ...prev,
      currNFts: currentNfts,
    }));
  };
  // mint from social users to phone or mails
  const depositNftCustodial = async (password) => {
    if (hasPassword && password === "")
      return toast.error("Password is required");
    if (hasPassword && password == null) return;
    setLoading(true);
    try {
      const tx = await depositNfts_custodial(
        email,
        entityInfo.indexWallet,
        network,
        collectionData.contractAddress,
        receiversAddressArr,
        receiversTypeSelection.title == "Email" ? sendEmails : false,
        password
      );
      setLoading(false);
      refreshNftsCount();
      //send your custom email
      if (!sendEmails && receiversTypeSelection.title == "Email") {
        setSendCustomEmails(true);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  // mint from social user to wallet
  const airDropCustodial = async (password) => {
    debugger;
    if (hasPassword && password === "")
      return toast.error("Password is required");
    if (hasPassword && password == null) return;
    setLoading(true);
    try {
      const tx = await airdrop_custodial(
        email,
        entityInfo.indexWallet,
        network,
        collectionData.contractAddress,
        receiversAddressArr,
        password
      );
      setLoading(false);
      refreshNftsCount();
    } catch (err) {
      setLoading(false);
      toast.error("Error in minting NFTs");
    }
  };
  // const handlePasswordSubmit = async (password, input) => {
  //   await input.method(...input.params, password);
  // };
  return (
    <>
      <Preloader show={loading} />
      {collectionData && (
        <div className="fugu--portfolio-section fugu--section-padding">
          <BackButton selectedTab={"Collections"} />
          <div className="container mt-5">
            <div className="row">
              <div className="col-xl-4  col-12">
                <div
                  className="fugu--blog-wrap fugu--blog-wrap2 wow fadeInUpX"
                  data-wow-delay="0s"
                >
                  <div className="fugu--blog-thumb">
                    <img src={collectionData.image} alt="" />
                  </div>
                  <div className="fugu--blog-content">
                    <div className="fugu--blog-date">
                      <ul>
                        <li>
                          <p className="text-warning-600">{`${t(
                            "dashboard.tabs.myCollections.maxNfts"
                          )} ${collectionData.maxNfts}`}</p>
                        </li>
                        <li>
                          <p className="text-warning-600">{`${t(
                            "dashboard.tabs.myCollections.mintedNfts"
                          )} ${collectionData.currNFts}`}</p>
                        </li>
                      </ul>
                    </div>
                    <div className="fugu--blog-title text-uppercase d-flex justify-content-center ">
                      <div className="d-flex align-items-center">
                        <h3 className="text-warning-600">
                          {collectionData.name} {"_"} {collectionData.symbol}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4  col-12">
                <div className="row fugu-dropdown dropdown-control mb-3">
                  <div className="col-auto">
                    <Dropdown
                      options={transferSelectedMethod
                        .filter((item) => !item.disabled)
                        .map((item, optionIndex) => ({
                          value: item,
                          label: (
                            <div key={optionIndex} className="dropdown-item">
                              <img
                                src={item.icon}
                                alt={item.name}
                                className="fugu-network-icons mx-2"
                              />
                            </div>
                          ),
                        }))}
                      onChange={(e) => handleDropDownSelection(e.value)}
                      placeholder={"select"}
                      arrowClosed={
                        <FontAwesomeIcon icon={faAngleDown} className="ms-2" />
                      }
                      arrowOpen={
                        <FontAwesomeIcon icon={faAngleUp} className="ms-2" />
                      }
                      className="fugu-dropdown dropdown-control"
                      controlClassName="fugu-dropdown dropdown-control"
                      arrowClassName="dropdown-arrow"
                      placeholderClassName="dropdown-placeholder"
                      value={selectedOption}
                    />
                  </div>
                  <div className="col-auto">
                    <input
                      type="file"
                      id="file-input"
                      name="file"
                      onChange={(event) =>
                        detectCsvFileData(event, receiversTypeSelection)
                      }
                      accept=".csv"
                      capture="user"
                      style={{ display: "none" }}
                    />
                    <label htmlFor="file-input">
                      <span className="fugu--btn btn-sm bg-white">
                        {t("dashboard.tabs.myCollections.uploadCsv")}
                      </span>
                    </label>
                  </div>
                </div>
                {receiversAddressArr.length > 0 && (
                  <div className="fugu--blog-sidebar-item row mt-5 mb-2">
                    <div className="fugu--tags overflow-hidden">
                      {receiversAddressArr.length > 0 &&
                        receiversAddressArr.map((e, index) => (
                          <div key={index} className="row m-1">
                            <div
                              className={`ml-3 fugu-remove-icon col-auto text-danger-500`}
                              onClick={() => removeEmail(index)}
                            >
                              <FontAwesomeIcon icon={faClose} />
                            </div>
                            <div className="col-auto">
                              <p
                                className={`text-warning-600 ${receiversTypeSelection.title == "Wallet"
                                  ? "small"
                                  : ""
                                  }`}
                              >
                                {e}
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xl-4  col-12">
                <div className="row fugu-dropdown dropdown-control">
                  <div className="col-lg-auto col-12 fugu-to-receiver  d-flex align-items-center">
                    <span>
                      <input
                        name="receiverAddress"
                        type="text"
                        placeholder={`${inputPlaceHolder}`}
                        value={receiverAddress}
                        onChange={(e) => handleInputChange(e)}
                      />
                    </span>
                    {receiversTypeSelection.name === "Twitter" && <span className="ms-1 text-white">@x.com</span>}
                  </div>
                  <div className="col-lg-auto col-12 mt-3">
                    <button
                      className="fugu--btn btn-sm bg-white"
                      onClick={() => addManualReceiver()}
                    >
                      {t("dashboard.tabs.myCollections.addReveiver")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mb-4 mt-5">
                <div>
                  <div>
                    {receiversTypeSelection.name == "Email" && (
                      <div className="d-flex align-items-center justify-content-center mb-3 text-uppercase">
                        <Checkbox
                          className=" text-warning-600"
                          checked={sendEmails}
                          onChange={(e) => setSendEmails(e.target.checked)}
                        />
                        <p
                          className={`${!sendEmails ? "text-gray" : "text-warning-600"
                            }`}
                        >
                          {t("dashboard.tabs.myCollections.sendEmail")}
                        </p>
                      </div>
                    )}
                  </div>

                  {receiversTypeSelection.name == "Email" &&
                    sendCustomEmails &&
                    !sendEmails && (
                      <p
                        className="text-warning-600 fugu-inline-btn w-100 text-center mouse-cursor d-flex justify-content-center"
                        onClick={() =>
                          window.open(
                            `mailto:${receiversAddressArr.join(",")}?subject=${recommendedMailFormats.subject
                            }&body=${recommendedMailFormats.body}`
                          )
                        }
                      >
                        Send your custom emails
                      </p>
                    )}

                  <div className="d-flex justify-content-center">
                    <button
                      className="fugu--outline-btn"
                      onClick={() => mint()}
                    >
                      <span>{t("dashboard.tabs.myCollections.minteNfts")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MintManager;
