import React, { useState } from 'react';
import CollectionImageAndProps from '../../components/collections-manager/collection-props';
import CollectionTraits from '../../components/collections-manager/collection-traits';
import { validateCollectionProps } from '../../components/collections-manager/create-collection/collection-ui-utils';
import { useAuth } from '../../components/web3/context/AuthContext';
import { createCollection } from '../../components/collections-manager/create-collection/collection-ui-utils';
import BackButton from '../../components/common/backButton';
import ButtonLoader from '../../components/common/preloader/buttonLoader';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Preloader from '../../components/common/preloader/preloader';
import { useTranslation } from 'react-i18next';
function SingleAiGenerated() {
  const { t } = useTranslation()
  let initialCollectionProps = {
    name: '',
    symbol: '',
    nftCount: '',
    image: null,
  };
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [collectionTraits, setCollectionTraits] = useState([]);
  const [collectionProps, setCollectionProps] = useState(
    initialCollectionProps
  );
  const {
    isLoggedIn,
    loginOption,
    hasPassword,
    network,
    balance,
    entityInfo,
    email,
  } = useAuth();
  const createSingleCollection = async () => {
    try {
      if (!validateCollectionProps(collectionProps, collectionTraits)) return;
      await createCollection({
        balance,
        isLoggedIn,
        loginOption,
        hasPassword,
        network,
        collectionProps,
        collectionTraits,
        isGeneratedImageLayers: false,
        isGeneratedImageAILayersHasURI: false,
        isGeneratedImageAILayers: false,
        confirmedURI: '',
        email,
        entityInfo,
        setLoading,
      });
      setLoading(false);
      navigate('/transferDashboard?selectedTab=Collections');
    } catch (e) {
      toast.error(e.message);
    }
  };
  const getTraits = (traits) => {
    console.log(traits);
    setCollectionTraits(traits);
  };
  const getCollectionProps = (props) => {
    setCollectionProps(props);
  };
  return (
    <div className="fugu--hero-section container">
      <BackButton selectedTab={'CreateNewCollection'} />
      <h1 className="text-center text-white">{t("dashboard.tabs.createCollection.generateAIImageCollection")}</h1>
      <Preloader show={loading} />
      <div className="fugu--blog-sidebar-section m-5 pb-5">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <CollectionImageAndProps
                passCollectionProps={getCollectionProps}
                aiGeneration={true}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <CollectionTraits passTraits={getTraits} />
          </div>
        </div>
        {collectionProps.image && (
          <div className="row mb-5">
            <div className="col-auto">
              <button
                className="fugu--outline-btn"
                onClick={createSingleCollection}
                disabled={loading}
              >
                <span>{t("dashboard.tabs.createCollection.mintCollection")}</span>
                {loading && <ButtonLoader />}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleAiGenerated;
