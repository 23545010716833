import React, { useState, useEffect } from "react";
import { Modal, Form, CloseButton } from "react-bootstrap";
import { ethers } from "ethers";
import { toast } from "react-hot-toast";
import { Radio, Tooltip } from "@mui/material";
import { useAuth } from "../web3/context/AuthContext";
import { switchDefaultNetworkAddress } from "../../api/defaultNetworkWallet";
import binanceIcon from "../../assets/images/networks-icons/binance.png";
import etherIcon from "../../assets/images/networks-icons/eth.png";
import polygonIcon from "../../assets/images/networks-icons/polygon.png";
import telosIcon from "../../assets/images/networks-icons/telos.png";
import flareIcon from "../../assets/images/networks-icons/flare.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
export default function LinkWallet({ show, handleCloseParent }) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(show);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { entityInfo, email, loginProvider, setIsLoggedIn, network } =
    useAuth();
  const [isValidKey, setIsValidKey] = useState(true);
  const [checked, setChecked] = useState(false);
  const [userAddress, setUserAddress] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState();
  const [selectedAddress, setSelectedAddress] = useState([]);
  const ChainIconsStruct = {
    MUMBAI: polygonIcon,
    ETHEREUM_TESTNET: etherIcon,
    BSC_TESTNET: binanceIcon,
    TELOS_TESTNET: telosIcon,
    FLARE_TESTNET: flareIcon,
  };

  //handle closing modal
  const handleClose = () => {
    setLoading(false);
    setShowModal(false);
    setInputValue("");
    handleCloseParent();
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setIsValidKey(validatePublicAddress(event.target.value));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    linkWallet();
  };

  const linkWallet = async () => {
    let chain = network.chain;
    if (isValidKey || inputValue != "") {
      setLoading(true);
      try {
        //Note: this func responsible to set this wallet as a default receiver associated to the current network
        let change = await switchDefaultNetworkAddress(
          email,
          inputValue,
          false,
          chain
        );
        if (change.data) {
          const updatedUserWalletsData = {
            ...entityInfo.userWalletsData,
            EVM: {
              ...entityInfo.userWalletsData["EVM"],
              [chain]: {
                ...entityInfo.userWalletsData["EVM"][chain],
                receivingAddress: inputValue,
              },
            },
          };
          setIsLoggedIn(
            true,
            inputValue,
            "custodial",
            loginProvider,
            entityInfo.indexWallet,
            updatedUserWalletsData
          );
          setSelectedAddress(entityInfo.userWalletsData["EVM"]);
          toast.success(
            `Recommended Wallet to ${network.chain} is set successfully`
          );
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Change Recommended Wallet is failed");
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  function validatePublicAddress(address) {
    if (!ethers.utils.isAddress(address)) {
      return false;
    }
    return true;
  }
  useEffect(() => {
    if (network) {
      setSelectedNetwork(network.chain);
      if (network.chain == "EVM") {
        if (entityInfo.userWalletsData && entityInfo.userWalletsData["EVM"]) {
          console.log(entityInfo.userWalletsData["EVM"]);
          let wallets = entityInfo.userWalletsData["EVM"].wallets;
          setSelectedAddress(entityInfo.userWalletsData["EVM"]);

          setUserAddress(wallets);
        }
      }
    }
  }, [network, entityInfo]);

  const setupSelectedWalletsInChains = () => {
    const walletElements = [];
    for (const key in selectedAddress) {
      if (selectedAddress.hasOwnProperty(key) && key in ChainIconsStruct) {
        const receiverWallet = selectedAddress[key].receivingAddress;
        const walletElement = (
          <div className="d-flex justify-content-center" key={key}>
            <img
              src={ChainIconsStruct[key]}
              className="fugu-network-icons m-1"
            />
            <div className="drop-trigger d-flex align-items-center ms-3">
              {receiverWallet}
            </div>
            {/* <div
                                    className={`ml-3 fugu-remove-icon col-2 text-danger-500 ustify-content-center align-items-center d-flex`}
                                    onClick={() => console.log("remove this wallet from this network", key)}
                                >
                                    <FontAwesomeIcon icon={faClose} />
                                </div> */}
          </div>
        );
        walletElements.push(walletElement);
      }
    }
    return walletElements;
  };

  const setRecommendedWalletWithNetwork = async (walletAddress) => {
    try {
      let change = await switchDefaultNetworkAddress(
        email,
        walletAddress.publicKey,
        false,
        selectedNetwork
      );
      let chain = selectedNetwork;
      if (change.data) {
        const updatedUserWalletsData = {
          ...entityInfo.userWalletsData,
          EVM: {
            ...entityInfo.userWalletsData["EVM"],
            [chain]: {
              ...entityInfo.userWalletsData["EVM"][chain],
              receivingAddress: walletAddress.publicKey,
            },
          },
        };
        setIsLoggedIn(
          true,
          walletAddress.publicKey,
          "custodial",
          loginProvider,
          walletAddress.index,
          updatedUserWalletsData
        );
        setSelectedAddress(entityInfo.userWalletsData["EVM"]);
        toast.success("Recommended Wallet is set successfully");
      } else {
        toast.error("Change Recommended Wallet is failed");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="password-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("header.manageWallet")}
        </Modal.Title>
        <CloseButton
          className="btn-close-white"
          onClick={handleClose}
          style={{ position: "absolute", right: "20px", top: "20px" }}
        />
      </Modal.Header>
      <Modal.Body>
        <p className="text-gray d-flex justify-content-center align-items-center">
          {t("header.uploadPreferredWallet")}
        </p>
        <Form onSubmit={handleSubmit}>
          <Form.Group
            controlId="privateKey"
            className="text-gray d-flex justify-content-center"
          >
            <Form.Control
              type="default"
              placeholder={t(`header.walletPublicKey`)}
              value={inputValue}
              onChange={handleInputChange}
              disabled={loading}
            />
            <div className="ms-3">
              <button
                className="fugu--outline-btn"
                disabled={loading || !isValidKey || inputValue == ""}
              >
                {loading && (
                  <div className="fugu-button-spinner">
                    <svg
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="50" cy="50" r="46" />
                    </svg>
                  </div>
                )}
                <span>{t("horuspassmodalEmail.submit")}</span>
              </button>
            </div>
          </Form.Group>
          {!isValidKey && inputValue != "" && (
            <p className="fugu-error-text p1-1 mb-0">Invalid public key</p>
          )}
          <br />
        </Form>
        {Object.keys(selectedAddress).length !== 0 && (
          <>
            <p className="text-gray d-flex justify-content-center align-items-center">
              {t("header.selectedReceiverWallets")}

            </p>
            {setupSelectedWalletsInChains()}
          </>
        )}
        {/* <div className="d-flex justify-content-center">
                    <img src={polygonIcon} className="fugu-network-icons m-3" onClick={() => setSelectedNetwork("MUMBAI")} />
                    <img src={etherIcon} className="fugu-network-icons m-3" onClick={() => setSelectedNetwork("ETHEREUM_TESTNET")} />
                    <img src={binanceIcon} className="fugu-network-icons m-3" onClick={() => setSelectedNetwork("BSC_TESTNET")} />
                </div>
                <div className="d-flex justify-content-center text-warning-500">
                    <p>{selectedNetwork}</p>
                </div> 
                {userAddress && userAddress.length && (
                    userAddress.map((address, index) =>
                        <div className="d-flex justify-content-center" key={index}>
                            {/* <div>
                                    <Tooltip
                                        placement="top"
                                        title={"Make this wallet the default receiver"}
                                    >
                                        <Radio
                                            checked={checked}
                                            onChange={(e) => {
                                                setRecommendedWalletWithNetwork(address)
                                            }
                                            }
                                            name="default-wallet"
                                            color="primary"
                                            inputProps={{ "aria-label": "select default wallet" }}
                                        />
                                    </Tooltip>
                                </div> 
                            <div>
                                <Tooltip
                                    placement="top"
                                    title={"Make this wallet the default receiver"}
                                >
                                    <div className="drop-trigger d-flex align-items-center ms-3" onClick={() => setRecommendedWalletWithNetwork(address)}>
                                        {address.publicKey}
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    )
                )} */}
      </Modal.Body>
    </Modal>
  );
}
