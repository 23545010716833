import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function CollectionTraits({ passTraits }) {
  const { t } = useTranslation();
  const [collectionTraits, setCollectionTraits] = useState([]);

  const [newTrait, setNewTrait] = useState({
    traitKey: '',
    traitValue: '',
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setNewTrait((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addTrait = () => {
    if (newTrait.traitKey.trim() === '' || newTrait.traitValue.trim() === '') {
      return;
    }
    const updatedTraits = [...collectionTraits, newTrait];

    setCollectionTraits(updatedTraits);
    passTraits(updatedTraits);
    setNewTrait({
      traitKey: '',
      traitValue: '',
    });
  };
  const removeTrait = (index) => {
    const updatedTraits = [...collectionTraits];
    updatedTraits.splice(index, 1);
    setCollectionTraits(updatedTraits);
    passTraits(updatedTraits);
  };

  return (
    <div className="fugu--blog-sidebar mb-4">
      <div className="fugu--blog-sidebar-item px-4">
        <h4>{t("dashboard.tabs.createCollection.addCollectionTraits")}</h4>
        <div className="row">
          <div className="col-lg-6 mb-lg-0 mb-3">
            <input
              type="text"
              placeholder={t("dashboard.tabs.createCollection.key")}
              className="text-gray-700"
              name="traitKey"
              value={newTrait.traitKey}
              onChange={handleChange}
            />
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              placeholder={t("dashboard.tabs.createCollection.value")}
              className="text-gray-700"
              name="traitValue"
              value={newTrait.traitValue}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="fugu--card-footer mb-4">
          <button className="fugu--btn btn-sm bg-white" onClick={addTrait}>
            {t("dashboard.tabs.createCollection.add")}
          </button>
        </div>
        <div className="fugu--tags fugu--tags2">
          <ul>
            {collectionTraits.map((trait, index) => (
              <li key={index}>
                <button
                  className="position-absolute"
                  onClick={() => removeTrait(index)}
                  style={{ zIndex: 100 }}
                >
                  <FontAwesomeIcon icon={faTimes} color="#fff" />
                </button>
                <div>
                  <p className="text-uppercase font-weight-bold">
                    {trait.traitKey}
                  </p>
                  <p>{trait.traitValue}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <div className="fugu--blog-sidebar">
                <div className="fugu--blog-sidebar-item">
                    <h4>Latest Minted Collections:</h4>
                    <div className="fugu--blog-post-wrap">
                        <div className="fugu--blog-post-thumb">
                            <Link href={"#"}>
                                <img src={require("../../../assets/images/all-img/blog2/dark/blog1.png")} alt="" />
                            </Link>
                        </div>
                        <div className="fugu--blog-post-data">
                            <Link href={"#"}>
                                <p>July 27, 2022</p>
                            </Link>
                            <Link href={"#"}>
                                <h5>We’ve valued one of the biggest NFT portfolios in the world!</h5>
                            </Link>
                        </div>
                    </div>
                    <div className="fugu--blog-post-wrap">
                        <div className="fugu--blog-post-thumb">
                            <Link href={"#"}>
                                <img src={require("../../../assets/images/all-img/blog2/dark/blog2.png")} alt="" />
                            </Link>
                        </div>
                        <div className="fugu--blog-post-data">
                            <Link href={"#"}>
                                <p>July 27, 2022</p>
                            </Link>
                            <Link href={"#"}>
                                <h5>One global or several tiny NFT communities? Traders version</h5>
                            </Link>
                        </div>
                    </div>
                    <div className="fugu--blog-post-wrap">
                        <div className="fugu--blog-post-thumb">
                            <Link href={"#"}>
                                <img src={require("../../../assets/images/all-img/blog2/dark/blog3.png")} alt="" />
                            </Link>
                        </div>
                        <div className="fugu--blog-post-data">
                            <Link href={"#"}>
                                <p>July 27, 2022</p>
                            </Link>
                            <Link href={"#"}>
                                <h5>Always be on time on your metaverse event with Dwiss!</h5>
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
}

export default CollectionTraits;
