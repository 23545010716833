import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import ar_flag from '../../assets/images/languages/egypt-flag.png';
import en_flag from '../../assets/images/languages/usa-flag.png';
import sp_flag from '../../assets/images/languages/spanish.svg';
import gr_flag from '../../assets/images/languages/german.png';
import ru_flag from '../../assets/images/languages/russian.png';
import { useMultiLanguage } from '../../MultiLangContext/useMultiLanguage';
const { Option, SingleValue } = components;
const languageOptions = [
  { value: 'en', label: 'English', icon: en_flag },
  { value: 'ar', label: 'العربية', icon: ar_flag },
  { value: 'sp', label: 'Spanish', icon: sp_flag },
  { value: 'gr', label: 'German', icon: gr_flag },
  { value: 'ru', label: 'Russian', icon: ru_flag },

  // Add more languages as needed
];
function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const lang = localStorage.getItem("selectedLanguage");
  const selectedLang = lang ? languageOptions.find(({ value }) => lang == value) : languageOptions[0]
  const { setLanguage, language } = useMultiLanguage();
  const [selectedLanguage, setSelectedLanglage] = useState(selectedLang);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? '#fff' : '#000',
      padding: 10,
    }),
    menu: (provided) => ({
      ...provided,
      width: 250, // Set your desired width here
    }),
    control: () => ({
      display: 'flex',
      alignItems: 'center',
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      width: '50px'
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
    }),
  };
  const customSingleValue = (props) => (
    <SingleValue {...props}>
      <div className=" d-flex align-items-center text-white">
        <img
          src={selectedLanguage.icon}
          alt="s-logo"
          className={`selected-logo  `}
          width={40} />
        {/* {props.children} */}
      </div>
    </SingleValue>
  );
  const customOption = (props) => (
    <Option {...props}>
      <div className=" d-flex align-items-center">
        <img
          src={props.data.icon}
          alt={props.data.label}
          className={`selected-logo ${language === 'ar' ? 'ml-3' : 'mr-3'
            }`}
          width={40} />
        {props.data.label}
      </div>
    </Option>
  );
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng.value);
    setSelectedLanglage(lng);
    setLanguage(lng.value);
  };

  return (
    <div className='language-ddl'>
      <Select
        value={selectedLanguage}
        onChange={(e) => changeLanguage(e)}
        options={languageOptions}
        styles={customStyles}
        components={{ SingleValue: customSingleValue, Option: customOption }}
        isMulti={false}
        isSearchable={false}
      />
    </div>
  );
}

export default LanguageSwitcher;
