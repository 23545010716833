import React, { useEffect, useState, useCallback } from "react";
import { PhoneInput } from "react-international-phone";
import {
  faAngleDown,
  faRefresh,
  faSpinner,
  faClose,
  faUpload,
  faCancel,
  faEdit,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownMenu from "../../components/layout/navbar/dropdown-menu";
import TokensTable from "./tokensTable";
import { useAuth } from "../../components/web3/context/AuthContext";
import { phone } from "phone";
import { useAccount, useChainId } from "wagmi";
import { useNavigate } from "react-router-dom";
import { transferSelectedMethod } from "../../components/layout/navbar/menu-data";
import {
  depositToken,
  transferByWallet,
  transferByEmail,
  depositByEmail,
} from "../../components/web3/ERC20-utils/proxy-payment/ProxyPayment_Utils";
import { openPasswordModel } from "../../components/social-wallet/PasswordModal";
import ButtonLoader from "../../components/common/preloader/buttonLoader";
import { toast } from "react-hot-toast";
import WalletTooltip from "../../components/social-wallet/tooltip";
import { Tooltip } from "@mui/material";
import {
  handleCSVUpload,
  convertDateToUnix,
  isValidWalletAddress,
} from "../../helper/helperFunc";
import DatePicker from "react-datepicker";
import {
  scheduleNonCustodial,
  scheduleCustodial,
  withdrawRefundNonCustodial,
} from "../../components/web3/scheduler-utils/scheduler-utils";
import CustomCalenderInput from "../../components/customCalender";
import QRScannerModal from "../../helper/qrcodeModal";
import { convertToSeconds } from "../../helper/helperFunc";
import { isValidCasperWallet } from "../../helper/helperFunc";
import { stringify } from "viem";
import {
  casperBatchTransfer,
  casperTokenDeposit,
} from "../web3/CASPER-utils/casper-proxy-payment";
import { ChainsObjectsArr } from "../../chainsStaticObject/chainsStaticData";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { useMultiLanguage } from "../../MultiLangContext/useMultiLanguage";
import { Spinner } from "react-bootstrap";

const { Option, SingleValue } = components;
const InitialInputs = () => ({
  inputs: {
    amount: "",
    receiverAddress: "",
    numberExecution: 1,
    numberRepeating: 0,
  },
});

function TokensExchange() {
  const { t } = useTranslation();
  const transferSelectedMethodOptions = transferSelectedMethod.map(
    (option) => ({
      ...option,
      value: option.name,
      label: t(option.title),
    })
  );
  const { language } = useMultiLanguage();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(
    transferSelectedMethod[0]
  );
  const { isConnected } = useAccount();

  const [scannedData, setScannedData] = useState("");
  const [state, setState] = useState(InitialInputs());
  const [UserBalance, setUserBalance] = useState();

  const [loading, setLoading] = useState(false);
  const {
    balance,
    selectedPaymentMethod,
    network,
    loginOption,
    email,
    hasPassword,
    isLoggedIn,
    entityInfo,
    isLoading,
    refreshBalance,
    refreshDepositBalance,
    setPaymentMethod,
    supportedOptions,
  } = useAuth();
  const { address } = useAccount();
  const [isMaxActive, setIsMaxActive] = useState(false);
  const [tokenData, setTokenData] = useState("");
  const chainId = useChainId();

  const [loadingBalance, setLoadingBalance] = useState(false);
  const [showPaymentMenu, setShowPaymentMenu] = useState(false);

  //set only one constant to sort the receivers and validate inputs depending
  //on the selected transfer method
  const [receiversArr, setReceiversArr] = useState([]);
  const [showQrCodeScanner, setShowQrCodeScanner] = useState(false);
  const [isValidInput, setInvalidInput] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isScheduleTransaction, setIsScheduleTransaction] = useState(false);
  const [days, setDays] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [inputKey, setInputKey] = useState(Date.now()); // Unique key
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#000",
      padding: 10,
    }),
    menu: (provided) => ({
      ...provided,
      width: isSmallScreen ? "auto" : 250, // Set your desired width here
      boxShadow: "-2px 2px 70px -25px rgb(0 0 0 / 30%)",
      borderRadius: "6px",
      background: "rgba(225, 225, 225, 0.5)",
    }),
    menuList: (provided) => ({
      ...provided,
      overflowY: "scroll",
      scrollbarGutter: "stable",
      "::-webkit-scrollbar": {
        width: "8px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#555",
      },
      "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#504f4f",
      },
    }),
    control: () => ({
      display: "flex",
      alignItems: "center",
      overflow: "visible",
    }),
    singleValue: (provided) => ({
      ...provided,
      width: "50px",
      overflow: "visible",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      overflow: "visible",
    }),
  };
  const customSingleValue = (props) => (
    <SingleValue {...props}>
      <img
        src={selectedMethod.icon}
        alt="s-logo"
        className={`selected-logo  `}
        width={40}
      />
      {/* {props.children} */}
    </SingleValue>
  );
  const customOption = (props) => (
    <Option {...props}>
      <div className="d-flex align-items-center">
        <img
          src={props.data.icon}
          alt={props.data.title}
          className={`selected-logo ${language === "ar" ? "ml-3" : "mr-3"}`}
          width={40}
        />{" "}
        {t(props.data.toolTipText)}
      </div>
    </Option>
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setIsScheduleTransaction(true);
  };
  const handleCloseModal = () => {
    setShowQrCodeScanner(false);
  };

  const handleDataScanned = (data) => {
    setScannedData(data);
    console.log(data, "Scanned data");
    //handleCloseModal();
  };
  const navigate = useNavigate();

  const handleChange = async (e) => {
    const { value, name } = e.target;
    const { inputs } = state;
    if (name == "amount") {
      setIsMaxActive(false);
      const pendingReceivers = detectAndValidatePendingReceiversInInput();
      const receivingArr = [...receiversArr, ...pendingReceivers];
      const enoughBalance = checkBalance(receivingArr, value);
      inputs[name] = !enoughBalance ? "" : value;
    } else {
      if (name == "receiverAddress") {
        //validate user input one by one
        let validInput;
        inputs["amount"] = ""; // reset amount value in start edit receivers arr
        if (selectedPaymentMethod.name == "Email") {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          validInput = emailRegex.test(value);
        } else if (selectedPaymentMethod.name == "Wallet") {
          validInput = network?.network.isValidAddress(value);
        } else if (selectedPaymentMethod.name == "Phone") {
          validInput = phone(value).isValid;
        } else if (selectedPaymentMethod.name == "Twitter") {
          const TwitterRegex = /^(?=.{4,15}$)(?!^\d+$)[A-Za-z0-9_]+$/;
          validInput = TwitterRegex.test(value);
        }
        setInvalidInput(validInput);
      }
      inputs[name] = value;
    }

    setState({
      ...state,
      inputs,
    });
  };
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 922) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (selectedPaymentMethod) {
      setSelectedMethod(selectedPaymentMethod);
    } else {
      setPaymentMethod(transferSelectedMethod[0]);
    }

    state.inputs.receiverAddress = "";
    setReceiversArr([]);
  }, [selectedPaymentMethod, network]);

  useEffect(() => {
    isLoggedIn && balance.toString() != "" ? userBalance() : setUserBalance();
  }, [isLoggedIn, balance, address, network]);

  const userBalance = async () => {
    // Calculate the user balance
    let number = parseFloat(balance);
    let result;

    // Balance formatting logic
    if (number < 0.000001) {
      result = "0";
    } else {
      result = number.toFixed(4); // Limit to 4 decimal places
    }
    // Set the calculated balance
    setUserBalance(result);
  };

  useEffect(() => {
    if (network) {
      //social logins
      setTokenData(network); //set token manually by user selection from dropdown
    } else {
      fetchTokenInfo();
    }
  }, [network, chainId]);

  const selectMaxBalance = () => {
    setState((prevState) => {
      const { inputs } = prevState;
      inputs["amount"] = isMaxActive ? "" : UserBalance?.toString();
      setIsMaxActive(!isMaxActive);
      return {
        ...prevState,
        inputs,
      };
    });
  };
  // metamask user
  const fetchTokenInfo = useCallback(async () => {
    if (isConnected) {
      // const selectedNetwork = chain?.nativeCurrency.symbol;
      const currentNetwork = ChainsObjectsArr.find(
        (item) => item.networkId == chainId
      );
      setTokenData(currentNetwork);
    }
  }, [chainId]);

  //this condition is to handle in case user have an receivers arr
  //and add another bunch of mails and didn't click enter key
  const detectAndValidatePendingReceiversInInput = () => {
    let pendingReceivers = [];
    if (receiversArr.length || state.inputs.receiverAddress != "") {
      if (selectedPaymentMethod.name == "Email") {
        if (state.inputs.receiverAddress != "") {
          pendingReceivers = validateEmails(state.inputs.receiverAddress);
        }
      } else if (selectedPaymentMethod.name == "Phone") {
        if (state.inputs.receiverAddress != "") {
          pendingReceivers = validatePhone(state.inputs.receiverAddress);
        }
      } else if (selectedPaymentMethod.name == "Wallet") {
        if (state.inputs.receiverAddress == "") return pendingReceivers;
        pendingReceivers.push(
          network?.network.validateWalletAddress(state.inputs.receiverAddress)
        );
      } else if (selectedPaymentMethod.name == "Twitter") {
        if (state.inputs.receiverAddress == "") return pendingReceivers;
        pendingReceivers.push({
          receiver: state.inputs.receiverAddress + " @x.com",
          isValid: true,
        });
      }
    }
    return pendingReceivers;
  };

  const handleDeposit = async () => {
    try {
      const time = validateTimeGapBetweenSchedulesTx();
      if (!time && state.inputs.numberExecution > 1 && isScheduleTransaction)
        return; //stop
      if (
        !time &&
        (state.inputs.numberExecution == 0 ||
          state.inputs.numberExecution == "") &&
        isScheduleTransaction
      )
        return; //stop
      const pendingReceivers = detectAndValidatePendingReceiversInInput();
      if (state.inputs.amount == 0) {
        toast.error("Add amount");
        return;
      }
      //total inputs (stateArr+input)
      const receivingArr = [...receiversArr, ...pendingReceivers];
      const acceptedAmount = network.minMumTrAmount;
      if (receivingArr.length == 0) return;
      let balance = checkBalance(receivingArr, state.inputs.amount);
      if (!balance) {
        toast.error("Your balance does not cover your transaction!!");
        return;
      } else if (state.inputs.amount < acceptedAmount) {
        toast.error(
          `Please enter an amount greater than ${acceptedAmount} to proceed with the transfer. Amounts smaller than this are not allowed. Thank you for your understanding!`
        );
        return;
      }
      setLoading(true);
      const unixDate = convertDateToUnix(selectedDate).toString();
      const validReceivers = receivingArr
        .flat()
        .filter((receiver) => receiver.isValid)
        .map((receiver) => receiver.receiver);
      const receiversIds = validReceivers.map((receiver) =>
        receiver?.replace(/\s/g, "")
      );
      if (loginOption == "custodial") {
        if (hasPassword) {
          openPasswordModel(
            handlePassSecureTransfer,
            {
              receivers: receiversIds,
              amount: state.inputs.amount,
              date: unixDate,
            },
            setLoading
          );
        } else {
          if (
            selectedMethod.name == "Email" ||
            selectedMethod.name == "Phone" ||
            selectedMethod.name == "Twitter"
          ) {
            // check isScheduleTransaction and set your func and use constant selectedDate
            let successTransfer;

            if (isScheduleTransaction) {
              const timeGap = validateTimeGapBetweenSchedulesTx();

              successTransfer = await scheduleCustodial(
                {
                  walletId: email,
                  walletIndex: entityInfo.indexWallet,
                  sender: entityInfo.activePublicKey,
                  recipients: receiversIds,
                  amount: state.inputs.amount,
                  executionTime: parseInt(unixDate),
                  repeatingTime: parseInt(timeGap),
                  numberOfExecutions: parseInt(
                    state.inputs.numberExecution == ""
                      ? 0
                      : state.inputs.numberExecution
                  ),
                  description: prepareScheduleTxDescObj(receiversIds),
                },
                network,
                false
              );
            } else {
              successTransfer = await network?.network.depositToken(
                email,
                entityInfo.activePublicKey,
                entityInfo.indexWallet,
                receiversIds,
                state.inputs.amount.toString(),
                network
              );
            }
            if (successTransfer) {
              //clean input state
              state.inputs.receiverAddress = "";
              refreshBalance();
              setReceiversArr([]);
            }
          } else if (selectedMethod.name == "Wallet") {
            // check isScheduleTransaction and set your func and use constant selectedDate.toLocaleString()
            if (isScheduleTransaction) {
              const timeGap = validateTimeGapBetweenSchedulesTx();
              await scheduleCustodial(
                {
                  walletId: email,
                  walletIndex: entityInfo.indexWallet,
                  sender: entityInfo.activePublicKey,
                  recipients: receiversIds,
                  amount: state.inputs.amount.toString(),
                  executionTime: parseInt(unixDate),
                  repeatingTime: parseInt(timeGap),
                  numberOfExecutions: parseInt(
                    state.inputs.numberExecution == ""
                      ? 0
                      : state.inputs.numberExecution
                  ),
                  description: prepareScheduleTxDescObj(receiversIds),
                },
                network,
                true
              );
            } else {
              await network?.network.transferToken(
                email,
                entityInfo.activePublicKey,
                entityInfo.indexWallet,
                receiversIds,
                state.inputs.amount,
                network
              );
            }
          }
          state.inputs.receiverAddress = "";
          state.inputs.amount = 0;
          setReceiversArr([]);
          refreshBalance();
          refreshDepositBalance();
          setLoading(false);
          setIsScheduleTransaction(false);
        }
      } else if (loginOption == "non_custodial") {
        if (isScheduleTransaction) {
          const timeGap = validateTimeGapBetweenSchedulesTx();
          await scheduleNonCustodial(
            {
              sender: entityInfo.activePublicKey,
              recipients: receiversIds,
              amount: state.inputs.amount,
              executionTime: parseInt(unixDate),
              repeatingTime: parseInt(timeGap),
              numberOfExecutions: parseInt(
                state.inputs.numberExecution == ""
                  ? 0
                  : state.inputs.numberExecution
              ),
              description: prepareScheduleTxDescObj(receiversIds),
            },
            network,
            selectedMethod.name == "Wallet"
          );
        } else {
          if (
            selectedMethod.name == "Email" ||
            selectedMethod.name == "Phone" ||
            selectedMethod.name == "Twitter"
          ) {
            // check isScheduleTransaction and set your func and use constant selectedDate.toLocaleString()
            await depositToken(
              entityInfo.activePublicKey,
              receiversIds,
              state.inputs.amount,
              network
            );
          } else if (selectedMethod.name == "Wallet") {
            // check isScheduleTransaction and set your func and use constant selectedDate.toLocaleString()
            // transfer from wallet to wallet
            await transferByWallet(
              entityInfo.activePublicKey,
              receiversIds,
              network.tokenAddress,
              state.inputs.amount,
              network.chain
            );
          }
        }
        state.inputs.receiverAddress = "";
        state.inputs.amount = 0;
        setReceiversArr([]);
        refreshBalance();
        refreshDepositBalance();
        setLoading(false);
        setIsScheduleTransaction(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const validateTimeGapBetweenSchedulesTx = () => {
    const timeGap = convertToSeconds(parseInt(days), parseInt(minutes));
    if (timeGap == 0 && state.inputs.numberExecution > 1) {
      toast.error("Please add time gap between transaction");
      return;
    } else if (
      state.inputs.numberExecution == "" ||
      state.inputs.numberExecution == 0
    ) {
      toast.error("Please add Number of transaction repetitions");
      return null;
    } else if (
      state.inputs.numberExecution == "" ||
      state.inputs.numberExecution == 1
    ) {
      setDays(0);
      setMinutes(0);
      return 0;
    } else return timeGap;
  };
  const prepareScheduleTxDescObj = (receivers) => {
    //prepare schedule tx description
    const desc = {
      operation: "tx token",
      to: receivers,
      symbol: network?.symbol,
    };
    //pass the json object as a string
    return stringify(desc);
  };

  const handlePassSecureTransfer = async (password, input) => {
    if (password === "" || password == null) {
      setLoading(false);
      toast.error("Password is required");
    } else {
      if (isScheduleTransaction) {
        const unixDate = convertDateToUnix(selectedDate);
        const timeGap = validateTimeGapBetweenSchedulesTx();

        scheduleCustodial(
          {
            walletId: email,
            walletIndex: entityInfo.indexWallet,
            sender: entityInfo.activePublicKey,
            recipients: input.receivers,
            amount: input.amount.toString(),
            executionTime: parseInt(unixDate),
            repeatingTime: parseInt(timeGap),
            numberOfExecutions: parseInt(
              state.inputs.numberExecution == ""
                ? 0
                : state.inputs.numberExecution
            ),
            password: password,
            description: prepareScheduleTxDescObj(input.receivers),
          },
          network,
          selectedMethod.name == "Wallet"
        );
      } else {
        if (
          selectedMethod.name == "Email" ||
          selectedMethod.name == "Phone" ||
          selectedMethod.name == "Twitter"
        ) {
          await network?.network.depositToken(
            email,
            entityInfo.activePublicKey,
            entityInfo.indexWallet,
            input.receivers,
            input.amount,
            network,
            password
          );
          refreshBalance();
        } else if (selectedMethod.name == "Wallet") {
          await network?.network.transferToken(
            email,
            entityInfo.activePublicKey,
            entityInfo.indexWallet,
            input.receivers,
            input.amount,
            network,
            password
          );
          refreshBalance();
        }
      }
    }
    state.inputs.receiverAddress = "";
    state.inputs.amount = 0;
    setReceiversArr([]);
    refreshBalance();
    refreshDepositBalance();
    setLoading(false);
    setIsScheduleTransaction(false);
  };
  //remove one element from the receivers arr
  const removeElement = (index) => {
    const newArr = receiversArr.filter((item, i) => i !== index);
    setReceiversArr(newArr);
  };

  const checkBalance = (receivers, inputAmount) => {
    if (inputAmount == "") {
      return true;
    }
    const amount = receivers.length
      ? parseFloat(inputAmount) * receivers.length
      : parseFloat(inputAmount);
    const balanceNum = parseFloat(balance);
    if (balanceNum >= amount) {
      return true;
    } else {
      toast.error("Insufficient balance");
      return false;
    }
  };
  function validateEmails(emailsString) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emails = emailsString.split(",").map((email) => email.trim());
    const validatedEmails = emails.map((email) => {
      return {
        receiver: email,
        isValid: emailRegex.test(email),
      };
    });
    return validatedEmails;
  }
  function validatePhone(phonesArr) {
    const phones = phonesArr.split(",").map((phoneNum) => phoneNum.trim());
    const validatedPhones = phones.map((phoneNum) => {
      return {
        receiver: phoneNum,
        isValid: phone(phoneNum).isValid,
      };
    });
    return validatedPhones;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value != "") {
        sortReceiversToState(event.target.value);
      }
    }
  };
  // this method responsible to sort and validate input adding  with key enter press
  const sortReceiversToState = (input) => {
    if (!supportedOptions?.Multi_Receivers) return;
    let receivers;
    // validate user text inputs depending on selected method
    //and return arr of object contain receiver and is valid props
    if (selectedPaymentMethod.name == "Email") {
      receivers = validateEmails(input);
    } else if (selectedPaymentMethod.name == "Phone") {
      receivers = validatePhone(input);
    } else if (selectedPaymentMethod.name == "Wallet") {
      receivers = network?.network.validateWalletAddress(input);
    } else if (selectedPaymentMethod.name == "Twitter") {
      receivers = [{ receiver: input + "@x.com", isValid: true }];
    }

    if (receivers.length && receivers[0].isValid) {
      setReceiversArr((receiver) => [...receiver, ...receivers]);
    } else {
      toast.error("Check your inputs and transfer method");
    }
    state.inputs.receiverAddress = "";
  };

  const updateBalance = () => {
    setLoadingBalance(true);
    refreshBalance();
    setTimeout(() => {
      setLoadingBalance(false);
    }, 1500);
  };
  const detectCsvFileData = async (event) => {
    const data = await handleCSVUpload(
      event,
      selectedMethod.name,
      network.chain
    );
    // map in arr to add receiver key
    const modifiedArr = data.map((item) => {
      const keys = Object.keys(item);
      if (keys.length >= 2) {
        const secondPropName = keys[1];
        return { ...item, receiver: item[secondPropName] };
      }
      return item;
    });
    setReceiversArr(modifiedArr);
    setInputKey(Date.now());
  };

  const editScheduler = () => {
    const pendingReceivers = detectAndValidatePendingReceiversInInput();
    const receivingArr = [...receiversArr, ...pendingReceivers];
    if (receivingArr.length == 0) {
      toast.error("Please add receivers");
    } else if (state.inputs.amount == "" || state.inputs.amount == 0) {
      toast.error("Please add Amount");
    } else {
      navigate("/DirectScheduleTransfer", {
        state: {
          transactionDetails: {
            receivers: receivingArr,
            amount: state.inputs.amount,
            network: network,
            selectedDate: selectedDate,
            numberExecution:
              state.inputs.numberExecution == ""
                ? 0
                : state.inputs.numberExecution,
            days: days,
            minutes: minutes,
            selectedMethod: selectedMethod.name,
          },
        },
      });
    }
  };
  return (
    <div>
      <div className="container-fluid mt-3 ">
        <div className="row fugu--dashboard-controller-holder mx-2">
          {/* token name & icon */}
          <div className=" col-xl-1 col-lg-2 col-md-2 col-sm-4 col-4 px-0 d-flex align-items-center justify-content-center my-3">
            <WalletTooltip title={`${tokenData?.symbol} ${t("general.token")}`}>
              <>
                <img src={tokenData?.logo} className="fugu-network-icons" />
                <div className="text-warning-500 ms-1 fugu-limit-line-hight">
                  <span>{tokenData?.symbol}</span>
                </div>
              </>
            </WalletTooltip>
          </div>
          {/* balance & refresh balance & upload csv*/}

          <div className="col-xl-2 col-lg-3 col-md-2 col-sm-8 col-8 justify-content-center align-items-center d-flex fugu-wallet-balance px-0 joyride-fugu-user-balance">
            <WalletTooltip title={t("dashboard.tabs.tokens.refreshBalance")}>
              <div className="col-3">
                <div
                  className={`ml-3 fugu-remove-icon col-2 text-warning-500 `}
                  onClick={() => updateBalance()}
                >
                  <FontAwesomeIcon
                    icon={loadingBalance ? faSpinner : faRefresh}
                  />
                </div>
              </div>
            </WalletTooltip>
            <div className="col-7 joyride-wallet-balance">
              <WalletTooltip title={t("general.balance")}>
                {balance?.toString() == "" ? (
                  <Spinner />
                ) : (
                  <h4 className="d-inline text-white">{UserBalance}</h4>
                )}
              </WalletTooltip>
            </div>
            <div className="col-2 me-1">
              <WalletTooltip
                title={
                  <React.Fragment>
                    {!supportedOptions?.Multi_Receivers ? (
                      `This feature isn't available on ${network?.title}`
                    ) : selectedMethod.name === "Email" ? (
                      <>
                        {t("dashboard.tabs.tokens.uploadCsvEmail")} <br />
                        Example: {selectedMethod.csvSample}
                      </>
                    ) : selectedMethod.name === "Phone" ? (
                      <>
                        {t("dashboard.tabs.tokens.uploadCsvPhone")} <br />
                        Example: {selectedMethod.csvSample}
                      </>
                    ) : selectedMethod.name === "Twitter" ? (
                      <>
                        {t("dashboard.tabs.tokens.uploadCsvTwitter")} <br />
                        Example: {selectedMethod.csvSample}
                      </>
                    ) : (
                      <>
                        {t("dashboard.tabs.tokens.uploadCsvWallet")} <br />
                        Example: {selectedMethod.csvSample}
                      </>
                    )}
                  </React.Fragment>
                }
              >
                <div className="justify-content-center align-items-center ms-2">
                  <label
                    htmlFor="file-input"
                    className="d-block m-1 cursor-pointer text-warning-600"
                  >
                    <FontAwesomeIcon icon={faUpload} />
                  </label>
                  <input
                    type="file"
                    id="file-input"
                    name="file"
                    onChange={detectCsvFileData}
                    key={inputKey}
                    accept=".csv"
                    style={{ display: "none" }}
                    capture="user"
                    disabled={!supportedOptions?.Multi_Receivers}
                  />
                </div>
              </WalletTooltip>
            </div>
          </div>

          {/* receivers inputs && payment method */}

          <div className="col-xl-4 col-lg-7 col-md-8 col-sm-12 col-12 align-self-center pe-1 my-3 d-flex">
            <WalletTooltip title={t("dashboard.tabs.tokens.setReceiver")}>
              <div className="d-flex fugu-recievers-inputs joyride-fugu-user-receivers-input">
                {selectedMethod.name == "Phone" ? (
                  <span className="w-100">
                    <PhoneInput
                      name="receiverAddress"
                      type="number"
                      placeholder={t("general.enterPhoneNumber")}
                      onChange={(value) =>
                        handleChange({
                          target: { name: "receiverAddress", value },
                        })
                      }
                      value={state.inputs.receiverAddress}
                      id="toPhone"
                      onKeyDown={handleKeyDown}
                    />
                  </span>
                ) : (
                  <div className="fugu-to-receiver d-flex align-items-center">
                    <span
                      className={`${
                        selectedMethod.name !== "Twitter" && "w-100"
                      }`}
                    >
                      <input
                        autoComplete="off"
                        name="receiverAddress"
                        type="text"
                        placeholder={t("general.to")}
                        value={state.inputs.receiverAddress}
                        onChange={(e) => handleChange(e)}
                        id="to"
                        onKeyDown={handleKeyDown}
                        className={isValidInput ? "white" : "text-danger-500"}
                      />
                    </span>
                    {selectedMethod.name == "Twitter" && (
                      <span className="ms-1 text-white">@x.com</span>
                    )}
                  </div>
                )}
              </div>
            </WalletTooltip>
            {/* payment method */}

            <div className="ps-1 justify-content-center align-items-center d-flex">
              <WalletTooltip title={t("dashboard.tabs.tokens.choosePayment")}>
                <div className="d-flex">
                  <Select
                    value={selectedMethod}
                    onChange={(e) => {
                      setSelectedMethod(e);
                      setPaymentMethod(e);
                      state.inputs.receiverAddress = "";
                      setReceiversArr([]);
                    }}
                    options={transferSelectedMethodOptions}
                    styles={customStyles}
                    components={{
                      SingleValue: customSingleValue,
                      Option: customOption,
                    }}
                    isMulti={false}
                    isSearchable={false}
                    isOptionDisabled={(option) => option.disabled}
                  />
                </div>
              </WalletTooltip>
            </div>
          </div>

          {/* max btn && amount input && qrcode*/}

          <div className="col-xl-3 col-lg-8 col-md-12 col-sm-12 align-self-center  my-3">
            <div className="row align-items-center">
              <WalletTooltip title={t("general.payMaxBalance")}>
                <div className="col-2 pe-2 d-flex justify-content-center">
                  <button
                    className={`fugu-max-btn ${isMaxActive ? "active" : ""}`}
                    onClick={() => selectMaxBalance()}
                    disabled={receiversArr.length > 1}
                  >
                    <span>{t("general.max")}</span>
                  </button>
                </div>
              </WalletTooltip>
              <div className=" col-8 fugu-transfer-amount-box ps-1">
                <WalletTooltip title={t("dashboard.tabs.tokens.setAmount")}>
                  <div className="fugu-to-receiver">
                    <span>
                      <input
                        name="amount"
                        type="number"
                        placeholder={t("general.amount")}
                        value={state.inputs.amount}
                        onChange={(e) => handleChange(e)}
                        id="amount"
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </span>
                  </div>
                </WalletTooltip>
              </div>
              <div
                className={`nav-item bg-gray fugu-wallet-font col-2`}
                onClick={() => setShowQrCodeScanner(!showQrCodeScanner)}
              >
                <WalletTooltip title={t("dashboard.tabs.tokens.scanCode")}>
                  <FontAwesomeIcon
                    icon={faQrcode}
                    style={{ color: "#FFFF", fontSize: 25 }}
                  />
                </WalletTooltip>
              </div>
            </div>
          </div>

          {/* send btn/schedule date-value*/}
          <div className="col-xl-2 col-lg-4 col-md-12 col-sm-12 ">
            <div className="fugu-date-box text-gray-10">
              {isScheduleTransaction && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  onClick={() => setIsScheduleTransaction(false)}
                >
                  <WalletTooltip
                    title={t("dashboard.tabs.tokens.cancelSchedule")}
                  >
                    <FontAwesomeIcon
                      icon={faCancel}
                      className="mx-1 text-danger"
                    />
                  </WalletTooltip>
                  <div>{selectedDate.toLocaleString()}</div>
                </div>
              )}
            </div>
            <div className="d-flex">
              {/* schedule btn */}

              {supportedOptions?.Schedules && (
                <div className="d-flex">
                  <div className="ms-3 d-flex justify-content-center align-items-center joyride-schedule-tx">
                    <div
                      className={`${
                        isScheduleTransaction ? "text-gray-10" : "text-gray"
                      }`}
                    >
                      {/* <WalletTooltip
                        title={
                          "Schedule this transaction, working with wallets only for now"
                        }
                      > */}
                      <Tooltip
                        title="Schedule this transaction."
                        placement="right"
                      >
                        <div className="fugu-date-picker">
                          <DatePicker
                            selected={selectedDate}
                            onChange={(date) => handleDateChange(date)}
                            showTimeSelect
                            timeIntervals={1}
                            dateFormat="Pp"
                            minDate={new Date()}
                            minTime={new Date()}
                            maxTime={new Date(0, 0, 0, 23, 59, 59)}
                            className="mx-5 fugu-date-picker text-gray-10"
                            customInput={
                              <CustomCalenderInput
                                onClick={handleDateChange}
                                value={selectedDate}
                              />
                            }
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              )}
              <div className="fugu-user-balance fugu-send-payment-btn">
                <button
                  type="submit"
                  id="fugu--submit-btn"
                  className="fugu-send-payment ms-1"
                  onClick={handleDeposit}
                  disabled={
                    (state.inputs.receiverAddress == "" &&
                      receiversArr.length == 0) ||
                    loading ||
                    state.inputs.amount == "" ||
                    state.inputs.amount == 0 ||
                    (!isValidInput && receiversArr.length == 0)
                  }
                >
                  <span>{t("dashboard.tabs.tokens.send")}</span>
                  {loading && <ButtonLoader />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {receiversArr && receiversArr.length > 0 && (
        <div className="container">
          <div className="row justify-content-center mt-1">
            <div className="col-md-5">
              <div
                className="p-3 fugu-tokens-table-holder"
                style={{ minWidth: 100 }}
              >
                {receiversArr &&
                  receiversArr?.map((b, index) => (
                    <div className="mb-1 row p-2" key={index}>
                      <div
                        className={`fugu-regular-light-font col-10 overflow-hidden ${
                          b.isValid ? "gray-10" : "text-danger-500"
                        }`}
                      >
                        {/* {selectedMethod.name == "Email" ? b.mail : selectedMethod.name == "Phone" ? b.phone : b.wallet} */}
                        {b.receiver}
                      </div>
                      <div
                        className={`fugu-remove-icon col-2 text-danger-500`}
                        onClick={() => removeElement(index)}
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {isScheduleTransaction && (
        <div className="row p-5">
          <div className="col-lg-6 col-12 ">
            <label className="text-white pb-1 ">
              {t("dashboard.tabs.tokens.numberRepetitions")}
              <input
                className=" text-gray-10 m-2"
                placeholder={t("dashboard.tabs.tokens.numberRepetitions")}
                type="number"
                name="numberExecution"
                onChange={(e) => handleChange(e.target.value < 0 ? 0 : e)}
                value={state.inputs.numberExecution}
                onWheel={(e) => e.target.blur()}
              />
            </label>
          </div>
          <div className="col-lg-6 col-12">
            {state.inputs.numberExecution > 1 && (
              <div className=" d-flex justify-content-around">
                <div>
                  <label className="text-white pb-1 ">
                    {t("general.days")}
                    <input
                      type="number"
                      className="text-gray-10 m-2"
                      value={days}
                      onChange={(e) =>
                        setDays(e.target.value < 0 ? 0 : e.target.value)
                      }
                      onWheel={(e) => e.target.blur()}
                    />
                  </label>
                </div>
                <div>
                  <label className="text-white">
                    {t("general.minutes")}
                    <input
                      className="text-gray-10 m-2"
                      type="number"
                      value={minutes}
                      onChange={(e) =>
                        setMinutes(e.target.value < 0 ? 0 : e.target.value)
                      }
                      onWheel={(e) => e.target.blur()}
                    />
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/* qrcode modal Scanner */}
      <QRScannerModal
        show={showQrCodeScanner}
        handleCloseParent={() => setShowQrCodeScanner(!showQrCodeScanner)}
        onDataScanned={handleDataScanned}
        userWallet={entityInfo.activePublicKey}
      />
      <TokensTable />
    </div>
  );
}

export default TokensExchange;
