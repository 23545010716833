import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../web3/context/AuthContext";
import Preloader from "../common/preloader/preloader";
import BackButton from "../common/backButton";
import Html5QrcodePlugin from "./qr-code-scanner";
import { openPasswordModel } from "../../components/social-wallet/PasswordModal";
import {
  addUsherToCollectionCustodial,
  addUsherToCollectionNonCustodial,
  removeUsherFromCollectionCustodial,
  removeUsherFromCollectionNonCustodial,
  scanTokenCustodial,
  scanTokenNonCustodial,
} from "../web3/nfts-utils/nfts-access/NFTsAccess_Utils";
import toast from "react-hot-toast";
import {
  faCopy,
  faPlusCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TOKEN_TYPE } from "../web3/nfts-utils/nfts-access/NFTsAccess_Constants";
import WalletTooltip from "../social-wallet/tooltip";
import QrReader from "./qr-code-scanner";

function NFTAccessDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const passedNFTAccessData = location.state;
  const [NFTAccessData, setNFTAccessData] = useState(passedNFTAccessData);
  const tokenType =
    passedNFTAccessData.tokenType.toString() == TOKEN_TYPE.NATIVE_TOKEN
      ? "NATIVE TOKEN"
      : passedNFTAccessData.tokenType.toString() == TOKEN_TYPE.ERC20
        ? "ERC20"
        : passedNFTAccessData.tokenType.toString() == TOKEN_TYPE.ERC721
          ? "ERC721"
          : "ERC1155";
  const [loading, setLoading] = useState(false);
  const [scansPerformed, setScansPerformed] = useState(
    passedNFTAccessData.scansPerformed.toString()
  );
  const [isAdd, setIsAdd] = useState(false);
  const [newUsher, setNewUsher] = useState("");
  const [data, setData] = React.useState("");
  const [ushers, setUshers] = React.useState(passedNFTAccessData.ushers);
  const [openQr, setOpenQr] = useState(false);
  const {
    isLoggedIn,
    loginOption,
    email,
    balance,
    network,
    hasPassword,
    entityInfo,
  } = useAuth();

  const onNewScanResult = (decodedText, decodedResult) => {
    // handle decoded results here
    setData(decodedText);
    setOpenQr(false);
    console.log(decodedText);
  };

  useEffect(() => {
    if (!isLoggedIn) navigate("/");
  }, [isLoggedIn]);

  useEffect(() => {
    if (!NFTAccessData) navigate("/transferDashboard");
  }, [NFTAccessData]);

  const handleCopy = async (text) => {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };

  const scanToken = async () => {
    try {
      setLoading(true);
      if (loginOption == "custodial") {
        if (hasPassword) {
          openPasswordModel(
            handlePassSecureTransfer,
            {
              tokenId: Number(NFTAccessData.tokenId),
              collectionId: Number(NFTAccessData.id),
              tokenType: NFTAccessData.tokenType.toString(),
              tokenAddress: NFTAccessData.contractAddress,
              userAddress: data,
              usherAddress: entityInfo.activePublicKey,
              setLoading,
            },
            setLoading
          );
        } else {
          const saved = await scanTokenCustodial(
            {
              tokenId: Number(NFTAccessData.tokenId),
              collectionId: Number(NFTAccessData.id),
              tokenType: NFTAccessData.tokenType.toString(),
              tokenAddress: NFTAccessData.contractAddress,
              userAddress: data,
              usherAddress: entityInfo.activePublicKey,
            },
            network.chain,
            email,
            entityInfo.indexWallet,
            ""
          );
          saved && setScansPerformed(Number(scansPerformed) + 1);
        }
      } else if (loginOption == "non_custodial") {
        const saved = await scanTokenNonCustodial(
          {
            tokenId: Number(NFTAccessData.tokenId),
            collectionId: Number(NFTAccessData.id),
            tokenType: NFTAccessData.tokenType.toString(),
            tokenAddress: NFTAccessData.contractAddress,
            userAddress: data,
            usherAddress: entityInfo.activePublicKey,
          },
          network.chain
        );
        saved && setScansPerformed(Number(scansPerformed) + 1);
      }
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };

  const handlePassSecureTransfer = async (password, input) => {
    if (password === "") return toast.error("Password is required");
    if (password == null) return;
    try {
      input.setLoading(true)
      const saved = await scanTokenCustodial(
        {
          tokenId: input.tokenId,
          collectionId: input.collectionId,
          tokenType: input.tokenType,
          tokenAddress: input.tokenAddress,
          userAddress: input.userAddress,
          usherAddress: entityInfo.activePublicKey,
        },
        network.chain,
        email,
        entityInfo.indexWallet,
        password
      );
      saved && setScansPerformed(Number(scansPerformed) + 1);
      input.setLoading(false);
    } catch (e) {
      console.log(e);
      input.setLoading(false);
    }
  };

  const addUsher = async () => {
    try {
      setLoading(true);
      const updatedUshers = [...ushers, newUsher];

      if (newUsher.toLowerCase() === entityInfo.activePublicKey.toLowerCase()) {
        toast.error("You can't Add Your Wallet As Usher");
        setNewUsher("");
        return;
      }
      if (loginOption == "custodial") {
        if (hasPassword) {
          openPasswordModel(
            handlePassAddUsherSecureTransfer,
            {
              collectionId: Number(NFTAccessData.id),
              usherAddress: newUsher,
              setLoading,
            },
            setLoading
          );
        } else {
          const saved = await addUsherToCollectionCustodial(
            {
              collectionId: Number(NFTAccessData.id),
              usherAddress: newUsher,
            },
            network.chain,
            email,
            entityInfo.indexWallet,
            ""
          );
          saved && setUshers(updatedUshers);
          saved && setNewUsher("");
        }
      } else if (loginOption == "non_custodial") {
        const saved = await addUsherToCollectionNonCustodial(
          {
            collectionId: Number(NFTAccessData.id),
            usherAddress: newUsher,
          },
          network.chain
        );
        saved && setUshers(updatedUshers);
        saved && setNewUsher("");
      }

      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };
  const handlePassAddUsherSecureTransfer = async (password, input) => {
    if (password === "") return toast.error("Password is required");
    if (password == null) return;
    try {
      input.setLoading(true)
      const saved = await addUsherToCollectionCustodial(
        {
          collectionId: input.collectionId,
          usherAddress: input.usherAddress,
        },
        network.chain,
        email,
        entityInfo.indexWallet,
        password
      );
      const updatedUshers = [...ushers, newUsher];
      saved && setUshers(updatedUshers);
      saved && setNewUsher("");
      input.setLoading(false);
    } catch (e) {
      console.log(e);
      input.setLoading(false);
    }
  };

  const removeUsher = async (address, index) => {
    try {
      setLoading(true);
      if (loginOption == "custodial") {
        if (hasPassword) {
          openPasswordModel(
            handlePassRemoveUsherSecureTransfer,
            {
              collectionId: Number(NFTAccessData.id),
              usherAddress: address,
              usherIndex: index,
              setLoading,
            },
            setLoading
          );
        } else {
          await removeUsherFromCollectionCustodial(
            {
              collectionId: Number(NFTAccessData.id),
              usherAddress: address,
            },
            network.chain,
            email,
            entityInfo.indexWallet,
            ""
          );
        }
      } else if (loginOption == "non_custodial") {
        await removeUsherFromCollectionNonCustodial(
          {
            collectionId: Number(NFTAccessData.id),
            usherAddress: address,
          },
          network.chain
        );
      }
      const updatedUshers = [...ushers];
      updatedUshers.splice(index, 1);
      setUshers(updatedUshers);
      setLoading(false);
    } catch (e) {
      toast.error(e.message);
      setLoading(false);
    }
  };
  const handlePassRemoveUsherSecureTransfer = async (password, input) => {
    if (password === "") return toast.error("Password is required");
    if (password == null) return;
    try {
      input.setLoading(true)
      await removeUsherFromCollectionCustodial(
        {
          collectionId: input.collectionId,
          usherAddress: input.usherAddress,
        },
        network.chain,
        email,
        entityInfo.indexWallet,
        password
      );
      const updatedUshers = [...ushers];
      updatedUshers.splice(input.usherIndex, 1);
      setUshers(updatedUshers);
      input.setLoading(false);
    } catch (e) {
      console.log(e);
      input.setLoading(false);
    }
  };
  const handleAddUsher = (val) => {
    setNewUsher(val);
  };
  return (
    <>
      <Preloader show={loading} />
      {NFTAccessData && (
        <div className="fugu--portfolio-section fugu--section-padding">
          <BackButton selectedTab={"NFT_Access"} />
          <div className="container mt-5">
            <div className="row">
              <div className="col-xl-6  col-12">
                <div
                  className="fugu--blog-wrap fugu--blog-wrap2 wow fadeInUpX"
                  data-wow-delay="0s"
                >
                  <div className="fugu--blog-content">
                    <div className="fugu--blog-title text-uppercase d-flex justify-content-center ">
                      <div className="d-flex align-items-center">
                        <h3 className="text-warning-600">
                          {NFTAccessData.name}
                        </h3>
                      </div>
                    </div>
                    <div className="fugu--blog-date">
                      <p>
                        {t("dashboard.tabs.nftAccess.admin")}{" "}
                        {NFTAccessData.admin?.slice(0, 5)} ...
                        {NFTAccessData.admin?.slice(
                          NFTAccessData.admin.length - 5
                        )}
                        <span
                          onClick={() => handleCopy(NFTAccessData.admin)}
                          className="cursor-pointer ms-2  mb-0"
                        >
                          <FontAwesomeIcon icon={faCopy} />
                        </span>
                      </p>
                      {TOKEN_TYPE.NATIVE_TOKEN != NFTAccessData.tokenType && (
                        <p>
                          {t("dashboard.tabs.nftAccess.contractAddress")}:{" "}
                          {NFTAccessData.contractAddress?.slice(0, 5)} ...
                          {NFTAccessData.contractAddress?.slice(
                            NFTAccessData.contractAddress.length - 5
                          )}
                          <span
                            onClick={() =>
                              handleCopy(NFTAccessData.contractAddress)
                            }
                            className="cursor-pointer ms-2  mb-0"
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </span>
                        </p>
                      )}
                      <p>
                        {t("dashboard.tabs.nftAccess.tokenType")} {tokenType}
                      </p>
                      <p>
                        {t("dashboard.tabs.nftAccess.maxScanPerUser")}:{" "}
                        {NFTAccessData.maxScanPerUser.toString()}
                      </p>
                      {TOKEN_TYPE.ERC1155 ==
                        NFTAccessData.tokenType.toString() && (
                          <p>
                            {t("dashboard.tabs.nftAccess.tokenId")}:{" "}
                            {NFTAccessData.tokenId.toString()}
                          </p>
                        )}
                      <p>
                        {t("dashboard.tabs.nftAccess.scansPerformed")}{" "}
                        {scansPerformed}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="d-inline-block w-100">
                  <button
                    className="mb-4 fugu--outline-btn bg-white"
                    onClick={() => setOpenQr(!openQr)}
                  >
                    <span>
                      {" "}
                      {openQr
                        ? t("dashboard.tabs.nftAccess.close")
                        : t("dashboard.tabs.nftAccess.open")}{" "}
                      {t("dashboard.tabs.nftAccess.qrScanner")}
                    </span>
                  </button>
                  {openQr && (
                    <QrReader qrCodeSuccessCallback={onNewScanResult} />
                  )}
                  {data && (
                    <div className="row mt-4 align-items-center">
                      <div className="col">
                        <WalletTooltip title={data}>
                          <p className="text-truncate text-white">{data}</p>
                        </WalletTooltip>
                      </div>
                      <div className="col">
                        <button
                          className="fugu--outline-btn"
                          onClick={scanToken}
                          disabled={loading}
                        >
                          <span>{t("dashboard.tabs.nftAccess.scanToken")}</span>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <hr className="my-5" />
            {ushers &&
              NFTAccessData.admin.toLowerCase() ==
              entityInfo.activePublicKey.toLowerCase() && (
                <div className="row mt-4">
                  <div className="col">
                    <h4 className="text-white mb-4">
                      {t("dashboard.tabs.nftAccess.ushers")}
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        color="#fff"
                        className="ms-4"
                        size="lg"
                        onClick={() => setIsAdd(true)}
                      />
                    </h4>
                    {isAdd && (
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            placeholder={t(
                              "dashboard.tabs.nftAccess.usherAddress"
                            )}
                            className="text-gray-700"
                            name="newUsher"
                            value={newUsher}
                            onChange={(e) => handleAddUsher(e.target.value)}
                          />
                        </div>
                        <div className="col-md-auto col-12 mt-2">
                          <button
                            className="fugu--outline-btn bg-white me-2"
                            disabled={
                              !network?.network.isValidAddress(newUsher)
                            }
                            onClick={addUsher}
                          >
                            <span>{t("dashboard.tabs.nftAccess.save")}</span>
                          </button>
                          <button
                            className="fugu--outline-btn"
                            onClick={() => setIsAdd(false)}
                          >
                            <span>{t("dashboard.tabs.nftAccess.cancel")}</span>
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="fugu--tags fugu--tags2 mt-5">
                      <ul>
                        {ushers.map((usher, index) => (
                          <li key={index}>
                            <button
                              className="position-absolute"
                              onClick={() => removeUsher(usher, index)}
                              style={{ zIndex: 100 }}
                            >
                              <FontAwesomeIcon icon={faTimes} color="#fff" />
                            </button>
                            <div>
                              <p className="text-uppercase font-weight-bold">
                                {usher?.slice(0, 5)} ...
                                {usher?.slice(usher.length - 5)}
                                <span
                                  onClick={() => handleCopy(usher)}
                                  className="cursor-pointer ms-2  mb-0"
                                >
                                  <FontAwesomeIcon icon={faCopy} />
                                </span>
                              </p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  );
}

export default NFTAccessDetail;
