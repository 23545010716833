export class BlockchainNetwork {
  chainName: string;
  constructor(chainName: string) {
    this.chainName = chainName;
  }

  getAddress(userWallets: any) {
    throw new Error('getAddress method must be implemented');
  }

  getWallets(userWallets: any, shortenKeyWidth: number) {
    throw new Error('getWallets method must be implemented');
  }

  async getBalance(address: string, network: any): Promise<any> {
    throw new Error('getBalance method must be implemented');
  }

  async getDeposit(email: string, network: string, tokenAddress: string): Promise<any> {
    throw new Error('getDepositBalance method must be implemented');
  }

  async getAllDepositsBalance(email: string, network: string): Promise<any> {
    throw new Error('getAllDepositsBalance method must be implemented');
  }

  isValidAddress(address: string): any {
    throw new Error('isValidAddress method must be implemented');
  }

  validateWalletAddress(addresses: any): any {
    throw new Error('validateWalletAddress method must be implemented');
  }

  async depositToken(email: string, walletAddress: string, walletIndex: number, receiversIds: [string], amount: string, network: string, password: string): Promise<any> {
    throw new Error('depositByEmail method must be implemented');
  }

  async transferToken(email: string, walletAddress: string, walletIndex: number, receiversIds: [string], amount: string, network: string, password: string): Promise<any> {
    throw new Error('transferToken method must be implemented');
  }

  getChainScanUrl() {
    throw new Error('getChainScanUrl method must be implemented');
  }

  getChainScanUrlTx() {
    throw new Error('getChainScanUrlTx method must be implemented');
  }

}
