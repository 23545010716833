import { useState, useEffect } from 'react';
import mintHolder from '../../assets/images/all-img/blog2/dark/blog14.png';
import uploadImgHolder from '../../assets/images/collections/uploadImage.png';
import SingleGenerateAi from './create-collection/single-ai-generate-component';
import { useTranslation } from 'react-i18next';
function CollectionImageAndProps({
  passCollectionProps,
  aiGeneration = false,
}) {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(mintHolder);

  let initialNftProps = {
    name: '',
    symbol: '',
    nftCount: '',
    image: null,
  };
  const [collectionProps, setCollectionProps] = useState(initialNftProps);
  const [generatedAiImage, setGeneratedAiImage] = useState();
  const [isGeneratedImageByAI, setIsGeneratedImageByAI] = useState(false);
  useEffect(() => {
    //listen to the updated aiGeneration status and update the state
    setIsGeneratedImageByAI(aiGeneration);
    const imgPlaceHolder = aiGeneration ? mintHolder : uploadImgHolder;
    setSelectedImage(imgPlaceHolder);
  }, [aiGeneration]);

  const handleImageUpload = (event) => {
    if (isGeneratedImageByAI) return; // if user coming from the ai generation didn't open the upload client
    setIsGeneratedImageByAI(false);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataUrl = reader.result;
      setSelectedImage(imageDataUrl);
      passCollectionProps((prevNftProps) => ({
        ...prevNftProps,
        image: file,
      }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  function base64ToFile(base64String) {
    try {
      const binaryString = atob(base64String);
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: 'image/png' });

      const fileName = 'image_' + Date.now() + '.png';

      const file = new File([blob], fileName, { type: 'image/png' });

      return file;
    } catch (error) {
      console.error('Error converting Base64 to image:', error);
      return null;
    }
  }
  useEffect(() => {
    if (generatedAiImage) {
      setIsGeneratedImageByAI(true);
      const file = base64ToFile(generatedAiImage);
      passCollectionProps((prevNftProps) => ({
        ...prevNftProps,
        image: file,
      }));
    }
  }, [generatedAiImage]);
  // const fetchNftsJson = async (url) => {
  //   try {
  //     const response = await fetch(url);
  //     if (!response.ok) {
  //       throw new Error('Failed to fetch data');
  //     }
  //     const jsonData = await response.json();
  //     return jsonData.image
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }
  // const fetchGeneratedNftData = async (onGenerateLayers) => {
  //   const { nftUrl, nftCount } = onGenerateLayers
  //   const imag = await fetchNftsJson(`${nftUrl}/0`)
  //   setCollectionProps((prev) => ({
  //     ...prev,
  //     nftCount: nftCount,
  //     image: imag
  //   }));
  //   setSelectedImage(imag)
  // }

  const handleChange = (e) => {
    const { value, name } = e.target;
    setCollectionProps((prevNftProps) => ({
      ...prevNftProps,
      [name]: value,
    }));
    passCollectionProps((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };
  return (
    <div
      className="fugu--blog-wrap fugu--blog-wrap2 wow fadeInUpX"
      data-wow-delay="0s"
    >
      {isGeneratedImageByAI ? (
        <SingleGenerateAi
          setAIGeneratedImage={(image) => {
            setGeneratedAiImage(image);
          }}
        />
      ) : (
        <div className="">
          <label
            htmlFor="image-input"
            className="w-100 d-flex justify-content-center"
          >
            <input
              type="file"
              id="image-input"
              name="image"
              accept=".png, .jpg, .jpeg"
              capture="user"
              style={{ display: 'none' }}
              onChange={handleImageUpload}
              disabled={isGeneratedImageByAI}
            />
            <img
              src={selectedImage}
              className="object-fit-contain"
              style={{ height: 250 }}
              alt=""
            />
          </label>
        </div>
      )}
      {((isGeneratedImageByAI && generatedAiImage) ||
        !isGeneratedImageByAI) && (
          <div className="fugu--blog-content">
            <div className="fugu--blog-title">
              <input
                type="default"
                placeholder={t("dashboard.tabs.createCollection.collectionName")}
                className="text-gray-700"
                name="name"
                value={collectionProps.name}
                onChange={handleChange}
              />
              <div className="row mt-3 ">
                <div className="col-lg-6 mb-lg-0 mb-3">
                  <input
                    type="number"
                    placeholder={t("dashboard.tabs.createCollection.collectionNFTCount")}
                    className="text-gray-700"
                    name="nftCount"
                    value={collectionProps.nftCount}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    type="default"
                    placeholder={t("dashboard.tabs.createCollection.collectionSymbol")}
                    className="text-gray-700"
                    name="symbol"
                    value={collectionProps.symbol}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default CollectionImageAndProps;
