import { createPublicClient, http } from "viem";
import { NETWORK_TO_NETWORK_INSTACNE } from "../../utils/Providers";
import {
  TOKEN_TYPE,
  ERC20_ABI,
  ERC721_ABI,
  ERC1155_ABI,
  NFTS_ACCESS_CONTRACT_ADDRESS,
  NFTS_ACCESS_ABI,
} from "./NFTsAccess_Constants";
import { signTransaction, writeToContractByWagmi } from "../../utils/utils";
import toast from "react-hot-toast";
import { ethers } from "ethers";

async function getTokenBalance(
  network,
  walletAddress,
  tokenAddress,
  tokenType,
  tokenId
) {
  const chain =
    NETWORK_TO_NETWORK_INSTACNE[network][process.env.REACT_APP_NETWORK_TYPE];
  const publicClient = createPublicClient({
    chain,
    transport: http(),
  });

  switch (tokenType) {
    case TOKEN_TYPE.NATIVE_TOKEN:
      return await publicClient.getBalance({
        address: walletAddress,
      });
    case TOKEN_TYPE.ERC20:
      return await publicClient.readContract({
        address: tokenAddress,
        abi: ERC20_ABI,
        functionName: "balanceOf",
        args: [walletAddress],
      });
    case TOKEN_TYPE.ERC721:
      return await publicClient.readContract({
        address: tokenAddress,
        abi: ERC721_ABI,
        functionName: "balanceOf",
        args: [walletAddress],
      });
    case TOKEN_TYPE.ERC1155:
      return await publicClient.readContract({
        address: tokenAddress,
        abi: ERC1155_ABI,
        functionName: "balanceOf",
        args: [walletAddress, tokenId],
      });
    default:
      throw new Error("Invalid token type");
  }
}

export async function getCollectionsByUser(network, walletAddress) {
  const chain =
    NETWORK_TO_NETWORK_INSTACNE[network][process.env.REACT_APP_NETWORK_TYPE];
  const publicClient = createPublicClient({
    chain,
    transport: http(),
  });

  return await publicClient.readContract({
    address:
      NFTS_ACCESS_CONTRACT_ADDRESS[network][process.env.REACT_APP_NETWORK_TYPE],
    abi: NFTS_ACCESS_ABI,
    functionName: "getCollectionsByUser",
    args: [walletAddress],
  });
}

export async function createCollectionCustodial(
  params,
  network,
  walletId,
  walletIndex,
  password
) {
  return await signTransaction(
    walletId,
    walletIndex,
    network,
    password,
    [
      {
        contractAddress:
          NFTS_ACCESS_CONTRACT_ADDRESS[network][
            process.env.REACT_APP_NETWORK_TYPE
          ],
        abi: NFTS_ACCESS_ABI,
        functionName: "createCollection",
        params: [
          params.name,
          params.tokenType,
          params.tokenId,
          params.maxScanPerUser,
          params.contractAddress,
          params.ushers,
        ],
        value: ethers.utils.parseEther("0.001"),
      },
    ],
    "Creating Scan Collection"
  );
}

export async function createCollectionNonCustodial(params, network) {
  const networkType = process.env.REACT_APP_NETWORK_TYPE;
  return await writeToContractByWagmi(
    {
      address: NFTS_ACCESS_CONTRACT_ADDRESS[network][networkType],
      abi: NFTS_ACCESS_ABI,
    },
    {
      functionName: "createCollection",
      args: [
        params.name,
        params.tokenType,
        params.tokenId,
        params.maxScanPerUser,
        params.contractAddress,
        params.ushers,
      ],
      value: ethers.utils.parseEther("0.001"),
    },
    network,
    "Creating Scan Collection"
  );
}

export async function removeUsherFromCollectionCustodial(
  params,
  network,
  walletId,
  walletIndex,
  password
) {
  return await signTransaction(
    walletId,
    walletIndex,
    network,
    password,
    [
      {
        contractAddress:
          NFTS_ACCESS_CONTRACT_ADDRESS[network][
            process.env.REACT_APP_NETWORK_TYPE
          ],
        abi: NFTS_ACCESS_ABI,
        functionName: "removeUsherFromCollection",
        params: [params.collectionId, params.usherAddress],
      },
    ],
    "Removing Usher from Collection"
  );
}

export async function removeUsherFromCollectionNonCustodial(params, network) {
  return await writeToContractByWagmi(
    {
      address:
        NFTS_ACCESS_CONTRACT_ADDRESS[network][
          process.env.REACT_APP_NETWORK_TYPE
        ],
      abi: NFTS_ACCESS_ABI,
    },
    {
      functionName: "removeUsherFromCollection",
      args: [params.collectionId, params.usherAddress],
    },
    network,
    "Removing Usher from Collection"
  );
}

export async function addUsherToCollectionCustodial(
  params,
  network,
  walletId,
  walletIndex,
  password
) {
  return await signTransaction(
    walletId,
    walletIndex,
    network,
    password,
    [
      {
        contractAddress:
          NFTS_ACCESS_CONTRACT_ADDRESS[network][
            process.env.REACT_APP_NETWORK_TYPE
          ],
        abi: NFTS_ACCESS_ABI,
        functionName: "addUsherToCollection",
        params: [params.collectionId, params.usherAddress],
      },
    ],
    "Adding Usher to Collection"
  );
}

export async function addUsherToCollectionNonCustodial(params, network) {
  return await writeToContractByWagmi(
    {
      address:
        NFTS_ACCESS_CONTRACT_ADDRESS[network][
          process.env.REACT_APP_NETWORK_TYPE
        ],
      abi: NFTS_ACCESS_ABI,
    },
    {
      functionName: "addUsherToCollection",
      args: [params.collectionId, params.usherAddress],
    },
    network,
    "Adding Usher to Collection"
  );
}

export async function scanTokenCustodial(
  params,
  network,
  walletId,
  walletIndex,
  password
) {
  const usherNativeBalance = await getTokenBalance(
    network,
    params.usherAddress,
    null,
    TOKEN_TYPE.NATIVE_TOKEN,
    null
  );

  const networkInstance = await NETWORK_TO_NETWORK_INSTACNE[network][
    process.env.REACT_APP_NETWORK_TYPE
  ];

  const publicClient = createPublicClient({
    chain: networkInstance,
    transport: http(),
  });

  const gasPrice = await publicClient.getGasPrice();

  if (
    usherNativeBalance <
    ethers.BigNumber.from(gasPrice).mul(ethers.BigNumber.from(500000))
  ) {
    toast.error("You don't have enough balance to perform scan");
    return;
  }

  const balance = await getTokenBalance(
    network,
    params.userAddress,
    params.tokenAddress,
    params.tokenType,
    params.tokenId
  );
  if (balance == 0) {
    toast.error("User Doesn't have the token");
    return;
  }

  try {
    return await signTransaction(
      walletId,
      walletIndex,
      network,
      password,
      [
        {
          contractAddress:
            NFTS_ACCESS_CONTRACT_ADDRESS[network][
              process.env.REACT_APP_NETWORK_TYPE
            ],
          abi: NFTS_ACCESS_ABI,
          functionName: "performScan",
          params: [params.collectionId, params.userAddress],
        },
      ],
      "Scanning Token"
    );
  } catch (error) {
    console.log(error);
    toast.error("Max Scan Per User Reached");
  }
}

export async function scanTokenNonCustodial(params, network) {
  const usherNativeBalance = await getTokenBalance(
    network,
    params.usherAddress,
    null,
    TOKEN_TYPE.NATIVE_TOKEN,
    null
  );

  const networkInstance = await NETWORK_TO_NETWORK_INSTACNE[network][
    process.env.REACT_APP_NETWORK_TYPE
  ];

  const publicClient = createPublicClient({
    chain: networkInstance,
    transport: http(),
  });

  const gasPrice = await publicClient.getGasPrice();

  if (
    usherNativeBalance <
    ethers.BigNumber.from(gasPrice).mul(ethers.BigNumber.from(500000))
  ) {
    toast.error("You don't have enough balance to perform scan");
    return;
  }

  const balance = await getTokenBalance(
    network,
    params.userAddress,
    params.tokenAddress,
    params.tokenType,
    params.tokenId
  );

  if (balance == 0) {
    toast.error("User Doesn't have the token");
    return;
  }

  try {
    return await writeToContractByWagmi(
      {
        address:
          NFTS_ACCESS_CONTRACT_ADDRESS[network][
            process.env.REACT_APP_NETWORK_TYPE
          ],
        abi: NFTS_ACCESS_ABI,
      },
      {
        functionName: "performScan",
        args: [params.collectionId, params.userAddress],
      },
      network,
      "Scanning Token"
    );
  } catch (error) {
    console.log(error);
    toast.error("Max Scan Per User Reached");
  }
}
