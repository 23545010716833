import React, { useState } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import aiApiIssue from '../../../assets/images/collections/aiIssuePlaceholder.png';
import aiplaceholder from '../../../assets/images/collections/aiPlaceholder.jpg';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
function SingleGenerateAi({ setAIGeneratedImage }) {
  const { t } = useTranslation()
  let initialNftProps = {
    firstInput: '',
    secondInput: '',
  };
  const [collectionProps, setCollectionProps] = useState(initialNftProps);
  const [generateClickedSpinner, setGenerateClickedSpinner] = useState(false);
  const [generateClicked, setGenerateClicked] = useState(false);
  const [generatedImage, setGeneratedImage] = useState();
  const [generatedImageForDisplay, setGeneratedImageForDisplay] = useState();
  const [errorInApi, setErrorInAPI] = useState();

  const generateImage = async () => {
    setGenerateClickedSpinner(true);
    setGenerateClicked(true);
    const content = collectionProps.firstInput + collectionProps.secondInput;
    const endpoint = `${process.env.REACT_APP_GENERATE_AI_IMAGE_API}myAiChat`;
    const data = { content, type: 'image' };
    try {
      const response = await axios.post(endpoint, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setGenerateClickedSpinner(false);
      setGeneratedImage(`${response.data.generated_image}`);
      setAIGeneratedImage(response.data.generated_image);
      setGeneratedImageForDisplay(
        `data:image/png;base64,${response.data.generated_image}`
      );
      setErrorInAPI(false);
    } catch (error) {
      toast.error('Error querying the AI bot:', error);
      console.error('Error querying the AI bot:', error);
      setErrorInAPI(true);
      setGenerateClickedSpinner(false);

      return null;
    }
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    setCollectionProps((prevNftProps) => ({
      ...prevNftProps,
      [name]: value,
    }));
  };
  return (
    <div className="fugu--blog-content">
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-5">
            <img
              src={
                errorInApi
                  ? aiApiIssue
                  : generatedImage
                    ? generatedImageForDisplay
                    : aiplaceholder
              }
              alt=""
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="col mb-2">
            <input
              type="default"
              placeholder={t("dashboard.tabs.createCollection.firstInput")}
              className="text-gray-700"
              name="firstInput"
              value={collectionProps.firstInput}
              onChange={handleChange}
            />
          </div>
          <div className="col">
            <input
              type="default"
              placeholder={t("dashboard.tabs.createCollection.secondInput")}
              className="text-gray-700"
              name="secondInput"
              value={collectionProps.secondInput}
              onChange={handleChange}
            />
          </div>
          <div className="mt-3 ">
            <button
              className="fugu--outline-btn"
              onClick={() => {
                generateImage();
              }}
            >
              <span>
                {generateClickedSpinner ? (
                  <Spinner />
                ) : generateClicked ? (
                  t("dashboard.tabs.createCollection.regenerate")
                ) : (
                  t("dashboard.tabs.createCollection.generate")
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleGenerateAi;
