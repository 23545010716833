import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { Modal, Form, CloseButton } from "react-bootstrap";
import { toast } from "react-hot-toast";
import * as EmailValidator from "email-validator";
import { sendSignInLinkToEmail } from "firebase/auth";
import { getAuthConfig } from "./firebase";
import { useTranslation } from "react-i18next";
export function openEmailModal(freeNft = false, QRNFTURL = undefined) {
  const div = document.createElement("div");
  document.body.appendChild(div);
  const root = createRoot(div);
  const close = () => {
    root.unmount();
    div.remove();
    document.body.style.overflow = "auto";
  };
  root.render(
    <EmailModal
      show={true}
      close={close}
      freeNft={freeNft} QRNFTURL={QRNFTURL}
    />
  );
}

export function EmailModal(props) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [loginError, setLoginError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false)
  const handleInputChange = async (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    if (!EmailValidator.validate(email)) {
      toast.error("Invalid email");
      return;
    }
    event.preventDefault();
    setLoading(true)
    const auth = await getAuthConfig();
    const url = props.freeNft ? props.QRNFTURL : process.env.REACT_APP_SITE_URL
    const actionCodeSettings = {
      url: `${url}`,
      handleCodeInApp: true,
    };
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      localStorage.setItem("email", email);
      toast.success(`We have sent an email to ${email}`)
      setLoading(false)
      setSuccess(true)
    } catch (err) {
      console.log(`Error: ${err}`);
      setLoginError(err.message);
      setLoading(false)
    }
  };

  const handleCancel = () => {
    props.close();
  };

  return (
    <Modal
      show={props.show}
      onHide={handleCancel}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      id="password-modal"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Horus wallet
        </Modal.Title>
        <CloseButton
          onClick={handleCancel}
          style={{ position: "absolute", right: "20px", top: "20px" }}
          className="btn-close-white"
        />
      </Modal.Header>
      <Modal.Body>
        {!success ? (
          <>
            <p>{t("horuspassmodalEmail.enterEmail")}</p>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formPassword">
                <Form.Control
                  type="email"
                  placeholder={t("horuspassmodalEmail.enterEmail")}
                  value={email}
                  onChange={handleInputChange}
                  disabled={loading}
                />
              </Form.Group>
              <br />

              {loginError !== "" && <div>{loginError}</div>}
            </Form>
          </>
        ) : (
          <>
            {success && (
              <div>
                {`we have sent an email to ${email},  if you didn't receive an email click`}
                <button className="fugu-inline-btn ms-2 "
                  onClick={handleSubmit}
                >Resend
                </button>
              </div>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="fugu--portfolio-btn">
          {!success && (
            <button className="fugu--outline-btn" onClick={handleSubmit} disabled={loading}>
              {loading && (
                <div className="fugu-button-spinner">
                  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="50" cy="50" r="46" />
                  </svg>
                </div>
              )}
              <span>{t("horuspassmodalEmail.submit")}</span>
            </button>
          )}
        </div>
        <div className="fugu--portfolio-btn">
          <button className="fugu--outline-btn" onClick={handleCancel}>
            <span>{t("horuspassmodalEmail.close")}</span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
