import axiosToken from "../../social-wallet/AxiosToken";
import CryptoJS from "crypto-js";
import { getEthersProvider } from "./Providers";
import { ethers } from "ethers";
import toast from "react-hot-toast";
import {
  prepareWriteContract,
  writeContract,
  prepareSendTransaction,
  sendTransaction,
  waitForTransaction,
  getNetwork,
  readContract,
} from "@wagmi/core";
import { displayMessageWithLink } from "../../../helper/helperFunc";
import { UniblockAPI } from "../BlockchainAPIs/UniblockAPI";
import { AlchemyAPI } from "../BlockchainAPIs/AlchemyAPI";

const signTransaction = async (
  walletId,
  walletIndex,
  network,
  password,
  txs,
  toastMsg
) => {
  try {
    const networkType = process.env.REACT_APP_NETWORK_TYPE;
    const txPromise = axiosToken.post("signTransaction", {
      walletId,
      walletIndex,
      network,
      networkType,
      password,
      txs,
    });

    console.log("txs ====> ", txs);

    const tx = await toast.promise(txPromise, {
      loading: `${toastMsg}`,
      success: (res) => {
        if (res.data.res.error) {
          throw new Error(res.data.res.error);
        };
        return displayMessageWithLink(
          res.data.res[res.data.res.length - 1].hash,
          toastMsg,
          network
        );
      },
      error: (err) => {
        if (err && err.message) {
          return err.message;
        }
        return `${toastMsg} Failed!`;
      },
    });

    const res = tx.data.res;

    console.log("signature res ====> ", res);

    for (let i = 0; i < res.length; i++) {
      if (tx.data.res[i].status !== true) {
        throw new Error("Transaction failed");
      }
    }

    return tx.data.res[res.length - 1].hash;
  } catch (error) {
    console.log(error);
    // toast.error(`${toastMsg} Failed!`);
    throw error;
  }
};

/**
 * Send a transaction using the Wagmi API
 * @param {string} to - The recipient address
 * @param {number} value - The amount to send
 * @param {string} msg - A message for the transaction
 * @returns {Promise<string>} - A promise that resolves with a success message or rejects with an error
 */
async function sendTransactionByWagmi(to, value, msg) {
  // Get the current chain ID
  const { chain } = getNetwork();
  if (chain === undefined) {
    toast.error("Please connect your Wallet");
    return;
  }
  const chainID = chain.id;

  return new Promise(async (resolve, reject) => {
    // Check if the chain ID is 80001 (Mumbai Testnet)
    if (chainID === 80001) {
      try {
        // Prepare the transaction configuration
        const config = await prepareSendTransaction({
          to,
          value,
        });

        // Send the transaction and get the hash
        const hashPromise = new Promise((resolveHash, rejectHash) => {
          sendTransaction(config)
            .then((result) => {
              resolveHash(result.hash);
            })
            .catch((error) => {
              rejectHash(error);
            });
        });

        // Display a toast with a loading message while waiting for the signature
        toast.promise(hashPromise, {
          loading: "Need your signature",
          success: "Successfully signed the transaction",
          error: "Failed! Please try again",
        });

        // Get the hash of the transaction
        const hash = await hashPromise;

        // Wait for the transaction to be deployed and display a toast with the result
        const deployment = waitForTransaction({ hash });

        toast.promise(deployment, {
          loading: `${msg} In Progress`,
          success: () => {
            resolve(`${msg} Success`);
            return `${msg} Success`;
          },
          error: (err) => {
            reject(err);
            return `${msg} Failed`;
          },
        });
      } catch (e) {
        // Handle any errors that occur during the transaction
        reject(e);
        toast.error("Transaction failed");
      }
    } else {
      // If the chain ID is not 80001, reject the promise and display an error toast
      reject(new Error("Please connect to Mumbai Testnet"));
      toast.error("Please connect to Mumbai Testnet");
    }
  });
}

/**
 * Writes to a smart contract by using the Wagmi protocol.
 * @param {object} to - The smart contract address and ABI.
 * @param {object} tx - The transaction details.
 * @param {string} msg - The message to display during the transaction.
 * @returns {Promise<string>} - A promise that resolves to the transaction hash or rejects with an error.
 */
async function writeToContractByWagmi(to, tx, network, msg) {
  // Get the current chain ID from the network configuration
  const { chain } = getNetwork();

  if (chain === undefined) {
    toast.error("Please connect your Wallet");
    return;
  }

  return new Promise(async (resolve, reject) => {
    try {
      // Prepare the configuration for writing to the contract
      const config = await prepareWriteContract({
        address: to.address,
        abi: to.abi,
        functionName: tx.functionName,
        args: tx.args,
        value: tx.value,
      });

      // Write to the contract and get the transaction hash
      const hashPromise = new Promise((resolveHash, rejectHash) => {
        writeContract(config)
          .then((result) => {
            resolveHash(result.hash);
          })
          .catch((error) => {
            rejectHash(error);
          });
      });

      // Show a toast message with loading animation while waiting for the user's signature
      toast.promise(hashPromise, {
        loading: "Need your signature",
        success: "Successfully signed the transaction",
        error: "Signature rejected",
      });

      const hash = await hashPromise;

      // Wait for the transaction to be deployed and show a toast message with the progress
      const deployment = waitForTransaction({ hash });

      toast.promise(deployment, {
        loading: `${msg} In Progress`,
        success: () => {
          resolve(displayMessageWithLink(hash, msg, network));
          return displayMessageWithLink(hash, msg, network);
        },
        error: (err) => {
          reject(err);
          return `${msg} failed`;
        },
      });

      await deployment;
    } catch (e) {
      reject(e);
      toast.error("Transaction failed");
      return new Error("Transaction failed");
    }
  });
}

/**
 * Reads data from a contract by using the WAGMI protocol.
 * @param {Object} to - The contract to read from.
 * @param {Object} tx - The transaction details.
 * @returns {Promise} - A promise that resolves with the data read from the contract.
 * @throws {Error} - If there is an error reading from the contract.
 */
async function readFromContractByWagmi(to, tx) {
  // Get the current chain ID
  const { chain } = getNetwork();
  if (chain === undefined) {
    toast.error("Please connect your Wallet");
    return;
  }
  const chainID = chain.id;

  try {
    // Read data from the contract
    const data = await readContract({
      address: to.address,
      abi: to.abi,
      functionName: tx.functionName,
      args: tx.args,
    });
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

/**
 * Reads data from a smart contract.
 *
 * @param {Object} to - The contract to read from, containing the address and ABI.
 * @param {Object} tx - The transaction details, including the function name and arguments.
 * @returns {Promise} - A promise that resolves with the result of the contract function call.
 */
async function readFromContract(to, tx, network) {
  // Create a JSON-RPC provider using the Mumbai RPC URL
  const provider = getEthersProvider(network.chain);

  // Create a contract instance using the contract address, ABI, and provider
  const contract = new ethers.Contract(to.address, to.abi, provider);

  // Call the contract function with the provided arguments and return the result
  return await contract[tx.functionName](...tx.args);
}

const getNativeTokens = async (network, walletAddress) => {
  const provider = getEthersProvider(network);
  const balances = ethers.utils.formatEther(
    await provider.getBalance(walletAddress)
  );
  return balances;
};

const hashWalletIds = (walletIds, network) => {
  let hashedArr = [];

  for (let i = 0; i < walletIds.length; i++) {
    const walletId = walletIds[i].toLowerCase();
    const combinedInput = `${walletId.toLowerCase()}:${network}:${process.env.REACT_APP_SECRET_KEY}`;
    const hashedWalletId = CryptoJS.SHA256(combinedInput).toString();
    hashedArr.push(hashedWalletId);
  }
  return hashedArr;
};

const getHashedWalletId = (walletIds, network) => {
  const hashedArr = hashWalletIds([walletIds], network);
  return hashedArr[0];
};

const getDepositBalances = async (depositedTokens, network) => {
  const currentBlockchainApisProvider =
    process.env.REACT_APP_BLOCKCHAIN_APIS_PROVIDER;
  switch (currentBlockchainApisProvider) {
    case "UNIBLOCK":
      const uniblock = new UniblockAPI(network);
      return await uniblock.getDepositBalances(depositedTokens);
    case "ALCHEMY":
      const alch = new AlchemyAPI(network);
      return await alch.getDepositBalances(depositedTokens);
    default:
      return [];
  }
};

const getAllBalances = async (walletId, network) => {
  const currentBlockchainApisProvider =
    process.env.REACT_APP_BLOCKCHAIN_APIS_PROVIDER;
  switch (currentBlockchainApisProvider) {
    case "UNIBLOCK":
      const uniblock = new UniblockAPI(network);
      return await uniblock.getAllBalances(walletId);
    case "ALCHEMY":
      const alch = new AlchemyAPI(network);
      return await alch.getAllBalances(walletId);
    default:
      return [];
  }
};

const getNfts = async (walletId, network) => {
  const currentBlockchainApisProvider =
    process.env.REACT_APP_BLOCKCHAIN_APIS_PROVIDER;
  switch (currentBlockchainApisProvider) {
    case "UNIBLOCK":
      const uniblock = new UniblockAPI(network);
      return await uniblock.getNFTs(walletId);
    case "ALCHEMY":
      const alch = new AlchemyAPI(network);
      return await alch.getNFTs(walletId);
    default:
      return [];
  }
  // const alch = new AlchemyAPI(network);
  // return await alch.getNFTs(walletId);
};

export {
  signTransaction,
  getHashedWalletId,
  hashWalletIds,
  getNativeTokens,
  readFromContract,
  readFromContractByWagmi,
  writeToContractByWagmi,
  sendTransactionByWagmi,
  getAllBalances,
  getNfts,
  getDepositBalances,
};
