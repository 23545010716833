import { ethers, Contract, Signer } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import { getEthersProvider } from "../utils/Providers";
import {
  HORUS_SCHEDULER_CONTRACT_ADDRESS,
  HORUS_SCHEDULER_ABI,
  HAMADA_APES_ABI,
} from "./scheduler-constants";
import {
  PROXY_PAYMENT_CONTRACT_DICT,
  PROXY_PAYMENT_CONTRACT_ABI,
} from "../ERC20-utils/proxy-payment/ProxyPayment_Constants";
import { ERC20_ABI } from "../ERC20-utils/proxy-payment/HorusCoin_Constants";
import {
  signTransaction,
  hashWalletIds,
  writeToContractByWagmi,
} from "../utils/utils";
import { displayMessageWithLink } from "../../../helper/helperFunc";

export const scheduleNonCustodial = async (tx, network, isTransfer) => {
  try {
    let data, gasLimit;
    if (isTransfer)
      [data, gasLimit] = await prepareNativeTransferNonCustodial(tx, network);
    else [data, gasLimit] = await prepareDepositNonCustodial(tx, network);

    const [txValue, gasPrice, reqGasLimit, totalRequiredValue] =
      await computeFees(tx, network, gasLimit);

    await writeToContractByWagmi(
      {
        address:
          HORUS_SCHEDULER_CONTRACT_ADDRESS[network.chain][
            process.env.REACT_APP_NETWORK_TYPE
          ],
        abi: HORUS_SCHEDULER_ABI,
      },
      {
        functionName: "scheduleTransaction",
        args: [
          {
            to: PROXY_PAYMENT_CONTRACT_DICT[network.chain][
              process.env.REACT_APP_NETWORK_TYPE
            ],
            value:
              network.tokenAddress == ethers.constants.AddressZero
                ? txValue
                : 0,
            gasLimit: reqGasLimit,
            executionTime: tx.executionTime,
            repeatingTime: tx.repeatingTime,
            numberOfExecutions: tx.numberOfExecutions,
            description: tx.description,
            data: data,
          },
        ],
        value: totalRequiredValue,
      },
      network.chain,
      "Scheduling Tranaction"
    );
  } catch (err) {
    console.log(err);
    toast.error("Scheduling Transaction Failed");
  }
};

export const scheduleCustodial = async (tx, network, isTransfer) => {
  try {
    let data, gasLimit;
    if (isTransfer)
      [data, gasLimit] = await prepareNativeTransferCustodial(tx, network);
    else [data, gasLimit] = await prepareDepositCustodial(tx, network);

    const [txValue, gasPrice, reqGasLimit, totalRequiredValue] =
      await computeFees(tx, network, gasLimit);
    const schedulingTx = await signTransaction(
      tx.walletId,
      tx.walletIndex,
      network.chain,
      tx.password,
      [
        {
          contractAddress:
            HORUS_SCHEDULER_CONTRACT_ADDRESS[network.chain][
              process.env.REACT_APP_NETWORK_TYPE
            ],
          abi: HORUS_SCHEDULER_ABI,
          functionName: "scheduleTransaction",
          params: [
            {
              to: PROXY_PAYMENT_CONTRACT_DICT[network.chain][
                process.env.REACT_APP_NETWORK_TYPE
              ],
              value:
                network.tokenAddress == ethers.constants.AddressZero
                  ? txValue
                  : 0,
              gasLimit: reqGasLimit,
              executionTime: tx.executionTime,
              repeatingTime: tx.repeatingTime,
              numberOfExecutions: tx.numberOfExecutions,
              description: tx.description,
              data: data,
            },
          ],
          value: totalRequiredValue,
        },
      ],
      "Scheduling Tranaction"
    );

    console.log("Transaction scheduled", await schedulingTx);
  } catch (err) {
    console.log(err);
    toast.error("Scheduling Transaction Failed");
  }
};

export const cancelTransactionNonCustodial = async (tx, network) => {
  try {
    await writeToContractByWagmi(
      {
        address:
          HORUS_SCHEDULER_CONTRACT_ADDRESS[network.chain][
            process.env.REACT_APP_NETWORK_TYPE
          ],
        abi: HORUS_SCHEDULER_ABI,
      },
      {
        functionName: "cancelTransaction",
        args: [tx.txIndex],
      },
      network.chain,
      "Canceling Transaction"
    );
  } catch (err) {
    console.log(err);
    toast.error("Canceling Transaction Failed");
  }
};

export const cancelTransactionCustodial = async (tx, network) => {
  try {
    const cancelHash = await signTransaction(
      tx.walletId,
      tx.walletIndex,
      network.chain,
      tx.password,
      [
        {
          contractAddress:
            HORUS_SCHEDULER_CONTRACT_ADDRESS[network.chain][
              process.env.REACT_APP_NETWORK_TYPE
            ],
          abi: HORUS_SCHEDULER_ABI,
          functionName: "cancelTransaction",
          params: [tx.txIndex],
        },
      ],
      "Canceling Transaction"
    );

    if (!cancelHash) {
      toast.error("Transaction Failed");
      return;
    }

    console.log("Transaction canceled", await cancelHash);
  } catch (err) {
    console.log(err);
    toast.error("Canceling Transaction Failed");
  }
};

export const getUserBalance = async (network, address) => {
  const provider = getEthersProvider(network.chain);
  const schedulerContract = new Contract(
    HORUS_SCHEDULER_CONTRACT_ADDRESS[network.chain][
      process.env.REACT_APP_NETWORK_TYPE
    ],
    HORUS_SCHEDULER_ABI,
    provider
  );

  try {
    const balance = await schedulerContract.getUserBalance(address);
    return balance;
  } catch (err) {
    console.log(err);
    toast.error("Getting User Balance Failed");
  }
};

export const getUserTransactions = async (address, network) => {
  const provider = getEthersProvider(network.chain);
  const schedulerContract = new Contract(
    HORUS_SCHEDULER_CONTRACT_ADDRESS[network.chain][
      process.env.REACT_APP_NETWORK_TYPE
    ],
    HORUS_SCHEDULER_ABI,
    provider
  );

  try {
    const transactions = await schedulerContract.getUserTransactions(address);
    return transactions;
  } catch (err) {
    console.log(err);
    toast.error("Getting User Transactions Failed");
  }
};

export const withdrawRefundNonCustodial = async (network) => {
  try {
    await writeToContractByWagmi(
      {
        address:
          HORUS_SCHEDULER_CONTRACT_ADDRESS[network.chain][
            process.env.REACT_APP_NETWORK_TYPE
          ],
        abi: HORUS_SCHEDULER_ABI,
      },
      {
        functionName: "withdrawRefund",
        args: [],
      },
      network.chain,
      "Withdrawing Refund"
    );
  } catch (err) {
    console.log(err);
    toast.error("Withdrawing Refund Failed");
  }
};

export const withdrawRefundCustodial = async (tx, network) => {
  try {
    const withdrawHash = await signTransaction(
      tx.walletId,
      tx.walletIndex,
      network.chain,
      tx.password,
      [
        {
          contractAddress:
            HORUS_SCHEDULER_CONTRACT_ADDRESS[network.chain][
              process.env.REACT_APP_NETWORK_TYPE
            ],
          abi: HORUS_SCHEDULER_ABI,
          functionName: "withdrawRefund",
          params: [],
        },
      ],
      "Withdrawing Refund"
    );

    if (!withdrawHash) {
      toast.error("Transaction Failed");
      return;
    }

    console.log("Transaction canceled", await withdrawHash);
    toast.success(
      displayMessageWithLink(
        withdrawHash,
        "Your tokens refunded successfully",
        network.chain
      )
    );
  } catch (err) {
    console.log(err);
    toast.error("Withdrawing Refund Failed");
  }
};

const prepareNativeTransferNonCustodial = async (tx, network) => {
  try {
    if (network.tokenAddress != ethers.constants.AddressZero) {
      await writeToContractByWagmi(
        {
          address: network.tokenAddress,
          abi: ERC20_ABI,
        },
        {
          functionName: "approve",
          args: [
            PROXY_PAYMENT_CONTRACT_DICT[network.chain][
              process.env.REACT_APP_NETWORK_TYPE
            ],
            ethers.utils.parseUnits(
              (+tx.amount * tx.recipients.length).toString(),
              network.decimals
            ),
          ],
        },
        network.chain,
        "Approving Transaction"
      );
    }

    const estimatedGasLimit = 200000 + 30 * tx.recipients.length;

    const iface = new ethers.utils.Interface(PROXY_PAYMENT_CONTRACT_ABI);

    const txData = iface.encodeFunctionData("batchTrasnfer", [
      tx.sender,
      network.tokenAddress,
      tx.recipients,
      ethers.utils.parseUnits(tx.amount, network.decimals),
    ]);

    console.log("Transaction data", txData);

    return [txData, estimatedGasLimit];
  } catch (err) {
    console.log(err);
    toast.error("Preparing Transaction Failed");
  }
};

const prepareNativeTransferCustodial = async (tx, network) => {
  try {
    if (network.tokenAddress != ethers.constants.AddressZero) {
      const approveHash = await signTransaction(
        tx.walletId,
        tx.walletIndex,
        network.chain,
        tx.password,
        [
          {
            contractAddress: network.tokenAddress,
            abi: ERC20_ABI,
            functionName: "approve",
            params: [
              PROXY_PAYMENT_CONTRACT_DICT[network.chain][
                process.env.REACT_APP_NETWORK_TYPE
              ],
              ethers.utils.parseUnits(
                (+tx.amount * tx.recepinets.length).toString(),
                network.decimals
              ),
            ],
          },
        ],
        "Approving Transaction"
      );

      if (!approveHash) {
        toast.error("Transaction Failed");
        return;
      }
    }

    const estimatedGasLimit = 200000 + 30 * tx.recipients.length;

    const iface = new ethers.utils.Interface(PROXY_PAYMENT_CONTRACT_ABI);

    const txData = iface.encodeFunctionData("batchTrasnfer", [
      tx.sender,
      network.tokenAddress,
      tx.recipients,
      ethers.utils.parseUnits(tx.amount, network.decimals),
    ]);

    console.log("Transaction data", txData);

    return [txData, estimatedGasLimit];
  } catch (err) {
    console.log(err);
    toast.error("Preparing Transaction Failed");
  }
};

const prepareDepositNonCustodial = async (tx, network) => {
  const recipientsHahsed = hashWalletIds(tx.recipients, network.chain);
  console.log("Recipients hashed", recipientsHahsed);
  const provider = getEthersProvider("NON_CUSTODIAL");
  const signer = provider.getSigner();
  try {
    if (network.tokenAddress != ethers.constants.AddressZero) {
      const ERC20 = new Contract(network.tokenAddress, ERC20_ABI, signer);
      const approveTx = await ERC20.approve(
        PROXY_PAYMENT_CONTRACT_DICT[network.chain][
          process.env.REACT_APP_NETWORK_TYPE
        ],
        ethers.utils.parseUnits(
          (+tx.amount * tx.recipients.length).toString(),
          network.decimals
        )
      );
      await toast.promise(approveTx.wait(), {
        loading: "Approving ERC20",
        success: "ERC20 Approved",
        error: "Error in Approving ERC20",
      });
      await provider.waitForTransaction(approveTx.hash);
      console.log("ERC20 approved", await approveTx);

      await writeToContractByWagmi(
        {
          address: network.tokenAddress,
          abi: ERC20_ABI,
        },
        {
          functionName: "approve",
          args: [
            PROXY_PAYMENT_CONTRACT_DICT[network.chain][
              process.env.REACT_APP_NETWORK_TYPE
            ],
            ethers.utils.parseUnits(
              (+tx.amount * tx.recipients.length).toString(),
              network.decimals
            ),
          ],
        },
        network.chain,
        "Approving Transaction"
      );
    }

    const estimatedGasLimit = 200000 + 30 * tx.recipients.length;

    const iface = new ethers.utils.Interface(PROXY_PAYMENT_CONTRACT_ABI);

    const txData = iface.encodeFunctionData("depositFunds", [
      tx.sender,
      recipientsHahsed,
      network.tokenAddress,
      ethers.utils.parseUnits(tx.amount, network.decimals),
    ]);

    console.log("Transaction data", txData);

    return [txData, estimatedGasLimit];
  } catch (err) {
    console.log(err);
    toast.error("Preparing Transaction Failed");
  }
};

const prepareDepositCustodial = async (tx, network) => {
  try {
    const hashedRecipients = hashWalletIds(tx.recipients, network.chain);
    if (network.tokenAddress != ethers.constants.AddressZero) {
      const approveHash = await signTransaction(
        tx.walletId,
        tx.walletIndex,
        network.chain,
        tx.password,
        [
          {
            contractAddress: network.tokenAddress,
            abi: ERC20_ABI,
            functionName: "approve",
            params: [
              PROXY_PAYMENT_CONTRACT_DICT[network.chain][
                process.env.REACT_APP_NETWORK_TYPE
              ],
              ethers.utils.parseUnits(
                (+tx.amount * tx.recipients.length).toString(),
                network.decimals
              ),
            ],
          },
        ],
        "Approving Transaction"
      );

      if (!approveHash) {
        toast.error("Transaction Failed");
        return;
      }
    }

    const estimatedGasLimit = 200000 + 30 * tx.recipients.length;

    const iface = new ethers.utils.Interface(PROXY_PAYMENT_CONTRACT_ABI);

    const txData = iface.encodeFunctionData("depositFunds", [
      tx.sender,
      hashedRecipients,
      network.tokenAddress,
      ethers.utils.parseUnits(tx.amount, network.decimals),
    ]);

    console.log("Transaction data", txData);

    return [txData, estimatedGasLimit];
  } catch (err) {
    console.log(err);
    toast.error("Preparing Transaction Failed");
  }
};

const computeFees = async (tx, network, gasLimit) => {
  console.log("Gas limit", gasLimit);
  console.log("Tx", tx);
  const txValue = ethers.utils.parseUnits(
    (+tx.amount * tx.recipients.length * tx.numberOfExecutions).toString(),
    network.decimals
  );
  const gasPrice = ethers.utils.parseUnits("10", "gwei");
  console.log("Gas limit", gasLimit);
  const reqGasLimit = gasLimit * tx.numberOfExecutions;
  console.log("Required gas limit", reqGasLimit);
  const SCHEDULED_TX_FEE = ethers.utils
    .parseUnits("100000", "gwei")
    .mul(tx.numberOfExecutions);
  let totalRequiredValue;
  if (network.tokenAddress == ethers.constants.AddressZero)
    totalRequiredValue = txValue
      .add(gasPrice.mul(reqGasLimit))
      .add(SCHEDULED_TX_FEE);
  else totalRequiredValue = gasPrice.mul(reqGasLimit).add(SCHEDULED_TX_FEE);

  console.log("Total required value", totalRequiredValue);

  return [txValue, gasPrice, reqGasLimit, totalRequiredValue];
};

export const updateScheduledNonCustodial = async (tx, network) => {
  try {
    await writeToContractByWagmi(
      {
        address:
          HORUS_SCHEDULER_CONTRACT_ADDRESS[network.chain][
            process.env.REACT_APP_NETWORK_TYPE
          ],
        abi: HORUS_SCHEDULER_ABI,
      },
      {
        functionName: "updateScheduledTransaction",
        args: [tx.txIndex, tx.executionTime, tx.repeatingTime],
      },
      network.chain,
      "Updating Scheduled Transaction"
    );
  } catch (err) {
    console.log(err);
    toast.error("Updating Scheduled Transaction Failed");
  }
};

export const updateScheduledCustodial = async (tx, network) => {
  try {
    const updateHash = await signTransaction(
      tx.walletId,
      tx.walletIndex,
      network.chain,
      tx.password,
      [
        {
          contractAddress:
            HORUS_SCHEDULER_CONTRACT_ADDRESS[network.chain][
              process.env.REACT_APP_NETWORK_TYPE
            ],
          abi: HORUS_SCHEDULER_ABI,
          functionName: "updateScheduledTransaction",
          params: [tx.txIndex, tx.executionTime, tx.repeatingTime],
        },
      ],
      "Updating Scheduled Transaction"
    );

    if (!updateHash) {
      toast.error("Transaction Failed");
      return;
    }

    console.log("Transaction updated", await updateHash);
  } catch (err) {
    console.log(err);
    toast.error("Updating Scheduled Transaction Failed");
  }
};
