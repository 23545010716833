
import React, { useEffect } from "react";
import lightLogo from "../../../assets/images/logo/logo-white.png"
import instaLogo from "../../../assets/images/social2/instagram.svg";
import faceLogo from "../../../assets/images/social2/facebook.svg";
import twitterLogo from "../../../assets/images/social2/twitter.svg";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function FooterHomeThree() {
	const { t } = useTranslation();
	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);
	return (
		<footer className="fugu--footer-section">
			<div className="container">
				<div className="fugu--footer-top">
					{/*	<div className="row">
						<div className="col-lg-3">
							<div className="fugu--textarea">
								<div className="fugu--footer-logo">
									<img src={lightLogo} alt="" className="light-version-logo" />
								</div>
								<p>
									Discover NFTs by category, track the latest drops, and follow the collections you
									love to enjoy it!
								</p>
								<div className="fugu--social-icon">
									<ul>
										<li>
											<Link href="#">
												<img src={twitterLogo} alt="" />
											</Link>
										</li>
										<li>
											<Link href="#">
												<img src={faceLogo} alt="" />
											</Link>
										</li>
										<li>
											<Link href="#">
												<img src={instaLogo} alt="" />
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-2 offset-lg-1 col-md-4 col-sm-4">
							<div className="fugu--footer-menu">
								<span>Marketplace</span>
								<ul>
									<li>
										<Link href="#">Create A Store </Link>
									</li>
									<li>
										<Link href="#">Start Selling </Link>
									</li>
									<li>
										<Link href="#">My Account </Link>
									</li>
									<li>
										<Link href="#">Job </Link>
									</li>
									<li>
										<Link href="#">List a Item </Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-2 offset-lg-1 col-md-4 col-sm-4">
							<div className="fugu--footer-menu">
								<span>Marketplace</span>
								<ul>
									<li>
										<Link href="#">Art </Link>
									</li>
									<li>
										<Link href="#">Digital Art </Link>
									</li>
									<li>
										<Link href="#">Photography </Link>
									</li>
									<li>
										<Link href="#">Games </Link>
									</li>
									<li>
										<Link href="#">Music </Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-2 offset-lg-1 col-md-4 col-sm-4">
							<div className="fugu--footer-menu">
								<span>Marketplace</span>
								<ul>
									<li>
										<Link href="#">Explore NFTs </Link>
									</li>
									<li>
										<Link href="#">Platform Status </Link>
									</li>
									<li>
										<Link href="#">Help center </Link>
									</li>
									<li>
										<Link href="#">Partners </Link>
									</li>
									<li>
										<Link href="#">Marketplace </Link>
									</li>
								</ul>
							</div>
						</div>
					</div>*/}
				</div>
				<div className="fugu--footer-bottom">
					<p>&copy; {t("footer.copyRight")}</p>
					<div className="fugu--footer-menu">
						<ul>
							<li>
								<Link to="/terms">{t("footer.terms")} </Link>
							</li>
							<li>
								<Link to="/privacyPolicy"> {t("footer.privacyPolicy")} </Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
}
