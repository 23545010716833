import Isotope from "isotope-layout";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../components/web3/context/AuthContext";
import nftPlaceHolder from "../../assets/images/all-img/v3/card9.jpg";
import { toast } from "react-hot-toast";
import {
  claimAllNft,
  claimNft,
  getDepositedNfts,
} from "../../components/web3/nfts-utils/nfts-proxy/NftsProxy_Utils";
import { openPasswordModel } from "../../components/social-wallet/PasswordModal";
import Preloader from "../../components/common/preloader/preloader";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import NormaAlert from "../../components/custom-modal/normal-alert";
const ClaimNfts = () => {
  const { t } = useTranslation();
  const [pendingNfts, setPendingNfts] = useState([]);
  const [isBrokenImage, setIsBrokenImage] = useState([]);
  const [showAlert, setShowAlert] = useState(false)
  const [selectedClaimElem, setSelectedClaimElem] = useState(false)
  const [isClaimAllClicked, setIsClaimAllClicked] = useState(false)
  const { network, email, isLoading, setIsLoading, entityInfo } = useAuth();

  const isotope = useRef();
  const handleImageError = (index) => {
    setIsBrokenImage((prevBrokenImages) => {
      const updatedBrokenImages = [...prevBrokenImages];
      updatedBrokenImages[index] = true;
      return updatedBrokenImages;
    });
  };
  const initializeIsotope = () => {
    isotope.current = new Isotope("#fugu--four-column-2", {
      itemSelector: ".claim-nft-item",
      resizable: false,
      masonry: {
        columnWidth: ".claim-nft-item",
        gutterWidth: 0,
      },
    });
  };
  const fetchPendingNfts = async () => {
    try {
      if (network) {
        setIsLoading(true);
        const nfts = await getDepositedNfts(email, network);
        console.log("nfts", nfts);
        nfts && setPendingNfts(nfts);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, "Error on fetch pending nfts");
      setIsLoading(false);
    }
  };
  const claimUserNft = async (index) => {
    try {
      setIsLoading(true);
      await claimNft(email, pendingNfts[index], network);
      setIsLoading(false);
      fetchPendingNfts();
    } catch (e) {
      setIsLoading(false)
      toast.error(e.message)
    }
  };

  const claimUserAllNfts = async () => {
    setIsLoading(true);
    await claimAllNft(email, network);
    setIsLoading(false);
    fetchPendingNfts();
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/ClaimNFT",
      title: "Claim NFT Page",
    });
  }, []);
  useEffect(() => {
    if (pendingNfts) {
      initializeIsotope();
    }
  }, [pendingNfts]);

  useEffect(() => {
    fetchPendingNfts();
  }, [network?.chain])

  return (
    <div className="pt-4">
      <Preloader show={isLoading} />
      <div className="fugu--section-title">
        <div className="fugu--default-content content-sm">
          <h2>{t("dashboard.tabs.claimNFTs.pageTitle")}</h2>
          <p>
            {t("dashboard.tabs.claimNFTs.pageSubTitle")}
          </p>
        </div>
      </div>
      {pendingNfts && pendingNfts.length > 0 && (
        <div className="fugu--card-footer mb-4">
          <button
            className="fugu--btn btn-sm bg-white"
            onClick={() => { setShowAlert(true); setSelectedClaimElem(); setIsClaimAllClicked(true) }}
          >
            {t("dashboard.tabs.claimNFTs.claimAll")}
          </button>
        </div>
      )}
      <div className="container">
        <div id="fugu--four-column-2" className="row">
          {pendingNfts && pendingNfts.length > 0 ?
            pendingNfts.map((elem, index) => (
              <div className={`mb-4 col-lg-3 col-md-6 col-12  claim-nft-item `} key={index}>
                <div className="fugu--card-wrap">
                  <div className="fugu--card-thumb">
                    <img
                      src={isBrokenImage[index] ? nftPlaceHolder : elem.image}
                      alt=""
                      onError={() => handleImageError(index)}
                    />
                  </div>
                  <div className="fugu--card-data">
                    <h3>
                      {elem.name
                        ? `${elem.name} - #${elem.tokenId}`
                        : "Undefined"}
                    </h3>
                    <div className="fugu--card-footer">
                      <button
                        className="fugu--btn btn-sm bg-white"
                        onClick={() => { setShowAlert(true); setSelectedClaimElem(index) }}
                      >
                        {t("dashboard.tabs.claimNFTs.claim")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )) : <h1 className='text-white text-center mt-5 pt-5'>{t("dashboard.tabs.claimNFTs.noFundsToClaim")}</h1>}
        </div>
      </div>
      <NormaAlert size={'lg'}
        show={showAlert}
        message={`Are you sure you want to claim ${isClaimAllClicked ? 'all NFTs' : 'this NFT'} to your default wallet
<b class='text-break'> ${entityInfo.userWalletsData.EVM.defaultReceivingAddress}</b>?
<div class="mt-5">Note: The claiming process is gasless and supported by Horus Wallet.</div>`}
        handleCloseParent={() => setShowAlert(false)}
        agreeAction={() => { setShowAlert(false); isClaimAllClicked ? claimUserAllNfts() : claimUserNft(selectedClaimElem) }}
      />
    </div>
  );
};
export default ClaimNfts;
