import { BlockchainNetwork } from "./BlockchainNetwork";
import { CHAIN_SCAN, RPC_NODES } from "./Providers";
import axios from "axios";
import { tokenTransfer } from "../ERC20-utils/proxy-payment/ProxyPayment_Utils";
export class BitcoinNetwork extends BlockchainNetwork {
  private network: any;
  private rpc: any;

  constructor(chain: string) {
    super(chain);
    type NetworkType = 'MAINNET' | 'TESTNET';
    this.rpc = RPC_NODES.BITCOIN[process.env.REACT_APP_NETWORK_TYPE as NetworkType];
  }

  getAddress(userWallets: any) {
    return userWallets.BITCOIN.wallets[0].address[process.env.REACT_APP_NETWORK_TYPE as any];
  }

  getWallets(userWallets: any, shortenKeyWidth: number) {
    if (!userWallets) return [];
    return userWallets.map((elem: any) => {
      const address = elem.address[process.env.REACT_APP_NETWORK_TYPE as any];
      return {
        address: address,
        hasPassword: elem.hasPassword,
        walletIndex: elem.index,
        publicKey: elem.publicKey,
        label:
          address?.slice(0, shortenKeyWidth) +
          "..." +
          address?.slice(-4),
      };
    });
  }

  async getBalance(address: string, network: any): Promise<number> {
    if (!network) return 0;
    try {
      const response = await axios.get(`${this.rpc}/addrs/${address}/balance`);
      const balanceInSatoshis = response.data.balance;
      const balanceInBTC = balanceInSatoshis / 1e8;
      console.log("Balance in BTC", balanceInBTC);
      return balanceInBTC;
    } catch (error) {
      return 0;
    }
  }

  async getDeposit(email: string, tokenAddress: string): Promise<any> {
    return 0;
  }

  async getAllDepositsBalance(email: string): Promise<any> {
    return [];
  }

  isValidAddress(address: string): any {
    return true;
  }

  validateWalletAddress(addresses: any): any {
    return {
      isValid: true,
      receiver: addresses
    }
  }

  async depositToken(
    email: string,
    walletAddress: string,
    walletIndex: number,
    receiversIds: [string],
    amount: string,
    network: string,
    password: string
  ): Promise<any> {
    return tokenTransfer(
      email,
      walletIndex,
      password,
      1,
      receiversIds[0],
      (Number(amount) * 1e8).toString(),
      "BITCOIN"
    );
  }

  async transferToken(
    email: string,
    walletAddress: string,
    walletIndex: number,
    receiversIds: [string],
    amount: string,
    network: string,
    password: string
  ): Promise<any> {
    return tokenTransfer(
      email,
      walletIndex,
      password,
      0,
      receiversIds[0],
      (Number(amount) * 1e8).toString(),
      "BITCOIN"
    );
  }

  getChainScanUrlAddress() {
    const chain = CHAIN_SCAN[this.chainName as keyof typeof CHAIN_SCAN];
    return (
      chain[process.env.REACT_APP_NETWORK_TYPE as keyof typeof chain] +
      "address/"
    );
  }

  getChainScanUrlTx() {
    const chain = CHAIN_SCAN[this.chainName as keyof typeof CHAIN_SCAN];
    return (
      chain[process.env.REACT_APP_NETWORK_TYPE as keyof typeof chain] + "tx/"
    );
  }
}
