import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ethers } from "ethers";
import {
  faAngleDown,
  faSearch,
  faVideo,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { ChainsObjectsArr } from "../../../chainsStaticObject/chainsStaticData";
import DropdownMenu from "../navbar/dropdown-menu";
import Navbar from "../navbar/navbar";
import useScroll from "./../../../hooks/useScroll";
import lightLogo from "../../../assets/images/logo/logo-white.png";
import testLogo from "../../../assets/images/logo/logo-test.png";
// import { Web3Button, Web3NetworkSwitch } from "@web3modal/react";
import { useAccount, useChainId } from "wagmi";
// import {getNetwork} from "@wagmi/core";
import { useAuth } from "../../web3/context/AuthContext";
import LoginButtons from "./../../social-wallet/loginButtons";
import { FaCopy } from "react-icons/fa";
import { toast } from "react-hot-toast";
import downloadWalletIcon from "../../../assets/images/tools/Download.svg";
import linkWalletIcon from "../../../assets/images/tools/link.svg";
import importWalletIcon from "../../../assets/images/tools/Import.svg";
import logout_icon from "../../../assets/images/tools/Logout.png";
import claimIcon from "../../../assets/images/tools/Claim.svg";
import disabledClaimIcon from "../../../assets/images/tools/disabledClaim.svg";

import createWalletIcon from "../../../assets/images/tools/Create.svg";
import WalletTooltip from "../../social-wallet/tooltip";
import PopUpModal from "../../custom-modal/link-import-modal";
import { getWalletDetails } from "../../web3/ERC20-utils/proxy-payment/ProxyPayment_Utils";
import { openPasswordModel } from "../../social-wallet/PasswordModal";
import { openNewPasswordModel } from "../../social-wallet/NewPasswordModal";
import axiosToken from "../../social-wallet/AxiosToken";
import { setAxiosToken } from "../../social-wallet/AxiosToken";
import facebook_logout from "../../social-wallet/icon/logout-facebook-icon.png";
import google_logout from "../../social-wallet/icon/logout-google-icon.png";
import twitter_logout from "../../social-wallet/icon/logout-twitter-icon.png";
import csprClickIcon from "../../../assets/images/logo/csprClick.svg";
import transak_logo from "../../social-wallet/icon/transak-logo.svg";
import LinkWallet from "../../link-wallet/linkWallet";
// import { Web3ChainsObjectsArr } from "../../../chainsStaticObject/supportedWeb3networks";
import QrCodeModal from "../../../helper/qrcodeModal";
import { launchTransak } from "../../web3/transak/functions";
import HConnectModal from "../../custom-modal/hconnectModal";
import { ClickUI } from "@make-software/csprclick-ui";
import styled, { ThemeProvider } from "styled-components";
import { useClickRef, ThemeModeType } from "@make-software/csprclick-ui";
import { CsprClickThemes } from "@make-software/csprclick-ui";
import { CSPR_CLICK_PROVIDERS_NETWORKS_DIC } from "../../../chainsStaticObject/csprClickProvidersDic";
import { getAuthConfig } from "../../social-wallet/firebase";
import { getSupportedOption } from "../../web3/utils/Providers";
import { refreshFirebaseToken } from "../../social-wallet/firebase";
import CustomSelect from "../../walletSelect";
import LanguageSwitcher from "../../LanguageSwitcher/LanguageSwitcher";
import Preloader from "../../common/preloader/preloader";
import { useTranslation } from "react-i18next";

export default function HeaderHomeThree({ handleClickStart }) {
  const { t } = useTranslation();
  let initialModalContent = {
    title: "Polygon",
    chain: "MUMBAI",
    symbol: "MATIC",
    networkId: "80001",
  };
  const isDev = process.env.REACT_APP_DEPLOY_ENV == "dev";
  const options = [
    {
      value: isDev ? "https://horuswallet.com" : "https://dev.horuswallet.com/",
      label: isDev ? "Main net" : "Test net",
    },
  ];
  const clickRef = useClickRef();
  const [userAddress, setUserAddress] = useState();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [selectedChain, setSelectedChain] = useState(ChainsObjectsArr[0]);
  const [themeMode, setThemeMode] = useState(ThemeModeType.light);

  const {
    network,
    setNetwork,
    isLoggedIn,
    refreshAuth,
    logout,
    setHasPassword,
    entityInfo,
    loginOption,
    email,
    hasPassword,
    loginProvider,
    depositBalance,
    refreshDepositBalance,
    setIsLoggedIn,
    supportedOptions,
    setToken,
    setIsLoading,
    setEmail,
    isLoading
  } = useAuth();

  const [logoutIcon, setLogoutIcon] = useState(logout_icon);

  const { address, isConnected } = useAccount();
  const chainId = useChainId();

  const navigate = useNavigate();
  const location = useLocation();

  const [publicKey, setPublicKey] = useState(null);
  const [shortenedKey, setShortenedKey] = useState("Loading...");
  const [showPopUpModal, setShowPopupModal] = useState(false);
  const [modelContent, setModalContent] = useState(initialModalContent);
  const [showLinkWalletModal, setShowLinkWalletModal] = useState(false);
  const [hasDeposit, setHasDeposit] = useState(false);
  const [showAddressMenu, setShowAddressMenu] = useState(false);
  const [showNetworkChainMenu, setShowNetworkChainMenu] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [openQrCodeModal, setOpenQrCodeModal] = useState(false);
  const [showHConnectModal, setShowHConnectModal] = useState(false);
  const [isClickConnected, setClickConnected] = useState(false);
  const [appLogo, setAppLogo] = useState();
  const [currentNetworkPlaceholder, setCurrentNetworkPlaceholder] = useState();

  useEffect(() => {
    const newToken = async () => {
      if (isLoggedIn && loginOption == "custodial") {
        const token = await refreshFirebaseToken();
        console.log("new token from refresh token", token);
        //update axios token value
        setToken(token);
        setAxiosToken(token);
      }
    };
    newToken();
    // Set up interval to call newToken every 15 min
    const intervalId = setInterval(newToken, 900000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    CSPR_CLICK_PROVIDERS_NETWORKS_DIC.hasOwnProperty(loginProvider) &&
      checkCsprClickStatus();
    const appEnv = process.env.REACT_APP_DEPLOY_ENV;
    setCurrentNetworkPlaceholder(appEnv == "dev" ? "Test Net" : "Main Net");
    setAppLogo(appEnv == "dev" ? testLogo : lightLogo);
  }, [loginProvider, isLoggedIn]);

  const customStyles = {
    container: (base) => ({
      ...base,
      display: "flex",
      alignItems: "center",
      width: "100%",
    }),
    control: (base, state) => ({
      ...base,
      height: "34px",
      background: "transparent",
      border: "none",
      color: "black",
      boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "auto",
      display: "block",
      background: "transparent",
      border: "none",
      padding: "0",
      input: { height: 0 },
      width: "100%",
      marginTop: 3,
    }),
    input: (provided, state) => ({
      ...provided,
    }),
    indicatorSeparator: (state) => ({
      display: "none",
      border: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      border: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
    }),

    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgba(225, 225, 225, 0.5)",
      color: "black",
    }),

    option: (provided) => ({
      ...provided,
      backgroundColor: "rgba(225, 225, 225, 0.5)",
      color: "black",
    }),
  };
  const MobCustomStyles = {
    control: (base, state) => ({
      ...base,
      background: "transparent",
      border: "none",
      color: "black",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "auto",
      display: "flex",
      background: "transparent",
      border: "none",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: 0,
      height: "auto",
      border: "none",
      color: "#ffffff",
      width: "auto",
      width: "auto",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
      border: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      border: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
    }),

    menu: (provided) => ({
      ...provided,
      backgroundColor: "rgba(225, 225, 225, 1)",
      color: "black",
    }),

    option: (provided) => ({
      ...provided,
      backgroundColor: "rgba(225, 225, 225, 0.5)",
      color: "black",
    }),
  };

  const handleSwitchNetworkOptionClick = (selectedOption) => {
    window.location.href = selectedOption.value;
  };
  useEffect(() => {
    if (entityInfo.activePublicKey !== null) {
      console.log("entityInfo.activePublicKey", entityInfo.activePublicKey);
      setPublicKey(entityInfo.activePublicKey);
      setShortenedKey(
        entityInfo.activePublicKey?.slice(0, 6) +
        "..." +
        entityInfo.activePublicKey?.slice(-4)
      );
    }
  }, [entityInfo.activePublicKey]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 922) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      isLoggedIn &&
      !isConnected &&
      !CSPR_CLICK_PROVIDERS_NETWORKS_DIC.hasOwnProperty(loginProvider)
    ) {
      // check if user has deposit on his account
      const updatedFundArr = entityInfo?.depositFund?.some(
        (item) => item.balance != 0
      );
      setHasDeposit(updatedFundArr);
    }
  }, [isLoggedIn, network, entityInfo]);

  useEffect(() => {
    if (isLoggedIn) {
      console.log("loginProvider", loginProvider);
      if (loginProvider == "google") setLogoutIcon(google_logout);
      else if (loginProvider == "facebook") setLogoutIcon(facebook_logout);
      else if (loginProvider == "twitter") setLogoutIcon(twitter_logout);
      else setLogoutIcon(logout_icon);
    }
  }, [isLoggedIn, loginProvider]);

  useEffect(() => {
    if (isConnected) {
      const currentNetwork = ChainsObjectsArr.find(
        (elem) => elem.networkId == chainId
      );
      setSelectedChain(currentNetwork ? currentNetwork : ChainsObjectsArr[0]);

      setNetwork(currentNetwork ? currentNetwork : ChainsObjectsArr[0]);
      navigate("/transferDashboard?selectedTab=Tokens")
    }
  }, [isConnected, chainId]);

  useEffect(() => {
    if (network && isLoggedIn && loginOption == "custodial") {
      setSelectedChain(network);
      const chainHolder = network?.networkType;
      if (
        entityInfo.userWalletsData &&
        entityInfo.userWalletsData[chainHolder]
      ) {
        switchNetwork(entityInfo.userWalletsData[chainHolder].wallets);
      } else {
        //in case this user try to switch to casper network
        //but he didn't have a casper wallet on his account
        openNewPasswordModel(createFirstWallet);
      }
    }
  }, [network, isLoggedIn]);

  // this func to switch between evm and casper wallets
  // to  display the correct wallets in dropdown
  const switchNetwork = (wallets) => {
    const currentPath = window.location.pathname;
    // this condition to reset the user network browsing 
    // reset another operation (manage collection or mint) switch user 
    if (!currentPath.includes('transferDashboard')) {
      navigate('/transferDashboard');
    }
    const shortenKeyWidth = isSmallScreen ? 20 : 6;
    wallets = network.network.getWallets(wallets, shortenKeyWidth);
    console.log("wallets", wallets);
    setUserAddress(wallets);
  };

  useEffect(() => {
    if (isConnected && location.pathname !== "/QrNfts")
      navigate("/transferDashboard");
  }, [isConnected, address]);

  useEffect(() => {
    if (isClickConnected && location.pathname !== "/QrNfts")
      navigate("/transferDashboard");
  }, [isClickConnected]);
  const checkCsprClickStatus = async () => {
    const ClickConnected = await clickRef?.isConnected(loginProvider);
    if (ClickConnected && location.pathname !== "/QrNfts")
      navigate("/transferDashboard");
    setClickConnected(ClickConnected);
  };
  const handleCloseMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };
  const openImportLinkModal = (title, placeHolder, type) => {
    setShowPopupModal(true);
    let content = {
      title,
      placeHolder,
      type,
    };
    setModalContent(content);
  };
  const scroll = useScroll();

  const handleCopy = async () => {
    await navigator.clipboard.writeText(publicKey);
    toast.success("Copied to clipboard");
  };

  const handleDownloadWallet = () => {
    console.log("download wallet");
    console.log("Wallet Index : ", entityInfo.indexWallet);
    if (hasPassword) openPasswordModel(handleDownloadWalletPassword);
    else getWalletDetails(email, network?.chain, entityInfo.indexWallet);
  };

  const handleDownloadWalletPassword = (password) => {
    getWalletDetails(email, network?.chain, entityInfo.indexWallet, password);
  };

  // this func is  using to create another  wallet  on evm & casper chains
  const createWallet = async (password) => {
    if (password === null) return;
    try {
      setIsLoading(true);
      // Create a new random wallet
      let privateKey = ethers.utils.hexlify(ethers.utils.randomBytes(32));
      privateKey = privateKey.slice(2);
      const apiName = "importWallet";
      const chainHolder = network?.networkType;
      let response;
      try {
        if (chainHolder == "EVM") {
          response = await axiosToken.post(apiName, {
            walletId: email,
            network: network?.chain,
            privateKey: privateKey,
            password: password,
          });
        }
        const wallet = new ethers.Wallet(privateKey);
        let newWallets = entityInfo.userWalletsData;
        newWallets[chainHolder].wallets.push({
          publicKey:
            chainHolder == "EVM"
              ? wallet.address
              : response.data.wallets[chainHolder].wallets[0].publicKey,
          hasPassword: password != "",
          index: newWallets[chainHolder].wallets.length,
        });
        setIsLoggedIn(
          true,
          entityInfo.activePublicKey,
          "custodial",
          loginProvider,
          entityInfo.indexWallet,
          newWallets
        );
        refreshAuth();
        setIsLoading(false);
        switchNetwork(newWallets[chainHolder].wallets)
        toast.success("Wallet created successfully");
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
        toast.error("Error creating wallet");
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      toast.error("Error creating wallet");
    }
  };

  // function processWalletsData(userWallets) {
  //   if (userWallets) {
  //     let map = {};
  //     for (let network in userWallets) {
  //       switch (network) {
  //         case 'BITCOIN':
  //           userWallets[network].wallets.forEach((wallet) => {
  //             const walletAddress = wallet.address[process.env.REACT_APP_NETWORK_TYPE];
  //             map[wallet.publicKey] = walletAddress;
  //             wallet.publicKey = walletAddress;
  //           });
  //           userWallets.BITCOIN.defaultReceivingAddress = map[userWallets.BITCOIN.defaultReceivingAddress];
  //           break;
  //         case 'ICP':
  //           userWallets.ICP.wallets.forEach((wallet) => {
  //             wallet.publicKey = wallet.principalId;
  //             wallet.address = wallet.accountId;
  //           });
  //           break;
  //         default:
  //           break;
  //       }
  //     }
  //     return userWallets;
  //   }
  // }

  const createFirstWallet = async (password) => {
    if (password == null) setNetwork(ChainsObjectsArr[0]);
    if (password === null) return;
    try {
      //first call to create new wallet
      setIsLoading(true)
      await axiosToken.get("getWallet", {
        params: {
          walletId: email,
          network: network?.chain,
          password: password,
        },
      });
      //second call is to fetch all  networks data
      const response = await axiosToken.get("getWallet", {
        params: {
          walletId: email,
          network: "",
          password: password,
        },
      });
      toast.success(`${network?.chain} Wallet is created successfully`);
      // setHasPassword(response.data.wallets[network?.chain].wallets[0].hasPassword);
      const userWalletsData = response.data.wallets;
      const activePublicKey = network.network.getAddress(userWalletsData);
      setIsLoggedIn(
        true,
        activePublicKey,
        "custodial",
        loginProvider,
        0, //active wallet index 0 evm
        userWalletsData
      );
      setHasPassword(response.data.wallets[network?.chain].wallets[0].hasPassword);
      switchNetwork(userWalletsData[network?.chain].wallets);
      setIsLoading(false)
    } catch (error) {
      console.log("error", error);
      setIsLoading(false)
      toast.error("Error creating wallet");
    }
  }
  const visitEtherScan = () => {
    let chainScanUrl = network?.network?.getChainScanUrlAddress();
    window.open(`${chainScanUrl}${entityInfo.activePublicKey}`);
  };
  const userSignOut = async () => {
    if (isLoggedIn && loginOption == "custodial") {
      const auth = await getAuthConfig();
      setEmail("");
      await signOut(auth);
    }
    logout();
    navigate("/");
  };
  const reloadDepositBalance = () => {
    refreshDepositBalance();
  };
  const callCasperClickLogin = async () => {
    // window.csprclick.signIn()
  };
  const handleTransak = async () => {
    launchTransak(entityInfo.activePublicKey);
  };
  return (
    <header
      className={`site-header site-header--menu-right fugu--header-section fugu--header-three ${scroll ? "sticky-menu" : ""
        }`}
      id="sticky-menu"
    >
      <Preloader show={isLoading} />
      <div className="d-none">
        <ThemeProvider theme={CsprClickThemes.light}>
          <ClickUI
            themeMode={themeMode}
            onThemeSwitch={() =>
              setThemeMode(
                themeMode === ThemeModeType.light
                  ? ThemeModeType.dark
                  : ThemeModeType.light
              )
            }
          />
        </ThemeProvider>
      </div>
      <div className="container-fluid">
        <nav className="navbar site-navbar">
          <div className="brand-logo joyride-switch-network">
            <Link to={"/"}>
              <img src={appLogo} alt="" className="light-version-logo" />
            </Link>
            <div>
              {!isSmallScreen && (
                <Select
                  isSearchable={false}
                  placeholder={currentNetworkPlaceholder}
                  options={options}
                  styles={customStyles}
                  onChange={handleSwitchNetworkOptionClick}
                />
              )}
            </div>
          </div>
          <LanguageSwitcher />
          {email && loginOption === "custodial" && !isSmallScreen && (
            <WalletTooltip title={loginProvider == "twitter" ? t("header.loggedInTwitter") : t("header.loggedInEmail")}>
              <div className="text-gray">
                <p>{email}</p>
              </div>
            </WalletTooltip>
          )}

          <div
            className="fugu-wallet-font mx-1  mouse-cursor text-gray-10"
            onClick={handleClickStart}
          >
            <WalletTooltip title={t("header.startDemo")}>
              <FontAwesomeIcon icon={faVideo} color="green" />
            </WalletTooltip>
          </div>
          <div className="fugu--header-connect-option-holder">
            <div className="menu-block-wrapper">
              <div
                className={`menu-overlay ${isMobileMenuOpen ? "active" : null}`}
                onClick={handleCloseMobileMenu}
              ></div>
              <nav
                className={`menu-block ${isMobileMenuOpen ? "active" : null}`}
                id="append-menu-header"
              >
                <div className="mobile-menu-head">
                  <div
                    className="mobile-menu-close"
                    onClick={handleCloseMobileMenu}
                  >
                    &times;
                  </div>
                </div>

                <Navbar>
                  {/* <NavItem navItemText="Home" menuItems={null} /> */}
                  {isLoggedIn ? (
                    <>
                      {loginOption === "non_custodial" ? (
                        <>
                          <w3m-network-button />
                          <w3m-account-button />
                        </>
                      ) : (
                        !CSPR_CLICK_PROVIDERS_NETWORKS_DIC[loginProvider] && (
                          // switch network
                          <li
                            className={`nav-item nav-item-has-children bg-gray fugu-switch-network`}
                            style={{ marginRight: "1em" }}
                            onClick={() =>
                              setShowNetworkChainMenu(!showNetworkChainMenu)
                            }
                          >
                            <div className="nav-link-item drop-trigger">
                              {/* {selectedChain.title} */}
                              <img
                                src={selectedChain?.image}
                                className="fugu-network-icons"
                              />
                              {isSmallScreen && <span className="mx-1">{selectedChain?.title}</span>}
                              <FontAwesomeIcon icon={faAngleDown} />
                            </div>
                            <DropdownMenu
                              isOpenDropDown={showNetworkChainMenu}
                              menuItems={ChainsObjectsArr}
                              id={"selectedChain"}
                            />
                          </li>
                        )
                      )}
                      {/* Transak */}
                      <li
                        className={`nav-item bg-gray fugu-wallet-font me-2 d-flex align-items-center ${isSmallScreen && "ms-3"
                          }`}
                        onClick={handleTransak}
                        style={{ marginLeft: "1em" }}
                      >
                        <WalletTooltip
                          title={t("header.transak")}
                          className="mx-4"
                        >
                          <img src={transak_logo} width={"40px"} />
                        </WalletTooltip>
                        {isSmallScreen && (
                          <p className="m-3 text-warning-600">
                            {t("header.transak")}
                          </p>
                        )}
                      </li>
                      {/* Qrcode btn */}
                      <li
                        className={`nav-item bg-gray fugu-wallet-font me-2 d-flex align-items-center ${isSmallScreen && "ms-3"
                          }`}
                        onClick={() => setOpenQrCodeModal(!openQrCodeModal)}
                      >
                        <FontAwesomeIcon
                          icon={faQrcode}
                          style={{ color: "#FFFF", fontSize: 25 }}
                        />
                        {isSmallScreen && (
                          <p className="m-3 text-warning-600">
                            {t("header.viewWalletqrcode")}{" "}
                          </p>
                        )}
                      </li>
                      {/* search btn */}
                      <li
                        className={`nav-item bg-gray fugu-wallet-font me-2 d-flex align-items-center ${isSmallScreen && "ms-3"
                          }`}
                        onClick={visitEtherScan}
                      >
                        <FontAwesomeIcon
                          icon={faSearch}
                          style={{ color: "#FFFF", fontSize: 25 }}
                        />
                        {isSmallScreen && (
                          <p className="m-3 text-warning-600">
                            {t("header.exploreWallet")}{" "}
                          </p>
                        )}
                      </li>
                      {/* wallet Address dropdown */}
                      <li
                        className={`nav-item mouse-cursor d-flex justify-content-between ms-2 wallets-ddl`}
                      >
                        {userAddress && loginOption === "custodial" && (
                          <CustomSelect options={userAddress} disabled={!supportedOptions?.Create_Wallet} />
                        )}
                      </li>
                      {/* copy wallet option */}
                      <li
                        className={`d-flex nav-item align-items-center align-items-center mr-3  ${isSmallScreen && "ms-3"
                          }`}
                        onClick={handleCopy}
                      >
                        <WalletTooltip title="Copy Address">
                          <>
                            <FaCopy
                              color="white"
                              style={{
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                padding: 0,
                              }}
                            />
                            {isSmallScreen && (
                              <p className="m-3 text-warning-600">
                                {t("header.copyWallet")}{" "}
                              </p>
                            )}
                          </>
                        </WalletTooltip>
                      </li>
                      {loginOption === "custodial" ? (
                        <>
                          {/*download wallet */}
                          <li
                            className="nav-item fugu-wallet-font fugu-download-wallet d-flex"
                            style={{
                              marginLeft: "1em",
                              marginRight: "1em",
                              cursor: "pointer",
                            }}
                            onClick={handleDownloadWallet}
                          >
                            <WalletTooltip
                              title={t("header.downloadWallet")}
                              className="mx-4"
                            >
                              <img
                                alt="Download Wallet"
                                src={downloadWalletIcon}
                                width={"30px"}
                              />
                            </WalletTooltip>
                            {isSmallScreen && (
                              <p className="m-3 text-warning-600">
                                {t("header.downloadWallet")}
                              </p>
                            )}
                          </li>
                          {/* import wallet */}
                          {supportedOptions?.Import_Wallet && (
                            <li
                              className="nav-item fugu-wallet-font fugu-import-wallet d-flex"
                              style={{
                                marginLeft: "1em",
                                marginRight: "1em",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                openImportLinkModal(
                                  "set wallet private key",
                                  "Private Key",
                                  "import"
                                )
                              }
                            >
                              <WalletTooltip
                                title={t("header.importWallet")}
                                className="mx-4"
                              >
                                <img
                                  alt="Import Wallet"
                                  src={importWalletIcon}
                                  width={"30px"}
                                />
                              </WalletTooltip>
                              {isSmallScreen && (
                                <p className="m-3 text-warning-600">
                                  {t("header.importWallet")}
                                </p>
                              )}
                            </li>
                          )}
                          {/* link wallet */}
                          {supportedOptions?.Create_Wallet && (
                            <li
                              className="fugu-wallet-font fugu-link-wallet d-flex"
                              style={{
                                marginLeft: "1em",
                                marginRight: "1em",
                                cursor: "pointer",
                              }}
                              onClick={() => setShowLinkWalletModal(true)}
                            >
                              <WalletTooltip
                                title={t("header.manageWallet")}
                                className="mx-4"
                              >
                                <img
                                  alt="Link Wallet"
                                  src={linkWalletIcon}
                                  width={"30px"}
                                />
                              </WalletTooltip>
                              {isSmallScreen && (
                                <p className="m-3 text-warning-600">
                                  {t("header.manageWallet")}
                                </p>
                              )}
                            </li>
                          )}
                          {/* claim fund */}
                          {supportedOptions?.Claim_Tokens && (
                            <li
                              className="fugu-wallet-font fugu-fund-wallet nav-item nav-item-has-children d-flex"
                              style={{
                                marginLeft: "1em",
                                marginRight: "0.5em",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                reloadDepositBalance();
                              }}
                            >
                              <WalletTooltip
                                title={
                                  depositBalance == 0
                                    ? t("header.clickToreload")
                                    : `${depositBalance} ${network?.symbol}`
                                }
                                className="mx-4"
                              >
                                <img
                                  alt="Claim Funds"
                                  src={
                                    depositBalance != 0
                                      ? claimIcon
                                      : disabledClaimIcon
                                  }
                                  width={"30px"}
                                />
                              </WalletTooltip>
                              {isSmallScreen && (
                                <p className="m-3 text-warning-600">
                                  {depositBalance != 0
                                    ? `${depositBalance} ${network?.symbol}`
                                    : "click to reload funds"}
                                </p>
                              )}
                            </li>
                          )}

                          {/* create new wallet */}
                          {supportedOptions?.Create_Wallet && (
                            <li
                              className="fugu-wallet-font ms-3  mouse-cursor fugu-create-wallet d-flex"
                              onClick={() => openNewPasswordModel(createWallet)}
                            >
                              <WalletTooltip
                                title={t("header.createWallet")}
                                className="mx-4"
                              >
                                <img
                                  alt="Create a new Wallet"
                                  src={createWalletIcon}
                                  width={"30px"}
                                />
                              </WalletTooltip>
                              {isSmallScreen && (
                                <p className="m-3 text-warning-600">
                                  {t("header.createWallet")}
                                </p>
                              )}
                            </li>
                          )}
                        </>
                      ) : null}
                      {/* sign out btn */}

                      {email &&
                        loginOption === "custodial" &&
                        isSmallScreen && (
                          <li className="nav-item">
                            <WalletTooltip title={t("header.loggedInEmail")}>
                              <div className="text-gray">
                                <p>{email}</p>
                              </div>
                            </WalletTooltip>
                          </li>
                        )}
                      {isSmallScreen && (
                        <li className="nav-item mx-3 joyride-switch-network">
                          <Select
                            isSearchable={false}
                            placeholder={currentNetworkPlaceholder}
                            options={options}
                            styles={MobCustomStyles}
                            onChange={handleSwitchNetworkOptionClick}
                          />
                        </li>
                      )}
                      <li
                        className="fugu-wallet-font d-flex"
                        style={{
                          marginLeft: "1em",
                          marginRight: "1em",
                          cursor: "pointer",
                        }}
                        onClick={() => userSignOut()}
                      >
                        <WalletTooltip title={t("header.logout")} className="mx-4">
                          <img alt="Logout" src={logoutIcon} width={"45px"} />
                        </WalletTooltip>
                        {isSmallScreen && (
                          <p className="m-3 text-warning-600">{t("header.logout")} </p>
                        )}
                      </li>
                    </>
                  ) : (
                    <li>
                      <div
                        className={`${isSmallScreen ? "flex-column mt-5" : "d-flex"
                          }`}
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        <div
                          className="align-self-center mx-3 mouse-cursor opacity-50"
                          onClick={() => callCasperClickLogin()}
                        >
                          <img src={csprClickIcon} />
                        </div>
                        <div
                          className={`align-self-center fugu-web3-button ${isSmallScreen
                            ? "pt-3 d-flex justify-content-center"
                            : ""
                            }`}
                        >
                          <w3m-button />
                        </div>

                        <div className="ms-2 fugu-social-login">
                          <LoginButtons />
                          {isSmallScreen && (
                            <li className="nav-item mx-3 joyride-switch-network">
                              <Select
                                isSearchable={false}
                                placeholder={currentNetworkPlaceholder}
                                options={options}
                                styles={MobCustomStyles}
                                onChange={handleSwitchNetworkOptionClick}
                              />
                            </li>
                          )}
                        </div>
                      </div>
                    </li>
                  )}
                </Navbar>
              </nav>
            </div>
          </div>
          <div
            className="mobile-menu-trigger"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <span></span>
          </div>
        </nav>
      </div>
      {/* QrCode modal */}
      <QrCodeModal
        show={openQrCodeModal}
        handleCloseParent={() => setOpenQrCodeModal(!openQrCodeModal)}
        userWallet={entityInfo.activePublicKey}
      />
      {/*import wallets modal*/}
      <PopUpModal
        show={showPopUpModal}
        handleCloseParent={() => setShowPopupModal(!showPopUpModal)}
        handleWalletCreated={(addresses) => { switchNetwork(addresses) }}
        title={modelContent.title}
        placeHolder={modelContent.placeHolder}
        type={modelContent.type}
      />
      <LinkWallet
        show={showLinkWalletModal}
        handleCloseParent={() => setShowLinkWalletModal(!showLinkWalletModal)}
      />
      <HConnectModal
        show={showHConnectModal}
        handleCloseParent={() => setShowHConnectModal(!showHConnectModal)}
      />
    </header>
  );
}
