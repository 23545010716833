import React, { useState, useEffect } from "react";
import { useAuth } from "../../components/web3/context/AuthContext";
import OutLineButtonLoader from "../../components/common/preloader/buttonLoader";
import LoginButtons from "../../components/social-wallet/loginButtons";
import lightLogo from "../../assets/images/logo/logo-white.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import image404 from "../../assets/images/all-img/404.png";
import { formatURI } from "../../components/web3/nfts-utils/nfts-proxy/NftsProxy_Utils";
import toast from "react-hot-toast";
import {
  mintPoapNft,
  getContractStats,
  isClaimedNft,
} from "../../components/web3/nfts-utils/poaps/utils";
import { Spinner } from "react-bootstrap";
import Preloader from "../../components/common/preloader/preloader";

function QrNft() {
  let navigate = useNavigate();
  const { isLoggedIn, entityInfo, isLoading } = useAuth();
  const [loading, setLoading] = useState(false);
  const [gotHisNft, setGotHisNft] = useState(false);
  const [nftDetails, setNftDetails] = useState({
    name: "",
    description: "",
    image: "",
    attributes: [],
  });
  const [isUnavailable, setIsUnavailable] = useState(false);
  const [imageSpinner, setImageSpinner] = useState(false);
  const [params, setParams] = useState({});
  const [isClaimed, setIsClaimed] = useState(false);
  const [currentMintedNfts, setCurrentMintedNfts] = useState(0);
  const [totalMintedNfts, setTotalMintedNfts] = useState(0);
  function getQueryParam(param, defaultValue = null) {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.has(param) ? queryParams.get(param) : defaultValue;
  }

  useEffect(() => {
    const poapId = getQueryParam("poapId");
    const poapAddress = getQueryParam("poapAddress");
    const poapUri = getQueryParam("poapUri");
    const chainId = getQueryParam("chainId");

    if (!poapId || !poapAddress || !poapUri || !chainId) {
      setIsUnavailable(true);
      console.error("Missing required query params");
    } else {
      setParams({
        poapId,
        poapAddress,
        poapUri,
        chainId,
      });
      fetchNftDetails(poapUri);
    }
  }, []);

  useEffect(() => {
    if (
      isLoggedIn &&
      entityInfo.activePublicKey &&
      params.poapAddress &&
      params.chainId
    ) {
      initializePoapData();
    }
  }, [isLoggedIn, params]);

  const initializePoapData = async () => {
    const getStatsPromise = getContractStats(
      params.poapAddress,
      params.chainId
    );
    const isClaimedPromise = isClaimedNft(
      params.poapAddress,
      entityInfo.activePublicKey,
      params.chainId
    );

    const [stats, claimed] = await Promise.all([
      getStatsPromise,
      isClaimedPromise,
    ]);
    setCurrentMintedNfts(stats[0]);
    setTotalMintedNfts(stats[1]);
    setIsClaimed(claimed);

    console.log("Stats:", stats);
    console.log("Claimed:", claimed);
  };

  const fetchNftDetails = async (uri) => {
    try {
      setImageSpinner(true);
      const response = await axios.get(uri);
      setNftDetails({
        name: response.data.name || "",
        description: response.data.description || "",
        image: formatURI(response.data.image) || image404,
        attributes: response.data.attributes || [],
      });
      setImageSpinner(false);
    } catch (error) {
      console.error("Error fetching NFT details:", error);
      setImageSpinner(false);
    }
  };

  const claimNft = async () => {
    if (!isLoggedIn || !entityInfo) {
      toast.error("You need to login to claim NFT");
      return;
    }
    try {
      setLoading(true);
      await mintPoapNft({
        poapId: params.poapId,
        poapAddress: params.poapAddress,
        poapUri: params.poapUri,
        chainId: params.chainId,
        to: entityInfo.activePublicKey,
      });
      setIsClaimed(true);
      setGotHisNft(true);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Preloader show={loading || isLoading} />
      <div className="container d-flex justify-content-center  text-white text-center pb-5">
        <div>
          <div className="d-flex align-items-center">
            <div className="brand-logo mb-2">
              <img src={lightLogo} alt="" className="light-version-logo" />
            </div>
            <p className="fw-bold">QRC NFTS</p>
          </div>
          <div>
            {!isLoggedIn ? (
              <>
                <div className={`claim-nft-item mt-5 ${loading ? "wiggle" : ""}`}>
                  <div className="fugu--card-wrap-qrc-nft">
                    <div className="fugu--card-thumb">
                      <p className="fw-bold fs-3">
                        {isUnavailable ? "Unavailable" : nftDetails.name}
                      </p>
                      <img
                        src={isUnavailable ? image404 : nftDetails.image}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <LoginButtons freeNft={true} QRNFTURL={window.location.href} />
                </div>
              </>
            ) : (
              <>
                <h4>
                  {isClaimed
                    ? "You have already claimed your NFT. Thank you for participating!"
                    : currentMintedNfts != 0 &&
                      totalMintedNfts != 0 &&
                      Number(currentMintedNfts) == Number(totalMintedNfts)
                      ? "Sorry, the NFT collection you are trying to mint from has reached its maximum limit."
                      : gotHisNft
                        ? "Congratulations! You have received NFT in your Horus Wallet"
                        : ""}
                </h4>
                <p>
                  {Number(totalMintedNfts) - Number(currentMintedNfts)} NFT
                  Available
                </p>
                <div>
                  {!isUnavailable &&
                    !gotHisNft &&
                    !isClaimed &&
                    Number(currentMintedNfts) != Number(totalMintedNfts) && (
                      <div className="mt-3">
                        <button
                          className="fugu--btn btn-lg fugu--claim-btn bg-white"
                          onClick={claimNft}
                          disabled={loading}
                        >
                          {loading ? (
                            <OutLineButtonLoader className="my-3" />
                          ) : (
                            "Claim NFT"
                          )}
                        </button>
                      </div>
                    )}

                  {(gotHisNft || isClaimed) && (
                    <div className="fugu--card-footer d-flex justify-content-center align-items-center">
                      <button
                        className="fugu--btn btn-sm bg-white"
                        onClick={() => navigate("/transferDashboard")}
                      >
                        Browse Horus Site
                      </button>
                    </div>
                  )}
                </div>
                <div className={`claim-nft-item mt-5 ${loading ? "wiggle" : ""}`}>
                  {!imageSpinner ? (
                    <div className="fugu--card-wrap">
                      <div className="fugu--card-thumb">
                        <p className="fw-bold fs-3">
                          {isUnavailable ? "Unavailable" : nftDetails.name}
                        </p>

                        <img
                          src={isUnavailable ? image404 : nftDetails.image}
                          alt=""
                        />
                      </div>
                      <p>{isUnavailable ? "" : nftDetails.description}</p>
                    </div>
                  ) : (
                    <div className="fugu--card-wrap d-flex justify-content-center align-items-center">
                      <Spinner />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default QrNft;
