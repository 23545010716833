import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useMultiLanguage } from '../../../MultiLangContext/useMultiLanguage';

function CollectionCreationManager() {
  const { t } = useTranslation();
  const { language } = useMultiLanguage();
  return (
    <div className="mb-3 opacity-bg">
      <div className="container d-flex justify-content-center collection-creation">
        <div className="fugu--breadcrumbs-data ">
          <h3 className="text-center">{t('dashboard.tabs.createCollection.title')}</h3>
          <div className="row m-5 ">
            <div className="col-lg-6 col-sm-12">
              <Link to={'/CreateSingleImageCollection'}>
                <div
                  className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX mouse-cursor"
                  data-wow-delay="0s"
                >
                  <div className="collection-creation-icon mb-2 rotating">
                    <img
                      src={require('../../../assets/images/collections/singleCollection.png')}
                      alt="Single Image Collection"
                    />
                  </div>
                  <div className="fugu--iconbox-data">
                    <h4>
                      {language == 'ar' ? t('dashboard.tabs.createCollection.singleImage') : <>{t('dashboard.tabs.createCollection.singleImage')} <br />
                        {t('dashboard.tabs.createCollection.collection')}</>}
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-sm-12">
              <Link to={'/CreateSingleAiGeneratedCollection'}>
                <div
                  className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX mouse-cursor"
                  data-wow-delay="0s"
                >
                  <div className="collection-creation-icon mb-2 rotating">
                    <img
                      src={require('../../../assets/images/collections/aiSingle.png')}
                      alt="Single AI Generated"
                    />
                  </div>
                  <div className="fugu--iconbox-data">
                    <h4>
                      {language == 'ar' ? t('dashboard.tabs.createCollection.singleAI') : <>{t('dashboard.tabs.createCollection.singleAI')} <br />
                        {t('dashboard.tabs.createCollection.generated')}</>}
                    </h4>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6 col-sm-12">
              <Link to={'/GenerativeLayersCollection'}>
                <div
                  className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX  mouse-cursor"
                  data-wow-delay="0s"
                >
                  <div className="collection-creation-icon rotating">
                    <img
                      src={require('../../../assets/images/collections/generativeLayers.png')}
                      alt="Generative Layers Collections"
                    />
                  </div>
                  <div className="fugu--iconbox-data">
                    <h4>{t('dashboard.tabs.createCollection.generativeLayersmain')}</h4>
                  </div>
                </div>
              </Link>
            </div>
            {/* GenerativeAiLayers */}
            <div className="col-lg-6 col-sm-12">
              <Link to={'/GenerativeAiLayers'}>
                <div
                  className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4 wow fadeInUpX mouse-cursor"
                  data-wow-delay="0s"
                >
                  <div className="collection-creation-icon rotating">
                    <img
                      src={require('../../../assets/images/collections/aiLayers.png')}
                      alt="Generative AI Layers Collection"
                    />
                  </div>
                  <div className="fugu--iconbox-data">
                    <h4>{t('dashboard.tabs.createCollection.generativeLayersai')}</h4>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollectionCreationManager;
