import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/styles/css/main.css";
import "./assets/styles/css/app.css";
import "./assets/styles/css/animate.css";
import "bootstrap/scss/bootstrap.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";
import Layout from "./components/layout";
import ReactGA from "react-ga4";
import { AuthProvider } from "./components/web3/context/AuthContext";
import { joyRideStepsContent } from "./joySteps/joyRideStaticSteps";
import toast, { Toaster } from "react-hot-toast";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";

import { WagmiConfig } from "wagmi";
import { I18nextProvider } from "react-i18next";

//pages
import Terms from "./pages/privacy-terms-condition/terms";
import TransferDashboard from "./pages/logged-in-home/loggedInHome";
import IndexThree from "./pages/logged-out-home/loggedOutHome";
import PrivacyPolicy from "./pages/privacy-terms-condition/privacy-policy";
import ClaimFund from "./pages/claim-fund/claim-fund";
import ScheduleTransaction from "./pages/schedule-transaction/scheduleTransaction";
import NftGallery from "./pages/nft-gallery/nft-gallery";
import MintManager from "./pages/nft-gallery/mint/collections-controller/mint-manager";
import DirectScheduleTransfer from "./pages/schedule-transaction/directScheduleTransfer";
import MySchedules from "./pages/schedule-transaction/mySchedules";
import Livechat from "./components/live-chat/liveChat";
import SingImageCollection from "./pages/create-new-collection/single-image-collection";
import SingleAiGenerated from "./pages/create-new-collection/single-ai-generated";
import GenerativeLayersCollection from "./pages/create-new-collection/generative-layers-collection";
import ComingSoon from "./pages/coming-soon";
import GenerativeAiLayersCollection from "./pages/create-new-collection/generative-ai-layers-collection";
import QrNft from "./pages/qrNft/QrNft";
import { Buffer } from "buffer";
import {
  MultiLangProvider,
  useMultiLanguage,
} from "./MultiLangContext/useMultiLanguage";
import i18n from "./i18n";
import CreateNFTAccess from "./components/nftAccess/create-nft-access";
import NFTAccessDetail from "./components/nftAccess/nft-access-detail";
import { NETWORK_TO_NETWORK_INSTACNE } from "./components/web3/utils/Providers";
import CallCongrates from "./components/CallCongrates";

function App() {
  const { language } = useMultiLanguage();
  window.Buffer = Buffer;
  const networkType = process.env.REACT_APP_NETWORK_TYPE;
  const {
    BASE,
    ETHEREUM,
    POLYGON,
    BSC,
    TELOS,
    FLARE,
    BITLAYER,
    PWR_ETH_PLUS,
    PWR_BTC_PLUS,
    CHILIZ,
    BITFINITY,
    ARBITRUM,
    HEMI,
  } = NETWORK_TO_NETWORK_INSTACNE;
  const chains =
    networkType === "TESTNET"
      ? [
          BASE.TESTNET,
          ETHEREUM.TESTNET,
          POLYGON.TESTNET,
          BSC.TESTNET,
          TELOS.TESTNET,
          FLARE.TESTNET,
          BITLAYER.TESTNET,
          // PWR_ETH_PLUS.TESTNET,
          PWR_BTC_PLUS.TESTNET,
          CHILIZ.TESTNET,
          BITFINITY.TESTNET,
          ARBITRUM.TESTNET,
          HEMI.TESTNET,
        ]
      : [
        BASE.MAINNET,
        ETHEREUM.MAINNET,
        POLYGON.MAINNET,
        BSC.MAINNET,
        TELOS.MAINNET,
        FLARE.MAINNET,
        BITLAYER.MAINNET,
        CHILIZ.MAINNET,
        BITFINITY.MAINNET,
        ARBITRUM.MAINNET,
      ];

  const projectId = process.env.REACT_APP_PROJECT_ID;

  const metadata = {
    name: "Horus wallet",
    description: "Ultimate wallet for all your needs.",
    url: "https://horuswallet.com",
    icons: [
      "https://horuswallet.com/static/media/logo-white.b4898638e9b311ead489.png",
    ],
  };

  const wagmiConfig = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
  });

  createWeb3Modal({ wagmiConfig, projectId, chains });
  window.csprclick?.once("csprclick:loaded", () => {
    //do something here
    console.log("?? casper click loaded");
  });
  const googleAnaId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
  console.log(googleAnaId);
  ReactGA.initialize(`${googleAnaId}`);

  //   const { publicClient } = configureChains(chains, [
  //     w3mProvider({ projectId }),
  //   ]);

  const [{ run, steps }, setState] = React.useState({
    run: false,
    steps: joyRideStepsContent,
  });

  useEffect(() => {
    const userLanguage = !language ? localStorage.getItem("selectedLanguage") ? localStorage.getItem("selectedLanguage") : "" : "";
    const isRtl = userLanguage === "ar";
    if (isRtl) {
      const link = document.createElement("link");
      link.href = "bootstrap/dist/css/bootstrap.rtl.min.css";
      link.rel = "stylesheet";
      document.head.appendChild(link);
      let linkCustom = document.createElement("link");
      linkCustom.href = `./assets/css/rtl.css`;
      linkCustom.rel = "stylesheet";
      document.head.appendChild(linkCustom);
      return () => {
        document.head.removeChild(link);
        document.head.removeChild(linkCustom);
      };
    }
  }, [language]);
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <MultiLangProvider>
          <I18nextProvider i18n={i18n}>
            <AuthProvider>
              <CallCongrates />
              <Router>
                <div>
                  <Toaster
                    position="bottom-center"
                    reverseOrder={false}
                    toastOptions={{
                      style: {
                        background: "#037FFF",
                        color: "#fff",
                        fontSize: "1.4em",
                      },
                    }}
                  />
                </div>
                <Routes>
                  <Route element={<Layout />}>
                    <Route path="/" exact element={<IndexThree />} />
                    <Route
                      path="/transferDashboard"
                      element={<TransferDashboard />}
                    />
                    <Route path="/claimFund" element={<ClaimFund />} />
                    <Route
                      path="/scheduleTransaction"
                      element={<ScheduleTransaction />}
                    />
                    <Route path="/nftGallery" element={<NftGallery />} />
                    <Route path="/mintNfts" element={<MintManager />} />
                    <Route
                      path="DirectScheduleTransfer"
                      element={<DirectScheduleTransfer />}
                    />
                    <Route path="/MySchedules" element={<MySchedules />} />
                    <Route
                      path="/CreateSingleImageCollection"
                      element={<SingImageCollection />}
                    />
                    <Route
                      path="/CreateSingleAiGeneratedCollection"
                      element={<SingleAiGenerated />}
                    />
                    <Route
                      path="/GenerativeLayersCollection"
                      element={<GenerativeLayersCollection />}
                    />
                    <Route
                      path="/GenerativeAiLayers"
                      element={<GenerativeAiLayersCollection />}
                    />
                    <Route path="/ComingSoon" element={<ComingSoon />} />
                    <Route
                      path="/CreateNFTAccess"
                      element={<CreateNFTAccess />}
                    />
                    <Route
                      path="/singleNFTAccess"
                      element={<NFTAccessDetail />}
                    />
                  </Route>
                  <Route path="QrNfts" element={<QrNft />} />
                  <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="/terms" element={<Terms />} />
                </Routes>
              </Router>
              <Livechat />
            </AuthProvider>
          </I18nextProvider>
        </MultiLangProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
